import { ApiCall, ParameterTypes } from '@lcms/api-definition';
import { logAction as logActionRoute } from '../../api-endpoint-definition.json';

export interface LogActionResponse {
    success: boolean;
}

export const logAction = new ApiCall<LogActionResponse>().post('logAction', {
    route: logActionRoute,
    params: {
        fileId: {
            type: ParameterTypes.String
        }
    }
});