import { ApiCall } from '@lcms/api-definition';
import { FileLocation } from '../../../types/file-location';
import { getFileLocation } from '../../api-endpoint-definition.json';

export interface FileLocationResponse {
    files: FileLocation[]
}

export const getFileLocations = new ApiCall<FileLocationResponse>().post('getFileLocations', {
    route: getFileLocation,
    params: {
        ids: {
            type: [] as string[]
        }
    }
});