import { compare } from "@lcms/helpers";
import { useEffect, useMemo } from "react";
import { useLanguages } from "../../../hooks/api/use-languages";
import { Dots } from "../../loading/spinner";

export function LanguageDropdown(props: { noLabel?: boolean; value?: { id: string; value: string; }; setValue: (value?: { id: string; value: string; }) => void }) {
    const { value, setValue } = props;
    const languageResource = useLanguages();

    const languages = useMemo(() => {
        if (languageResource.loading) return [];
        if (languageResource.hadError) return [];
        if (!languageResource.resource) return [];

        return languageResource.resource.languages.sort(compare(x => x.isDefault ? 0 : 1)) || [];
    }, [languageResource])

    useEffect(() => {
        if (!value) {
            const selected = languages.find(x => x.isDefault);
            setValue(selected ? {
                id: selected.id,
                value: selected.name
            } : undefined)
        }
    }, [languages, setValue, value])

    return (
        <>
            {languages.length ? <div className={`${props.noLabel ? '' : 'form-floating '}mb-1`}>
                <select className="form-select" id="language-dropdown" aria-label="Language" value={value?.id} onChange={e => setValue({
                    id: e.target.value,
                    value: languages.find(l => l.id === e.target.value)?.name || ''
                })}>
                    {languages.map(l => <option key={l.id} value={l.id}>{l.name}</option>)}
                </select>
                {!props.noLabel && <label htmlFor="language-dropdown">Language</label>}
            </div> : <div className="d-flex"><div className="me-2">Loading Languages</div><Dots size="0.25rem" spacing="0.25rem" /></div>}
        </>
    )
}