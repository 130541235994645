import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { HelpScout } from '../services/help-scout';
import { throwError } from './throw-error';



export interface InfoPaneContextType {
    visible: boolean;
    toggle: () => void;
    hide: () => void;
    show: () => void;
}

export const InfoPanelContext = React.createContext<InfoPaneContextType>({
    visible: false,
    toggle: throwError,
    hide: throwError,
    show: throwError,
});

export function InfoPanelContextProvider(props: PropsWithChildren<{}>) {
    const [visible, setVisible] = useState(false);

    const hide = useCallback(() => {
        setVisible(false);
    }, [setVisible]);

    const show = useCallback(() => {
        setVisible(true);
    }, [setVisible]);

    const toggle = useCallback(() => {
        setVisible(!visible);
    }, [setVisible, visible]);

    useEffect(() => {
        const trackingElements = document.getElementsByClassName('track-info-panel');
        if (visible) {
            HelpScout.close();
            for (let i = 0; i < trackingElements.length; i++) { trackingElements[i].classList.add('show'); }
        } else {
            for (let i = 0; i < trackingElements.length; i++) { trackingElements[i].classList.remove('show'); }
        }
    }, [visible])

    const context = useMemo(() => {
        return {
            visible,
            hide,
            show,
            toggle
        }
    }, [visible, hide, show, toggle])

    if (!context) { return null; }

    return (
        <InfoPanelContext.Provider value={context}>
            {props.children}
        </InfoPanelContext.Provider>
    );
};

