const newDate = new Date();

export const today = new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate()));

export const yesterday = new Date(today.valueOf());
yesterday.setUTCDate(today.getUTCDate() - 1);

export const tomorrow = new Date(today.valueOf());
tomorrow.setUTCDate(today.getUTCDate() + 1);

export const threeYears = new Date(Date.UTC(today.getUTCFullYear() + 3, 11, 31));

export const sevenDaysBack = new Date(today.valueOf());
sevenDaysBack.setUTCDate(today.getUTCDate() - 7);

export const fifteenDaysBack = new Date(today.valueOf());
fifteenDaysBack.setUTCDate(today.getUTCDate() - 15);

export const thirtyDaysBack = new Date(today.valueOf());
thirtyDaysBack.setUTCDate(today.getUTCDate() - 30);

export const fourtyFiveDaysBack = new Date(today.valueOf());
fourtyFiveDaysBack.setUTCDate(today.getUTCDate() - 45);

export const sixMonthsBack = new Date(today.valueOf());
sixMonthsBack.setUTCMonth(today.getUTCMonth() - 6, 1);

export const twelveMonthsBack = new Date(today.valueOf());
twelveMonthsBack.setUTCMonth(today.getUTCMonth() - 12, 1);

export const lastQuarterStart = new Date(today.valueOf());
if (lastQuarterStart.getUTCMonth() <= 2) {
    lastQuarterStart.setFullYear(lastQuarterStart.getUTCFullYear() - 1, 9, 1)
} else if (lastQuarterStart.getUTCMonth() <= 5) {
    lastQuarterStart.setUTCMonth(0, 1)
} else if (lastQuarterStart.getUTCMonth() <= 8) {
    lastQuarterStart.setUTCMonth(3, 1)
} else {
    lastQuarterStart.setUTCMonth(6, 1)
}

export const lastQuarterEnd = new Date(lastQuarterStart.valueOf());
lastQuarterEnd.setUTCMonth(lastQuarterStart.getUTCMonth() + 3, 1);

export const lastMonthStart = new Date(today.valueOf())
lastMonthStart.setUTCMonth(today.getUTCMonth() - 1, 1)

export const lastMonthEnd = new Date(today)
lastMonthEnd.setUTCMonth(today.getUTCMonth(), 1)

export const currentMonthStart = new Date(today.valueOf())
lastMonthStart.setUTCMonth(today.getUTCMonth() - 1, 1)

export const currentMonthEnd = new Date(today.valueOf())
currentMonthEnd.setUTCDate(currentMonthEnd.getUTCDate() + 1);
