
export class Cookie<T> {
    constructor(private cookieName: string, private lifetimeDays?: number, private noStringify = false) { }

    get(): T | null;
    get(defaultValue: T): T;
    get(defaultValue?: T): T | null {
        const cookie = Cookies.get(this.cookieName);
        if (!cookie) {
            if (typeof defaultValue === 'undefined') {
                return null;
            }
            return defaultValue;
        }
        return (this.noStringify ? cookie : JSON.parse(cookie)) as T;
    }

    set(value: T, lifetimeDays?: number | Date): void {
        Cookies.set(this.cookieName, this.noStringify && typeof (value) === 'string' ? value : JSON.stringify(value), this.lifetimeDays || lifetimeDays);
    }

    clear(): void {
        Cookies.set(this.cookieName, '', -999);
    }
}

export default class Cookies {
    static set(name: string, value: string, lifetimeDaysOrExpirationDate?: number | Date): void {
        let expires = '';
        if (lifetimeDaysOrExpirationDate) {
            if (typeof lifetimeDaysOrExpirationDate === 'number') {
                var d = new Date();
                d.setTime(d.getTime() + (lifetimeDaysOrExpirationDate * 24 * 60 * 60 * 1000));
                expires = 'expires=' + d.toUTCString();
            } else {
                expires = 'expires=' + lifetimeDaysOrExpirationDate.toUTCString();
            }

        }

        document.cookie = name + '=' + value + ';' + expires + ';path=/;SameSite=Strict';
    }

    static get(name: string): string {
        const key = name + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookies = decodedCookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(key) === 0) {
                return cookie.substring(key.length, cookie.length);
            }
        }
        return '';
    }
}
