import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { AccessToken } from "../../api/access-token";
import { loginRequest } from "./auth.config";
import { Cookie } from "./cookies";

const authCookie = new Cookie<string>('Authentication', undefined, true);

function saveToken(value: string, expiration: Date | null) {
    AccessToken.value = value;
    AccessToken.expires = expiration;
    authCookie.set(value, expiration || undefined)
}

export function clearToken() {
    AccessToken.value = null;
    AccessToken.expires = null;
    authCookie.clear();
}

export function checkForCookie() {
    return authCookie.get();
}

export async function getToken(instance: IPublicClientApplication, accounts: AccountInfo[]) {
    const account = accounts[0];
    if (!account) return false;

    if (AccessToken.value && AccessToken.expires) {
        if (AccessToken.expires > new Date()) {
            return true;
        } else {
            clearToken();
        }
    }

    const request = {
        ...loginRequest,
        account: account
    };

    const success = await instance.acquireTokenSilent(request)
        .then(async (response) => {
            saveToken(response.accessToken, response.expiresOn)
            return true;
        }).catch(async () => {
            return await instance.acquireTokenPopup(request)
                .then(async (response) => {
                    saveToken(response.accessToken, response.expiresOn)
                    return true;
                }).catch(e => {
                    console.error(e);
                    clearToken();
                    return false;
                });
        });

    return success;
}

