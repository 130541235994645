import { PropsWithChildren, useRef } from 'react';
import { MultiSelectItem } from '../../contexts/selected-file';
import { useFolderDropZone } from '../../hooks/use-folder-drop-zone';
import { FolderParent } from '../../types/folder-parent';

interface FolderSource {
    type: 'Folder',
    id: string;
    parent: FolderParent | undefined;
}
interface FileSource {
    type: 'File',
    id: string;
    parent: FolderParent | undefined;
}
interface MultiSelectSource {
    type: 'Multi-Select',
    items: MultiSelectItem[];
    parent: FolderParent | undefined;
}
interface UploadSource {
    type: 'Upload',
    files: File[];
}

export type DropSource = (FolderSource | FileSource | UploadSource | MultiSelectSource);

export function Droppable(props: PropsWithChildren<{ id?: string; allow: boolean; onDrop?: (source: DropSource, destination?: string) => void | Promise<void>, dragging?: string }>) {
    const { id, onDrop, allow, dragging } = props;
    const ref = useRef<HTMLDivElement | null>(null);

    const { isDropping, ...dragProps } = useFolderDropZone(id, onDrop, dragging);

    const hasChildren = props.id ? '' : 'main';
    const popLockAndDrop = isDropping ? 'drag-and-drop-hover' : 'dd-no-hover';
    return (
        <div id={props.id} {...(allow ? dragProps : {})}
            ref={ref}
            className={`tree-container ${hasChildren} ${popLockAndDrop}`}
        >
            {props.children}
        </div>
    );
}