import { ApiCall } from '@lcms/api-definition';
import { DetailedFileInfo } from "../../../types/detailed-file-info";
import { getFile } from '../../api-endpoint-definition.json';

export interface FileResponse {
    file: DetailedFileInfo | null
}

export const file = new ApiCall<FileResponse>().get('file', {
    route: getFile
});