export function hasParent(element: HTMLElement | null, matches: (element: HTMLElement) => boolean, stop?: (element: HTMLElement) => boolean) {
    let searching = element;
    while (searching) {
        if (matches(searching)) {
            return true;
        }
        if (stop?.(searching)) {
            return false;
        }
        searching = searching.parentElement;
    };
    return false;
}