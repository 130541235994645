import { ApiCall, ParameterTypes, PostPayload } from '@lcms/api-definition';
import { deleteFile } from '../../api-endpoint-definition.json';

export type DeleteFilePayload = PostPayload<typeof deleteFileEndpoint>

export interface DeleteResponse {
    success: boolean;
}

export const deleteFileEndpoint = new ApiCall<DeleteResponse>().post('deleteFile', {
    route: deleteFile,
    params: {
        id: {
            type: ParameterTypes.String
        }
    }
});