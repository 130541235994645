import { InlineScript } from "../script";

export function EnvironmentComment(props: { environment: string }) {
    return <>
        {props.environment !== 'prod' ?
            <InlineScript
                type="text/javascript"
                code={`  var meta = document.createElement('meta');
            meta.setAttribute('name', 'robots');
            meta.content = 'noindex';
            document.getElementsByTagName('head')[0].appendChild(meta);`}
            /> : null}
        <div dangerouslySetInnerHTML={{
            __html: `<!--Environment - ${props.environment} -->`
        }}>
        </div>
    </>
}