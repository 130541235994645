import { ApiCall } from '@lcms/api-definition';
import { DetailedFolderInfo } from '../../../types/detailed-folder-info';
import { scheduledForUnpublish } from '../../api-endpoint-definition.json';

export interface ScheduledForUnpublishResponse {
    pending: DetailedFolderInfo
}

export const scheduledUnpublish = new ApiCall<ScheduledForUnpublishResponse>().get('scheduledForUnpublish', {
    route: scheduledForUnpublish
});