import { lazy, Suspense } from 'react';
import { LoadingPage } from '../components/loading/loading-page';
import { PreviewProps } from '../pages/preview/preview';

const Page = lazy(() => import('../pages/preview/preview'))

export default function Preview(props: PreviewProps) {
    return (
        <Suspense fallback={<LoadingPage />}>
            <Page {...props} />
        </Suspense>
    )
}
