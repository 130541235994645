import { ApiCall } from '@lcms/api-definition';
import { Language } from '../../types/language';
import { getLanguages } from '../api-endpoint-definition.json';

export interface LanguagesResponse {
    languages: Language[]
}

export const languages = new ApiCall<LanguagesResponse>().get('languages', {
    route: getLanguages
});