import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Switch } from "../../../../form/switch/switch";
import { InputProps } from "../../input-props";

interface StarredAccordionProps extends InputProps<'isStarred', boolean> {
    show?: boolean;
}

export function StarredAccordion(props: StarredAccordionProps) {
    const { onChange, value: isStarred } = props;

    return (
        <div className="accordion-item">
            <h2 className="accordion-header" id="starredSection">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#starredCollapse" aria-expanded="false" aria-controls="starredCollapse">
                    <div className="w-100 d-flex justify-content-between align-items-center pe-4">
                        <div>Starred</div>
                        {isStarred ?
                            <small className="p-2 bg-white rounded text-dark">
                                <span className="me-1"><FontAwesomeIcon icon={['fas', 'star']} color='var(--bs-warning)' /></span> Promoted
                            </small> :
                            <small className="p-2 bg-white rounded text-muted">
                                Not Promoted
                            </small>}
                    </div>
                </button>
            </h2>
            <div id="starredCollapse" className={`accordion-collapse collapse ${props.show ? 'show' : ''}`} aria-labelledby="starredSection" data-bs-parent="#addEditFormAccordion">
                <div className="accordion-body">
                    <Switch name="starred" onChanged={onChange('isStarred')} label={isStarred ? 'Starred (Promoted)' : 'Un-Starred (Not Promoted)'} checked={isStarred} />
                    {isStarred && <div className="w-100 mt-2">
                        <small>Promoted in search results and listed in the promoted card.</small>
                    </div>}
                </div>
            </div>
        </div >
    );
}