import { useCallback, useState } from "react";
import { toggle } from "../../../services/toggle";
import { Modal } from "../../modal/modal";
import { LabelList, LabelPickerProps } from "./label-list";


export interface LabelModalProps {
    visible: boolean;
    onHide: () => void;
    selected: string[];
    toggle: (value: string) => void;
    onDone: (selected: string[]) => void;
}

export function LabelModal(props: LabelModalProps) {
    const { onDone, selected } = props;
    const onDoneClick = useCallback(() => {
        onDone(selected)
    }, [onDone, selected])

    return (
        <Modal visible={props.visible} onHide={props.onHide} scrollable noPadding showHeaderClose={false}
            Footer={<div className="d-flex w-100 d-flex justify-content-between">
                <button type="button" className="btn btn-outline-dark" onClick={props.onHide}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={onDoneClick}>Done</button>
            </div>}
        >
            <div className="alert alert-secondary rounded-0 mb-0">
                <b>Select labels to narrow your search:</b><br />
            </div>
            <LabelList selected={selected} toggle={props.toggle} />
        </Modal>
    );
}

export type ShowLabelPickerProps = Omit<Omit<LabelPickerProps, 'openSearchTools'>, 'toggle'> & { onDone: (changes: string[]) => void; };
export function useLabelModal() {
    const [options, setOptions] = useState<ShowLabelPickerProps>()
    const show = useCallback((p: ShowLabelPickerProps) => {
        const onDone = (changes: string[]) => {
            p.onDone(changes || []);
            setOptions(undefined);
        }
        setOptions({
            selected: p.selected,
            onDone: onDone
        })
    }, []);
    const hide = useCallback(() => { setOptions(undefined) }, []);

    const toggleItem = useCallback((type: string) => {
        if (!options) { return; }
        const newFilters = toggle(type, options.selected)
        setOptions({
            ...options,
            selected: newFilters
        })
    }, [options]);

    return {
        options,
        show, hide,
        toggleItem
    }
}