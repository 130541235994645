export function toggle(value: string, selected: string[]) {
    let wasChecked = false;
    const newFilters = selected.filter(t => {
        if (t === value) {
            wasChecked = true;
            return false;
        }
        return true;
    });
    if (!wasChecked) {
        newFilters.push(value);
    }
    return newFilters;
}