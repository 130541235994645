import React, { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { throwError } from "./throw-error";

export type MobileToggle = 'sidebar' | 'infopanel';
export type MobileToggles = { [key in MobileToggle]?: boolean };
interface ContextType {
    show: (type: MobileToggle) => void;
    hide: (type: MobileToggle) => void;
    toggle: (type: MobileToggle) => void;
    visibility: MobileToggles;
}

export const MobileTogglesContext = React.createContext<ContextType>({
    hide: throwError,
    show: throwError,
    toggle: throwError,
    visibility: {
        infopanel: false,
        sidebar: false
    }
});

export function MobileTogglesProvider(props: PropsWithChildren<{}>) {

    const [toggles, setToggles] = useState<MobileToggles>({});

    const show = useCallback((type: MobileToggle) => {
        setToggles({ [type]: true });
    }, [])

    const hide = useCallback((type: MobileToggle) => {
        setToggles({});
    }, [])

    const toggle = useCallback((type: MobileToggle) => {
        setToggles(prev => {
            return {
                [type]: !prev[type]
            }
        });
    }, [])

    const context: ContextType = useMemo(() => {
        return {
            hide: hide,
            show: show,
            toggle: toggle,
            visibility: toggles
        }
    }, [hide, show, toggle, toggles])

    return (
        <MobileTogglesContext.Provider value={context}>
            {props.children}
        </MobileTogglesContext.Provider>
    )
}