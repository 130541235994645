import { useCallback, useEffect, useState } from "react";
import { ValidatedInputProps } from "../../components/modals/admin-dialogue/input-props";

export function useValidatedFormValue<TField, TType>(field: TField, props: ValidatedInputProps<TField, TType>, validate: (value: TType) => string[]) {
    const { value, onChange, onValidate } = props;
    const [validation, setValidation] = useState<string[]>([]);
    const invalid = validation.length > 0;

    const onValueChange = useCallback((newValue: TType) => {
        onChange(field)(newValue);
    }, [field, onChange])

    const inputOnChange = useCallback((e: { target: { value: string; }; }) => {
        const newValue: any = e.target.value;
        onValueChange(newValue);
    }, [onValueChange]);

    useEffect(() => {
        const errors = validate(value);
        setValidation(errors);
        onValidate(field)(errors.length === 0);
    }, [field, onValidate, value, validate]);

    return {
        value: value,
        onInputChange: inputOnChange,
        onChange: onValueChange,
        invalid: invalid,
        errors: validation
    };
}
