import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useFileTypes } from '../../../hooks/api/use-file-types';
import { mapFileTypeToIcon } from '../../../services/map-file-type-to-icon';


export interface FileTypePickerProps {
    toggle: (fileType: string) => void;
    selected: string[];
}

export function FileTypesList(props: FileTypePickerProps) {
    const [list, setList] = useState<{ id: string; name: string; icon: IconName }[]>([]);
    const fileTypes = useFileTypes();
    useEffect(() => {
        if (!fileTypes.loading && !fileTypes.hadError) {
            setList(fileTypes.resource?.types?.filter(t => t.name !== 'Other').map(t => {
                return {
                    id: t.id,
                    name: t.name,
                    icon: mapFileTypeToIcon(t.name)
                }
            }) || [])
        }

    }, [fileTypes])

    return (
        <>
            <div className="list-group">
                {list.map(l => (
                    <button key={l.id} onClick={() => props.toggle(l.name)} className={'list-group-item d-flex justify-content-between align-items-center border-0 px-3 py-1 ' + (props.selected.includes(l.name) ? 'active' : '')}>
                        <div className="d-flex align-items-center text-start py-1">
                            <span className="icon-list align-self-center">
                                <FontAwesomeIcon className="me-3" icon={['fad', l.icon]} />
                            </span>
                            {l.name}
                        </div>
                        <div>
                            {props.selected.includes(l.name) && <FontAwesomeIcon icon={['fas', 'check']} />}
                        </div>
                    </button>
                ))}
            </div>
        </>

    );
}
