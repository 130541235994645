import { ApiCall } from '@lcms/api-definition';
import { DetailedFolderInfo } from '../../../types/detailed-folder-info';
import { getSubdirectories } from '../../api-endpoint-definition.json';

export interface FoldersResponse {
    folder: Pick<DetailedFolderInfo, 'breadCrumbs'> & Pick<DetailedFolderInfo, 'folders'> & { files: null }
}

export const subdirectoriesEndpoint = new ApiCall<FoldersResponse>().get('subdirectories', {
    route: getSubdirectories
});