import { AccessToken } from '../access-token';
import { ApiKey } from './api-key';
import { unauthorized } from "./unauthorized";


export function get(cors?: boolean) {
    return async (url: string, nativeProcessing?: boolean) => {
        const response = await fetch(url, {
            ...(cors ? { mode: 'cors' } : {}),
            headers: {
                'X-api-key': ApiKey,
                'Token': AccessToken.value || ''
            }
        }).then(unauthorized);
        return nativeProcessing ? response : await response.json();
    }
}
