import { useValidatedFormValue } from "../../../../hooks/forms/use-validated-form-value";
import { LanguageDropdown } from "../../../search-bar/search-tools/language-dropdown";
import { ValidatedInputProps } from "../input-props";

function validate(value?: { id: string; value: string }) {
    const errors: string[] = [];


    return errors;
}

export function LanguageInput(props: ValidatedInputProps<'language', { id: string; value: string } | undefined>) {
    const {
        errors, onChange, value
    } = useValidatedFormValue('language', props, validate);

    return (
        <div className="mb-3">
            <label className="form-label" htmlFor="language-dropdown">Language:</label>
            <LanguageDropdown value={value} setValue={onChange} noLabel />
            {errors.map((message, i) => <small key={i} className="form-error text-danger">{message}</small>)}
        </div>
    )
}