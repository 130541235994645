import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faExclamationCircle,
	faFileWord,
	faFileVideo,
	faFilePowerpoint,
	faFilePdf,
	faFileImage,
	faFileExcel,
	faFileAudio,
	faFileArchive,
	faFileAlt,
	faInfoCircle,
	faFolder,
	faFolderOpen,
	faFolderDownload,
	faEye,
	faFileDownload,
	faExpandAlt,
	faLink,
	faClock,
	faThumbtack,
	faPencil,
	faTrash,
	faStar,
	faFolderTree,
	faCheck,
	faFile,
	faSave,
	faFilmAlt,
	faChartLine,
	faStars,
	faFolderTimes,
	faFileCheck,
	faExclamationTriangle,
	faClone,
	faWindowRestore,
	faChartPie,
	faMapMarked,
	faBan,
	faCommentsAlt,
	faStickyNote,
} from "@fortawesome/pro-duotone-svg-icons";

library.add(
	faPencil,
	faTrash,
	faThumbtack,
	faExclamationCircle,
	faFileWord,
	faFileVideo,
	faFilePowerpoint,
	faFilePdf,
	faFileImage,
	faFileExcel,
	faFileAudio,
	faFileArchive,
	faFileAlt,
	faInfoCircle,
	faFolder,
	faFolderOpen,
	faFolderDownload,
	faEye,
	faFileDownload,
	faExpandAlt,
	faLink,
	faClock,
	faStar,
	faFolderTree,
	faCheck,
	faFile,
	faSave,
	faFilmAlt,
	faChartLine,
	faStars,
	faFolderTimes,
	faFileCheck,
	faExclamationTriangle,
	faClone,
	faWindowRestore,
	faChartPie,
	faMapMarked,
	faBan,
	faCommentsAlt,
	faStickyNote
);
