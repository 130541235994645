import { useCallback, useMemo, useState } from "react";
import { BulkEditMode } from "../../types/bulk-edit-mode";
import { MultiSelectItem } from "../selected-file";

export interface BulkEdit {
    visible: boolean;
    mode?: BulkEditMode;
    items?: MultiSelectItem[];
    show: (items: MultiSelectItem[], mode: BulkEditMode) => void;
    hide: () => void;
}

export function useBulkEdit(isAdmin: boolean) {

    const [state, setState] = useState<{ mode: BulkEditMode, items: MultiSelectItem[] }>()

    const show = useCallback((items: MultiSelectItem[], mode: BulkEditMode) => {
        setState({
            items: items,
            mode: mode
        })
    }, [])

    const hide = useCallback(() => {
        setState(undefined)
    }, [])

    const bulkEdit: BulkEdit = useMemo(() => {
        return {
            hide: hide,
            show: show,
            visible: !!state && isAdmin,
            items: state?.items,
            mode: state?.mode
        }
    }, [hide, isAdmin, show, state])

    return bulkEdit;
}