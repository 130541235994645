import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { HelpScout } from "../services/help-scout";

export function UserEvents() {

    const { inProgress, accounts } = useMsal();
    const username = accounts[0]?.username;

    useEffect(() => {
        if (inProgress === InteractionStatus.None && username) {
            HelpScout.hide('admin')
        }
    }, [inProgress, username])
    return null;
}