import { lazy, Suspense } from 'react';
import { LoadingPage } from '../../components/loading/loading-page';
import { StarredProps } from '../../pages/starred/starred';

const Page = lazy(() => import('../../pages/starred/starred.mobile'))

export default function Starred(props: StarredProps) {
    return (
        <Suspense fallback={<LoadingPage />}>
            <Page {...props} />
        </Suspense>
    )
}
