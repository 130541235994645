import { BrowserRouter, useHistory, useLocation } from 'react-router-dom';
import Routing from './routing/routing';
import { clarityId, environment, google, helpscoutId } from './config/config.json';
import { HelpScout } from './components/plugins/help-scout';
import { InfoPanelContextProvider } from './contexts/info-panel';
import { SelectedFileContextProvider } from './contexts/selected-file';
import { SearchContextProvider } from './contexts/search-context';
import { UserContextProvider } from './contexts/user';
import { LayoutContextProvider } from './contexts/layout';
import { UserEvents } from './components/user-events';
import { AdminPanelContextProvider } from './contexts/admin-panel/admin-panel';
import { AdminDialogue } from './components/modals/admin-dialogue/admin-dialogue';
import { ModalServiceProvider } from './contexts/modals-service';
import { ContextMenuProvider } from './contexts/context-menu';
import { useMount } from './hooks/use-mount';
import { BulkUploadDialogue } from './components/modals/bulk-upload/bulk-upload-dialogue';
import { MoveCopyDialogue } from './components/modals/move-copy-dialogue/move-copy-dialogue';
import { useEffect, useMemo } from 'react';
import { BulkEditDialogue } from './components/modals/bulk-edit-dialogue/bulk-edit-dialogue';
import { DataSyncProvider } from './contexts/data-sync/data-sync';
import { routes } from './routing/routes';
import { GoogleTagManager, MicrosoftClarity } from '@lcms/react-analytics';
import { EnvironmentComment } from './components/plugins/environment-comment';

function RouteWatch() {
    const { push: routeTo } = useHistory();
    const { pathname, hash } = useLocation();
    const className = useMemo(() => pathname.split('/').filter(x => x).map((x, i, all) => all.slice(0, i + 1).join('-').split(' ').join('-') || 'index'), [pathname])
    useEffect(() => {
        document.body.classList.add(...className);
        return () => document.body.classList.remove(...className);
    }, [className])

    useEffect(() => {
        if (hash && hash.toLowerCase().indexOf('#/HOME')) {
            routeTo(routes.folder + hash.substr('#/HOME'.length))
        }

    }, [hash, routeTo])

    return null;
}

function Main() {

    return (
        <UserContextProvider>
            <LayoutContextProvider>
                <DataSyncProvider>
                    <AdminPanelContextProvider>
                        <SelectedFileContextProvider>
                            <SearchContextProvider>
                                <InfoPanelContextProvider>
                                    <ContextMenuProvider>
                                        <BrowserRouter>
                                            <ModalServiceProvider>
                                                <Routing />
                                                <AdminDialogue />
                                                <BulkUploadDialogue />
                                                <MoveCopyDialogue />
                                                <BulkEditDialogue />
                                            </ModalServiceProvider>
                                            <RouteWatch />
                                        </BrowserRouter>
                                    </ContextMenuProvider>
                                </InfoPanelContextProvider>
                            </SearchContextProvider>
                        </SelectedFileContextProvider>
                    </AdminPanelContextProvider>
                </DataSyncProvider>
                <UserEvents />
            </LayoutContextProvider>
        </UserContextProvider>
    );
}

function Plugins() {
    return (
        <>
            <EnvironmentComment environment={environment} />
            <GoogleTagManager googleTagManagerId={google.tagManager} />
            <MicrosoftClarity clarityId={clarityId} />
            <HelpScout helpScoutId={helpscoutId} />
        </>
    );
}

export default function App() {
    useMount(() => {
        const no = (e: DragEvent) => {
            e = e || window.event;
            if (e.dataTransfer) {
                e.dataTransfer.effectAllowed = "none";
                e.dataTransfer.dropEffect = "none";
            }
            e.preventDefault();
            e.stopPropagation();
            return false;
        }


        window.addEventListener("dragover", no, false);
        window.addEventListener("drop", no, false);

        return () => {
            window.removeEventListener("dragover", no);
            window.removeEventListener("drop", no);
        }
    })

    return <>
        <Main />
        <Plugins />
    </>
};

