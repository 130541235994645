import { ApiCall, ParameterTypes } from '@lcms/api-definition';
import { checkUrl as checkUrlRoute } from '../api-endpoint-definition.json';

export interface CheckUrlResponse {
    isFree: boolean;
}

export const checkUrl = new ApiCall<CheckUrlResponse>().post('checkUrl', {
    route: checkUrlRoute,
    params: {
        id: {
            type: ParameterTypes.String
        },
        slug: {
            type: ParameterTypes.String
        },
        type: {
            type: ParameterTypes.String as 'file' | 'folder'
        }
    }
});