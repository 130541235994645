import { InputProps } from "../../input-props";
import { LabelInput } from "../label-input";

interface LabelsAccordionProps extends InputProps<'labels', string[]> {
    show?: boolean;
}

export function LabelsAccordion(props: LabelsAccordionProps) {
    const { onChange, value: labels } = props;

    return (
        <div className="accordion-item">
            <h2 className="accordion-header" id="labelSection">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#labelCollapse" aria-expanded="false" aria-controls="labelCollapse">
                    <div className="w-100 d-flex justify-content-between align-items-center pe-4">
                        <div>Labels</div>
                        {labels.length >= 1 ? <small className="p-2 bg-white rounded text-dark">{labels.length} Labels</small> :
                            <small className="p-2 bg-white rounded text-muted">No Labels</small>}

                    </div>
                </button>
            </h2>
            <div id="labelCollapse" className={`accordion-collapse collapse ${props.show ? 'show' : ''}`} aria-labelledby="labelSection" data-bs-parent="#addEditFormAccordion">
                <div className="accordion-body">
                    <label htmlFor="label-input" className="form-label cursor-pointer">Add New Label</label>
                    <LabelInput labels={labels} onAdd={(value) => {
                        onChange('labels')(labels.filter(x => x.toLocaleLowerCase() !== value.toLocaleLowerCase()).concat(value))
                    }} onRemove={(value) => {
                        onChange('labels')(labels.filter(x => x.toLocaleLowerCase() !== value.toLocaleLowerCase()))
                    }} />
                </div>
            </div>
        </div>
    );
}