import React, { PropsWithChildren, useEffect } from 'react';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { msalConfig } from "../services/authentication/auth.config";
import { LoadingPage } from '../components/loading/loading-page';
import { useLoginFunction } from '../hooks/auth/use-login-function';
import { checkForCookie } from '../services/authentication/get-token';

const msalInstance = new PublicClientApplication(msalConfig);

export function UserContextProvider(props: PropsWithChildren<{}>) {
    return (
        <MsalProvider instance={msalInstance}>
            <AuthToggle app={props.children} />
        </MsalProvider>
    );
};

function AuthToggle(props: { app?: React.ReactNode }) {
    const { inProgress } = useMsal();

    const login = useLoginFunction();
    const isAuthenticated = useIsAuthenticated()

    useEffect(() => {
        if (inProgress === 'none' && checkForCookie() && !isAuthenticated) {
            login()
        }
    }, [inProgress, isAuthenticated, login]);

    if (inProgress === 'none') {
        return <>{props.app}</>
    }
    return <LoadingPage />
}

