import { ApiCall, ParameterTypes } from '@lcms/api-definition';
import { checkMove } from '../../api-endpoint-definition.json';

export interface CheckMoveResponse {
    invalidFolders: { id: string; name: string; }[];
}

export const checkMoveEndpoint = new ApiCall<CheckMoveResponse>().post('checkMove', {
    route: checkMove,
    params: {
        destinationId: {
            type: ParameterTypes.String as string | undefined
        },
        sourceIds: {
            type: ParameterTypes.StringArray
        }
    }
});