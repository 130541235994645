import { ApiCall } from '@lcms/api-definition';
import { DetailedFolderInfo } from "../../../types/detailed-folder-info";
import { getFolder } from '../../api-endpoint-definition.json';

export interface FolderResponse {
    folder: DetailedFolderInfo | null
}

export const folder = new ApiCall<FolderResponse>().get('folder', {
    route: getFolder
});