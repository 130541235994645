import { ApiCall } from '@lcms/api-definition';
import { DetailedFolderInfo } from '../../../types/detailed-folder-info';
import { trashedFiles } from '../../api-endpoint-definition.json';

export interface TrashedFileResponse {
    trashed: DetailedFolderInfo
}

export const trashedEndpoint = new ApiCall<TrashedFileResponse>().get('trashed', {
    route: trashedFiles
});