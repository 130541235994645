import { ApiCall, ParameterTypes } from '@lcms/api-definition';
import { DetailedFolderInfo } from '../../../types/detailed-folder-info';
import { FileInfo } from '../../../types/file-info';
import { fileExists } from '../../api-endpoint-definition.json';

export interface ExistsResponse {
    result: DetailedFolderInfo<never, FileInfo>
}

export const fileExistsEndpoint = new ApiCall<ExistsResponse>().post('fileExists', {
    route: fileExists,
    params: {
        filename: {
            type: ParameterTypes.String
        },
        fileSize: {
            type: ParameterTypes.String
        }
    }
});