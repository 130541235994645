import { ApiCall } from '@lcms/api-definition';
import { FolderLocation } from '../../../types/folder-location';
import { getFolderLocation } from '../../api-endpoint-definition.json';

export interface FolderLocationResponse {
    folders: FolderLocation[]
}

export const getFolderLocations = new ApiCall<FolderLocationResponse>().post('getFolderLocations', {
    route: getFolderLocation,
    params: {
        ids: {
            type: [] as (string | undefined)[]
        }
    }
});