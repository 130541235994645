interface StatsProps {
    type: string;
    size: string;
    created: string;
    modified?: string;
}

export function Stats(props: StatsProps) {
    return (
        <div className="mt-2 rounded-2 shadow-sm border border-1 d-flex justify-content-between align-items-start flex-wrap px-2">
            <div className="w-50">
                <div className="ip-content d-flex justify-content-between align-items-center">
                    <span className="ip-label">Type</span>
                    <span className="ip-context flex-fill">
                        {props.type}
                    </span>
                </div>
                <div className="ip-content d-flex justify-content-between align-items-center">
                    <span className="ip-label">Size</span>
                    <span className="ip-context flex-fill">
                        {props.size}
                    </span>
                </div>
            </div>
            <div className="w-50">
                <div className="ip-content d-flex justify-content-between align-items-center">
                    <span className="ip-label">Uploaded</span>
                    <span className="ip-context flex-fill">
                        {props.created}
                    </span>
                </div>
                <div className="ip-content d-flex justify-content-between align-items-center">
                    <span className="ip-label">Modified</span>
                    <span className="ip-context flex-fill">
                        {props.modified}
                    </span>
                </div>
            </div>
        </div>
    );
}