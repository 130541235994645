import { useCallback, useContext, useMemo } from "react";
import { DataSyncContext, DirtyRecord } from "../contexts/data-sync/data-sync";

export function useDataSyncRecord(record: DirtyRecord, element: string) {
    const { id, type } = record;
    const { dirtyRecords, markClean } = useContext(DataSyncContext);

    const isDirty = useMemo(() => {
        const dirtyRecord = dirtyRecords.find(r => r.id === id && r.type === type);
        if (!dirtyRecord) return false;

        return dirtyRecord.refreshed.indexOf(element) < 0;
    }, [dirtyRecords, element, id, type])

    const markRecordClean = useCallback(() => {
        markClean([record], element)
    }, [element, markClean, record])

    const context = useMemo(() => {
        return {
            isDirty,
            markClean: markRecordClean
        }
    }, [isDirty, markRecordClean]);

    return context;
}

export function useDataSyncRecords(records: DirtyRecord[], element: string) {
    const { dirtyRecords, markClean } = useContext(DataSyncContext);

    const isDirty = useMemo(() => {
        const dirtyRecord = dirtyRecords.find(dirty => records.find(watch => watch.id === dirty.id && watch.type === dirty.type) && dirty.refreshed.indexOf(element) < 0);
        return !!dirtyRecord;

    }, [dirtyRecords, element, records])

    const markRecordClean = useCallback(() => {
        markClean(records, element)
    }, [element, markClean, records])

    const context = useMemo(() => {
        return {
            isDirty,
            markClean: markRecordClean
        }
    }, [isDirty, markRecordClean]);

    return context;
}

export function useDataSync() {
    const { markDirty } = useContext(DataSyncContext);
    return markDirty;
}