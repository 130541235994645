import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from '../../api/api';
import { mapFileTypeToIcon } from '../../services/map-file-type-to-icon';
import { FileInfo } from '../../types/file-info';
import { FileLocation } from '../../types/file-location';
import { FolderInfo } from "../../types/folder-info";
import { FolderLocation } from '../../types/folder-location';
import { Tree, Node, TreeNodeComponentProps } from '../tree/tree';
import { changeTree } from '../tree/change-tree';
import { mapToNode } from './map-to-node';
import { useMount } from '../../hooks/use-mount';
import { rootFolderLocation } from '../../types/root-directory';
import { BreadCrumbs } from '../../services/bread-crumbs';
import { Tooltip } from '../tooltip/tooltip';

interface TreeSettings {
    includeFiles?: boolean;
}

export type DocumentTreeSelectedType = FolderLocation | FileLocation;

export interface DocumentTreeProps extends TreeSettings {
    select: (item: DocumentTreeSelectedType) => void;
    selected?: DocumentTreeSelectedType[];
    onSelect?: () => void;
}

export function DocumentTree(props: DocumentTreeProps) {
    const [contents, setContents] = useState<Node<DocumentTreeSelectedType>>({
        allowDrop: false,
        allowDrag: false,
        title: 'All Folders',
        id: '',
        children: [],
        data: rootFolderLocation
    });

    const { selected } = props;
    const parents = useMemo(() => {
        return selected?.reduce((current, next) => {
            if (next.type === 'File') {
                return current.concat(next.parents.reduce((c, p) => c.concat(p.id), [] as (string | undefined)[]));
            } else {
                return current.concat(next.parents.map(p => p.id))
            }
        }, [] as (string | undefined)[]) || []
    }, [selected])

    const isSelected = useCallback((node: Node<DocumentTreeSelectedType>) => {
        return !!selected?.find(p => p.id === node.data.id) || false
    }, [selected])

    const updateNode = useCallback((changes: Node<DocumentTreeSelectedType>) => {
        setContents(prev => changeTree(prev, changes));
    }, [])

    const { select, onSelect } = props;
    const onNodeClick = useCallback((node: Node<DocumentTreeSelectedType>) => {
        select(node.data);
        onSelect?.();
    }, [onSelect, select])

    const isOpen = useCallback((node: Node<DocumentTreeSelectedType>) => node.data.type === 'Folder' && parents.includes(node.data.id), [parents])

    const settings = useMemo(() => {
        return {
            includeFiles: props.includeFiles

        }
    }, [props.includeFiles])

    return (
        <div className="tree-picker mt-3">
            <Tree
                onClick={onNodeClick}
                data={contents}
                Component={FolderEntry}
                updateNode={updateNode}
                isActive={isSelected}
                isOpen={isOpen}
                allowDragAndDrop={false}
                settings={settings}
            />
        </div>
    );
}

function FolderEntry(props: TreeNodeComponentProps<DocumentTreeSelectedType, TreeSettings>) {
    const [fetched, setFetched] = useState(false);
    const [fetchComplete, setFetchComplete] = useState(false);
    const { collapsed, setCollapsed, node, updateNode, index, isActive: checkIsActive, isOpen: checkIsOpen, settings } = props;
    const { id, data, title, allowDrop, allowDrag } = node;
    const includeFiles = settings?.includeFiles;

    const isFolder = useMemo(() => {
        return data.type === 'Folder'
    }, [data])

    const isActive = useMemo(() => {
        if (!checkIsActive) return false;
        return (checkIsActive(node))
    }, [checkIsActive, node]);

    const isOpen = useMemo(() => {
        if (!checkIsOpen) return false;
        return (checkIsOpen(node))
    }, [checkIsOpen, node]);

    useEffect(() => {
        if (isOpen && collapsed) {
            setCollapsed(false)
        }
    }, [isOpen, collapsed, setCollapsed])

    useMount(() => {
        if (!id) { setCollapsed(false); }
    })

    useEffect(() => {
        if (isFolder && !fetched && !collapsed) {
            setFetched(true);
            const fetcher = includeFiles ? api.get.folder('/' + id) : api.get.subdirectories('/' + id)
            fetcher.then(response => {
                if (response?.folder) {
                    const folderResult = response.folder;
                    updateNode({
                        allowDrop, id, data, title, allowDrag,
                        children: (folderResult.folders as (FolderInfo | FileInfo)[])
                            .concat(folderResult.files || [])
                            .map(fileOrFolder => {
                                const folder = data as FolderLocation;
                                const breadcrumbs = new BreadCrumbs(folderResult.breadCrumbs).add({
                                    name: fileOrFolder.title,
                                    id: fileOrFolder.id,
                                    isPublished: fileOrFolder.isPublished,
                                    isTrashed: fileOrFolder.isTrashed
                                });
                                return mapToNode(fileOrFolder.title,
                                    fileOrFolder.type === 'Folder' ? {
                                        type: 'Folder',
                                        parents: breadcrumbs.toList(),
                                        path: breadcrumbs.toPath(),
                                        id: fileOrFolder.id,
                                        isPublished: fileOrFolder.isPublished,
                                        isTrashed: fileOrFolder.isTrashed
                                    } : {
                                        type: 'File',
                                        parents: [{
                                            id: folder.id,
                                            path: breadcrumbs.toPath(),
                                            parents: breadcrumbs.toList(),
                                            isPublished: folder.isPublished,
                                            isTrashed: folder.isTrashed
                                        }],
                                        id: fileOrFolder.id || '',
                                        isPublished: fileOrFolder.isPublished,
                                        isTrashed: fileOrFolder.isTrashed
                                    }
                                )
                            }),
                    })
                }
            })
                .then(() => setFetchComplete(true));
        }
    }, [isFolder, fetched, collapsed, id, data, title, allowDrop, allowDrag, updateNode, includeFiles])

    const isLeaf = useMemo(() => node.children.length === 0, [node.children.length])

    const levelEdge = collapsed ? '0px' : '-3px';

    return (
        <div className={'tree-node tn-sub d-flex align-items-center ps-2 ' + (isActive ? 'active' : '')} style={{ overflow: 'visible' }}>
            {isFolder ? <div className="tn-toggle" onClick={() => setCollapsed(!collapsed)}>
                {/* <Tooltip content={!(fetchComplete && isLeaf) && "Toggle: " + title} placement="right"> */}
                {(!fetched || fetchComplete) ?
                    (index === 0 ?
                        <FontAwesomeIcon className="me-2" icon={['fas', collapsed ? 'caret-right' : 'caret-down']} /> :
                        (
                            fetchComplete && isLeaf ? <span style={{ marginRight: levelEdge }}><FontAwesomeIcon className="me-2" style={{ visibility: 'hidden' }} icon={['fas', collapsed ? 'caret-right' : 'caret-down']} /></span>
                                : <span style={{ marginRight: levelEdge }}><FontAwesomeIcon className="me-2" icon={['fas', collapsed ? 'caret-right' : 'caret-down']} /></span>
                        )
                    ) : <p><FontAwesomeIcon className="me-2" spin icon={['fal', 'spinner-third']} /></p>
                }
                {/* </Tooltip> */}
            </div> :
                <div>
                    <FontAwesomeIcon className="me-2" icon={['fad', mapFileTypeToIcon(data.type)]} />
                </div>}


            <div onClick={() => props.select()} className="tn-link d-flex justify-content-between align-items-center" style={{ textIndent: 0 }}>
                <Tooltip content={"Select: " + title + (!data.isPublished ? ' -- Unpublished' : '') + (!data.isTrashed ? ' -- Trashed' : '')} placement="top">
                    {!data.isPublished ? <FontAwesomeIcon className="text-danger me-2" icon={['fal', 'circle']} /> : null} {data.isTrashed ? <FontAwesomeIcon className="me-2" icon={['fas', 'trash']} /> : null} {title}
                </Tooltip>
                {isActive && <div>
                    <FontAwesomeIcon className="me-2 text-primary" icon={['fas', 'check']} />
                </div>}
            </div>


        </div>
    );
}

