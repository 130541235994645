import { lazy, Suspense } from 'react';
import { LoadingPage } from '../components/loading/loading-page';
import { UnpublishedProps } from '../pages/unpublished/unpublished';

const Page = lazy(() => import('../pages/unpublished/unpublished'))

export default function Unpublished(props: UnpublishedProps) {
    return (
        <Suspense fallback={<LoadingPage />}>
            <Page {...props} />
        </Suspense>
    )
}
