import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback } from "react";
import { useValidatedFormValue } from "../../../../hooks/forms/use-validated-form-value";
import { ValidatedInputProps } from "../input-props";

function validate(value: string, required: boolean) {
    const errors: string[] = [];
    if (!value && required) {
        errors.push('Description is required')
    }
    if (value.length > 750) {
        errors.push('Description cannot exceed 750 characters in length')
    }

    return errors;
}

export function DescriptionInput(props: ValidatedInputProps<'description'> & { required?: boolean }) {
    const { required } = props;

    const validation = useCallback((value: string) => {
        return validate(value, required || false)
    }, [required]);

    const {
        errors, invalid, onInputChange, value
    } = useValidatedFormValue('description', props, validation);

    return (
        <div className="mb-3">
            <label htmlFor="itemDescription" className={`form-label${invalid ? ' required' : ''}`}>Description{invalid && <FontAwesomeIcon icon={['fas', 'asterisk']} />}:</label>
            <textarea className={`form-control${invalid ? ' is-invalid' : ''}`} id="itemDescription" cols={50} rows={3}
                placeholder="Please enter a description..."
                value={value} onChange={onInputChange}
            />
            {errors.map((message, i) => <small key={i} className="form-error text-danger">{message}</small>)}
        </div>
    )
}