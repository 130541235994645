import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function Error() {
    return (
        <div className="w-100 py-4 text-center">
            <span className="display-2 text-danger">
                <FontAwesomeIcon icon={['fad', 'exclamation-circle']} />
            </span>
            <p className="text-muted">An error has occured</p>
        </div>
    );
}