import { Api } from '@lcms/api-definition';
import { labels, saveLabel, removeLabel } from './endpoints/labels';
import { itemsLabelsEndpoint } from './endpoints/admin/items-labels-endpoint';
import { search } from './endpoints/search';
import { dashboardRecent } from './endpoints/dashboard/dashboard-recent';
import { recentActivityEndpoint } from './endpoints/dashboard/recent-activity';
import { scheduledPublish } from './endpoints/dashboard/scheduled-publish';
import { scheduledUnpublish } from './endpoints/dashboard/scheduled-unpublish';
import { dashboardStarred } from './endpoints/dashboard/dashboard-starred';
import { dashboardTrending } from './endpoints/dashboard/dashboard-trending';
import { unpublishedFiles } from './endpoints/dashboard/unpublished-files';
import { file } from './endpoints/files/file';
import { fileTypes } from './endpoints/files/file-types';
import { getFileLocations } from './endpoints/files/file-location';
import { logAction } from './endpoints/files/log-action';
import { fileStatsEndpoint } from './endpoints/files/file-stats';
import { folder } from './endpoints/folders/folder';
import { getFolderLocations } from './endpoints/folders/folder-location';
import { subdirectoriesEndpoint } from './endpoints/folders/subdirectories';
import { saveFolderEndpoint } from './endpoints/folders/save-folder';
import { folderStatsEndpoint } from './endpoints/folders/folder-stats';
import { languages } from './endpoints/languages';
import { checkUrl } from './endpoints/check-url';
import { recentEndpoint } from './endpoints/files/recent';
import { starredEndpoint } from './endpoints/files/starred';
import { trendingEndpoint } from './endpoints/files/trending';
import { trashedEndpoint } from './endpoints/files/trashed';
import { untrackedEndpoint } from './endpoints/files/untracked';
import { trashFileEndpoint } from './endpoints/files/trash';
import { restoreFileEndpoint } from './endpoints/files/restore';
import { deleteFileEndpoint } from './endpoints/files/delete';
import { trashFolderEndpoint } from './endpoints/folders/trash';
import { restoreFolderEndpoint } from './endpoints/folders/restore';
import { deleteFolderEndpoint } from './endpoints/folders/delete';
import { downloadItemsEndpoint } from './endpoints/download';
import { checkMoveEndpoint } from './endpoints/admin/check-move-endpoint';
import { moveEndpoint } from './endpoints/admin/move-endpoint';
import { copyEndpoint } from './endpoints/admin/copy-endpoint';
import { trashEndpoint } from './endpoints/admin/trash-endpoint';
import { deleteEndpoint } from './endpoints/admin/delete-endpoint';
import { publishEndpoint } from './endpoints/admin/publish-endpoint';
import { promoteEndpoint } from './endpoints/admin/promote-endpoint';
import { labelEndpoint } from './endpoints/admin/label-endpoint';
import { reportsEndpoint } from './endpoints/admin/report-endpoint';
import { fileExistsEndpoint } from './endpoints/admin/file-exists-endpoint';
import { editLanguageEndpoint } from './endpoints/admin/edit-language-endpoint';
import { get } from './http/get';
import { post } from './http/post';


export const api = new Api(get(), post())
    .use(dashboardStarred.toDefinition())
    .use(dashboardTrending.toDefinition())
    .use(dashboardRecent.toDefinition())
    .use(search.toDefinition())
    .use(recentActivityEndpoint.toDefinition())
    .use(itemsLabelsEndpoint.toDefinition())
    .use(labels.toDefinition())
    .use(saveLabel.toDefinition())
    .use(removeLabel.toDefinition())
    .use(scheduledPublish.toDefinition())
    .use(scheduledUnpublish.toDefinition())
    .use(unpublishedFiles.toDefinition())
    .use(folder.toDefinition())
    .use(file.toDefinition())
    .use(fileTypes.toDefinition())
    .use(subdirectoriesEndpoint.toDefinition())
    .use(languages.toDefinition())
    .use(logAction.toDefinition())
    .use(checkUrl.toDefinition())
    .use(getFolderLocations.toDefinition())
    .use(getFileLocations.toDefinition())
    .use(saveFolderEndpoint.toDefinition())
    .use(fileStatsEndpoint.toDefinition())
    .use(folderStatsEndpoint.toDefinition())
    .use(recentEndpoint.toDefinition())
    .use(starredEndpoint.toDefinition())
    .use(trendingEndpoint.toDefinition())
    .use(trashFileEndpoint.toDefinition())
    .use(restoreFileEndpoint.toDefinition())
    .use(trashFolderEndpoint.toDefinition())
    .use(restoreFolderEndpoint.toDefinition())
    .use(trashedEndpoint.toDefinition())
    .use(untrackedEndpoint.toDefinition())
    .use(checkMoveEndpoint.toDefinition())
    .use(copyEndpoint.toDefinition())
    .use(moveEndpoint.toDefinition())
    .use(downloadItemsEndpoint.toDefinition())
    .use(trashEndpoint.toDefinition())
    .use(publishEndpoint.toDefinition())
    .use(promoteEndpoint.toDefinition())
    .use(labelEndpoint.toDefinition())
    .use(reportsEndpoint.toDefinition())
    .use(deleteFileEndpoint.toDefinition())
    .use(deleteFolderEndpoint.toDefinition())
    .use(deleteEndpoint.toDefinition())
    .use(editLanguageEndpoint.toDefinition())
    .use(fileExistsEndpoint.toDefinition())
    ;