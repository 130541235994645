import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { LoadingPage } from '../../components/loading/loading-page';
import { useLoginFunction } from '../../hooks/auth/use-login-function';
import { routes } from '../../routing/routes';
import { PageProps } from '../../routing/routing';

export interface LoginProps extends PageProps {

}

export default function Login(props: LoginProps) {
    const login = useLoginFunction();
    const { inProgress } = useMsal();
    const authenticated = useIsAuthenticated();

    const { history } = props;
    useEffect(() => {
        if (inProgress === InteractionStatus.None) {
            if (authenticated) {
                history.push(routes.dashboard)
            } else {
                login();
            }
        }
    }, [login, inProgress, authenticated, history])

    return <LoadingPage />;
}
