import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo, useCallback, useMemo, useState } from "react";
import { SaveFilePayload } from "../../../api/endpoints/files/save-file";
import { useSwitch } from "../../../hooks/use-switch";
import { dateToString } from "../../../services/date-conversion";
import { formatFileSize } from "../../../services/format-file-size";
import { today } from "../../../types/dates";
import { DescriptionInput } from "../admin-dialogue/form-components/description-input";
import { TitleInput } from "../admin-dialogue/form-components/title-input";
import { useForm } from "../../form/use-form";
import { Dots } from "../../loading/spinner";
import { FileUpload } from "../../../contexts/admin-panel/use-bulk-upload";

interface FileBulkUploadForm {
    title: string;
    description: string;
}
function newFormEntry(): FileBulkUploadForm {
    return {
        title: '',
        description: ''
    }
}

export function UploadFile(props: { model: FileUpload, skip: (file: File, undo?: boolean) => void; upload: (file: File, meta: SaveFilePayload) => void; parentId?: string; }) {
    const { skip, upload, parentId } = props;
    const { state, file } = props.model;
    const rowClass = useMemo(() => {
        switch (state.status) {
            case 'Complete':
                return 'uploaded';
            case 'Error':
                return 'bulk-error';
            case 'Pending':
                return 'editing';
            case 'Skipped':
                return 'skipped';
            case 'Uploading':
                return 'uploading';
        }
    }, [state.status])

    const [, markDirty] = useSwitch(false);
    const [isValid, setValidity] = useState(false);
    const form = useForm(file, newFormEntry, markDirty, setValidity)
    const { formValues } = form;
    const onUploadClick = useCallback(() => {
        upload(file, {
            title: formValues.title,
            description: formValues.description,
            parents: [parentId],
            publishStartDate: dateToString(today),
            rollback: false,
            labels: [],
            links: [],
        })
    }, [file, formValues.description, formValues.title, parentId, upload]);

    const onSkipClick = useCallback(() => {
        skip(file);
    }, [file, skip]);

    const onUndoSkipClick = useCallback(() => {
        skip(file, true);
    }, [file, skip]);

    if (state.status === 'Complete')
        return <FileRowComplete file={file} title={formValues.title} description={formValues.description} />
    if (state.status === 'Uploading')
        return <FileRowUploading file={file} title={formValues.title} description={formValues.description} />
    if (state.status === 'Skipped')
        return <FileRowSkipped file={file} undo={onUndoSkipClick} />

    return (
        <tr className={rowClass}>
            <td><TitleInput value={form.formValues.title} onChange={form.setField} onValidate={form.setFieldValidity} /></td>
            <td><DescriptionInput required value={form.formValues.description} onChange={form.setField} onValidate={form.setFieldValidity} /></td>
            <FileStats file={file} />
            <FileControls invalid={!isValid} skip={onSkipClick} upload={onUploadClick} hadError={state.status === 'Error'} />
        </tr>
    );
}

const FileRowComplete = memo((props: { file: File, title: string; description: string; }) => (
    <tr className='uploaded'>
        <ReadonlyFields title={props.title} description={props.description} />
        <FileStats file={props.file} />
        <UploadComplete />
    </tr>
))

const FileRowUploading = memo((props: { file: File, title: string; description: string; }) => (
    <tr className='uploading'>
        <ReadonlyFields title={props.title} description={props.description} />
        <FileStats file={props.file} />
        <UploadInProgress />
    </tr>
))

const FileRowSkipped = memo((props: { file: File, undo: () => void; }) => (
    <tr className='skipped'>
        <td colSpan={2} className="py-3"><span className="bg-white d-flex p-2 rounded-2 text-dark fw-bold ps-4">File upload skipped</span></td>
        <FileStats file={props.file} />
        <UndoSkip undo={props.undo} />
    </tr>
))

const ReadonlyFields = memo((props: { title: string; description: string; }) => (
    <>
        <td>{props.title}</td>
        <td>{props.description}</td>
    </>
))

const FileStats = memo((props: { file: File }) => (
    <td>
        <small className="fw-bold" style={{ fontSize: '12px' }}>
            {props.file.name}</small>
        <div className="text-uppercase pt-2 border-top mt-2 text-end" style={{ fontSize: '11px' }}>
            {formatFileSize(props.file.size)}
        </div>


    </td>
))

const UndoSkip = memo((props: { undo: () => void; }) => (
    <td className="p-3">
        <button className="btn btn-sm btn-dark text-center w-100" type="button" onClick={props.undo}>
            Undo Skip
        </button>
    </td>
))

const FileControls = memo((props: { upload: () => void; skip: () => void; invalid: boolean; hadError: boolean; }) => (
    <td className="p-3">
        <button disabled={props.invalid} className={"btn btn-sm text-center w-100 btn-primary"} type="button" onClick={props.upload}>
            {props.hadError ? <><FontAwesomeIcon icon={['far', 'redo']} className="me-2" /> Try Again</> : <>Upload File</>}
        </button>
        <button className="mt-3 w-100 text-center btn btn-sm btn-outline-dark" type="button" onClick={props.skip}>
            Skip File
        </button>
    </td>
))

const UploadComplete = memo(() => (
    <td>
        <div className="d-flex justify-content-between align-items-center p-3">
            <FontAwesomeIcon icon={['fad', 'file-check']} color='#198754' className="me-3 fa-2x" />
            <small className="text-success">File Uploaded</small>
        </div>
    </td>
))

const UploadInProgress = memo(() => (
    <td>
        <div className="d-flex justify-content-between align-items-center p-3">
            <div>
                <Dots size="0.5rem" />
                <small className="text-muted">Uploading</small>
            </div>
        </div>
    </td>
))