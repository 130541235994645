import { useCallback, useEffect, useMemo, useState } from "react";
import { FormField } from "./types/form-field";
import { FormValidity } from "./types/form-validity";

export function useForm<TForm, TDefault>(defaultValues: TDefault, map: (d: TDefault) => TForm, onDirty: () => void, onValidate: (isValid: boolean) => void) {
    const [formValues, setFormValues] = useState<TForm>(map(defaultValues));
    const [formValidity, setValidity] = useState<FormValidity<TForm>>({});

    useEffect(() => {
        setFormValues(map(defaultValues));
    }, [defaultValues, map])

    const formIsValid = useMemo(() => {
        return (Object.keys(formValidity) as FormField<TForm>[])
            .map((key) => formValidity[key])
            .filter(x => !x).length === 0;
    }, [formValidity]);

    const setField = useCallback((field: FormField<TForm>) => {
        return (value: any) => {
            onDirty();
            setFormValues(previous => {
                return {
                    ...previous,
                    [field]: value
                };
            });
        };
    }, [onDirty]);

    const setFieldValidity = useCallback((field: FormField<TForm>) => {
        return (isValid: boolean) => {
            setValidity(previous => {
                return {
                    ...previous,
                    [field]: isValid
                };
            });
        };
    }, []);

    useEffect(() => {
        onValidate(formIsValid);
    }, [formIsValid, onValidate]);

    return {
        formValues,
        formIsValid,
        setField,
        setFieldValidity
    };
}
