import { InlineModal } from "../../modal/modal-inline";
import { ConfirmationModalProps } from "./confirmation-modal";


export function InlineConfirmationModal(props: ConfirmationModalProps) {
    return (
        <InlineModal
            fixed
            lg={props.lg}
            visible={props.visible}
            header={props.header || 'Are you sure?'}
            onHide={props.onReject}
            Footer={<div className="d-flex justify-content-between w-100">
                <button type="button" className={`btn btn-outline-dark border-0 me-3 ${props.lg ? '' : 'btn-sm'}`} aria-label="Cancel" onClick={props.onReject}>{props.rejectText || 'No'}</button>
                <button type="button" className={`btn ${props.lg ? '' : 'btn-sm'} ${props.acceptIsDangerous ? 'btn-danger' : 'btn-primary'}`} aria-label="Submit" onClick={props.onAccept}>{props.acceptText || 'Yes'}</button>

            </div>}
        >
            <div className="p-2">{props.prompt}</div>
        </InlineModal>
    );
}
