import { ApiCall, ParameterTypes } from '@lcms/api-definition';
import { copy } from '../../api-endpoint-definition.json';

export interface CopyResponse {
    success: boolean;
}

export const copyEndpoint = new ApiCall<CopyResponse>().post('copy', {
    route: copy,
    params: {
        destinationId: {
            type: ParameterTypes.String as string | undefined
        },
        sourceId: {
            type: ParameterTypes.String as string | undefined
        },
        folderIds: {
            type: ParameterTypes.StringArray
        },
        fileIds: {
            type: ParameterTypes.StringArray
        }
    }
});