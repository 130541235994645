import { ApiCall } from '@lcms/api-definition';
import { FileType } from '../../../types/file-types';
import { getFileTypes } from '../../api-endpoint-definition.json';

export interface FileTypesResponse {
    types: FileType[]
}

export const fileTypes = new ApiCall<FileTypesResponse>().get('fileTypes', {
    route: getFileTypes
});