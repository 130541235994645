import { PropsWithChildren } from "react";
import { TooltipMessage, TooltipMessageConfiguration } from "./tooltip-message";
import { useTooltip } from "./use-tooltip";

export interface TooltipProps extends TooltipMessageConfiguration {
}

export function Tooltip(props: PropsWithChildren<TooltipProps>) {
    const { ...messageProps } = props;
    const { isVisible, show, hide } = useTooltip();

    return (
        <div
            className={ToolTipWrapper}
            onMouseEnter={show}
            onMouseLeave={hide}
        >
            {props.children}
            <TooltipMessage {...messageProps} visible={isVisible} />
        </div>
    );
}

export const ToolTipWrapper = "tooltip-wrapper"

