import { useCallback } from 'react';
import { loginRequest } from "../../services/authentication/auth.config";
import { IPublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

function login(instance: IPublicClientApplication) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
}

export function useLoginFunction() {
    const { instance } = useMsal();
    const loginFunction = useCallback(() => login(instance), [instance]);
    return loginFunction;
}