import { ApiCall } from '@lcms/api-definition';
import { DetailedFolderInfo } from '../../../types/detailed-folder-info';
import { starredFiles } from '../../api-endpoint-definition.json';

export interface StarredFileResponse {
    starred: DetailedFolderInfo
}

export const starredEndpoint = new ApiCall<StarredFileResponse>().get('starred', {
    route: starredFiles
});