import { useEffect, useRef } from "react";

export function useOnClickOutside<T extends HTMLElement>(handler: () => void) {
    const ref = useRef<T | null>(null)

    useEffect(
        () => {
            const listener = (event: MouseEvent | TouchEvent) => {
                if (!ref.current || ref.current.contains(event.target as HTMLElement)) {
                    return;
                }
                handler();
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        },
        [ref, handler]
    );

    return ref;
}