import { useCallback, useEffect, useMemo, useState } from "react";
import { useDataSync } from "../../../hooks/use-data-sync";
import { BreadCrumbs } from "../../../services/bread-crumbs";
import { HelpScout } from "../../../services/help-scout";
import { Modal } from "../../modal/modal";
import { ConfirmationModalProps } from "../confirmation-modal/confirmation-modal";
import { InlineConfirmationModal } from "../confirmation-modal/inline-confirmation-modal";
import { BulkUploadContent } from "./bulk-upload-content";
import { BulkUploadStatus } from "./bulk-upload-status";
import { useBulkUpload } from "./use-bulk-upload";

export function BulkUploadDialogue() {
    const { visible, hide, uploadFile, batch, skipFile } = useBulkUpload();
    const [confirmProps, setConfirmProps] = useState<ConfirmationModalProps>();
    const onFinish = useCallback(() => {
        setConfirmProps({
            onAccept: () => {
                if (batch) {
                    batch.files.forEach(file => {
                        if (!file.state.complete) {
                            skipFile(file.file)
                        }
                    })
                }
                setConfirmProps(undefined);
            },
            onReject: () => {
                setConfirmProps(undefined);
            },
            visible: true,
            acceptText: 'End Process',
            rejectText: 'Back To File Uploads',
            header: 'There are files that have not been uploaded and will be skipped',
            prompt: 'Are you sure you want to end the bulk upload process?',
            acceptIsDangerous: true
        })
    }, [batch, skipFile])

    const syncDirty = useDataSync();

    const onHide = useCallback(() => {
        syncDirty([{
            type: 'Folder',
            id: batch?.parent.id
        }])
        hide();
    }, [batch?.parent.id, hide, syncDirty])

    const path = useMemo(() => {
        if (!batch) return '';
        return new BreadCrumbs(batch.parent.breadCrumbs).toPath();
    }, [batch])

    const isDone = useMemo(() => {
        return !batch?.files.filter(f => f.state.complete === false).length
    }, [batch])

    useEffect(() => {
        if (visible) {
            HelpScout.hide('bulk-upload-modal');
        } else {
            HelpScout.show('bulk-upload-modal');
        }
    }, [visible])

    return (
        <Modal
            showHeaderClose={false}
            visible={visible}
            onHide={isDone ? onHide : onFinish}
            scrollable
            sizeXL fullXl
            Footer={
                <div className="d-flex justify-content-end">
                    {isDone ? <button type="button" className="btn btn-success pulse-success" onClick={onHide}>Close Bulk File Upload</button>
                        : <button type="button" className="btn btn-primary pulse" onClick={onFinish}>Finish Bulk Upload</button>}
                </div>
            }
            header={isDone ? 'Bulk File Upload Completed' : path}
        >
            {isDone ?
                <BulkUploadStatus complete files={batch?.files || []} />
                : <BulkUploadContent files={batch?.files || []} upload={uploadFile} skip={skipFile} parentId={batch?.parent.id} />
            }
            {confirmProps && <InlineConfirmationModal {...confirmProps} />}
        </Modal>
    );
}