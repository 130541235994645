import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './styles/index.scss';
import './styles/font-awesome';

import App from './App';
import NotSupported from './pages/not-supported';
import { compatability, standardCompatability } from '@lcms/supported-browsers'

ReactDOM.render(
  <React.StrictMode>
    {compatability(standardCompatability) ? <App /> : <NotSupported />}
  </React.StrictMode>,
  document.getElementById('root')
);

