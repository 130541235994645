import { PropsWithChildren } from 'react';
import { useDraggable } from '../../hooks/ui/use-draggable';
import { Node } from './tree'

export interface DraggableProps<T> {
    allow: boolean;
    id: string;
    parent: Node<T> | undefined;
    dragText?: string;
    onDrag?: (source: string) => void | Promise<void>;
    onDragEnd?: () => void | Promise<void>;
}

export function Draggable<T>(props: PropsWithChildren<DraggableProps<T>>) {

    const { onDrag: onDragStartEvent, onDragEnd: onDragEndEvent, id, dragText, parent } = props;

    const draggableProps = useDraggable({
        type: 'application/tree-node',
        value: JSON.stringify({
            id: id,
            parent: parent?.data
        })
    }, {
        onDragEnd: onDragEndEvent,
        onDragStart: onDragStartEvent
    }, dragText)


    return (
        <div id={props.id}
            draggable={props.allow && !!props.id}
            className={'tree-btn ' + (props.id ? '' : 'tree-main')}
            {...draggableProps}>
            {props.children}
        </div>
    );
}

