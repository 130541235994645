import { useCallback, useState } from "react";
import { toggle } from "../../../services/toggle";
import { Modal } from "../../modal/modal";
import { FileTypePickerProps, FileTypesList } from "./file-types-list";

export interface FileTypeModalProps {
    visible: boolean;
    onHide: () => void;
    selected: string[];
    toggle: (value: string) => void;
    onDone: (selected: string[]) => void;
}

export function FileTypeModal(props: FileTypeModalProps) {
    const { onDone, selected } = props;
    const onDoneClick = useCallback(() => {
        onDone(selected)
    }, [onDone, selected])

    return (
        <Modal visible={props.visible} onHide={props.onHide} scrollable noPadding showHeaderClose={false}
            Footer={<div className="d-flex w-100 d-flex justify-content-between">
                <button type="button" className="btn btn-outline-dark" onClick={props.onHide}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={onDoneClick}>Done</button>
            </div>}
        >
            <div className="alert alert-secondary rounded-0 mb-0">
                <b>Select file type(s) to narrow your search:</b><br />
            </div>
            <FileTypesList selected={selected} toggle={props.toggle} />
        </Modal>
    );
}

export type ShowFileTypePickerProps = Omit<FileTypePickerProps, 'toggle'> & { onDone: (changes: string[]) => void; includeFiles?: boolean };
export function useFileTypeModal() {
    const [options, setOptions] = useState<ShowFileTypePickerProps>()
    const show = useCallback((p: ShowFileTypePickerProps) => {
        const onDone = (changes: string[]) => {
            p.onDone(changes || []);
            setOptions(undefined);
        }
        setOptions({
            selected: p.selected,
            includeFiles: p.includeFiles,
            onDone: onDone
        })
    }, []);
    const hide = useCallback(() => { setOptions(undefined) }, []);

    const toggleItem = useCallback((type: string) => {
        if (!options) { return; }
        const newFilters = toggle(type, options.selected)
        setOptions({
            ...options,
            selected: newFilters
        })
    }, [options]);

    return {
        options,
        show, hide,
        toggleItem
    }
}
