import { DateField } from "./date-field";
import { threeYears, today } from "../../../../../types/dates";
import { PublishAccordionProps } from "./publish-accordion";

export function PublishDateControls(props: Omit<Omit<PublishAccordionProps, 'currentStartDate'>, 'currentEndDate'>) {
    const { onChange, publishEndDate, publishStartDate } = props;

    return (
        <div className="mt-2 p-2">
            <div className="w-100 d-flex justify-content-between align-items-center mt-3">
                <div className="mx-1">
                    <label htmlFor="itemTitle" className="form-label">Start:</label>
                    <DateField placeAbove max={threeYears} min={today} value={publishStartDate} onChange={(value) => {
                        onChange('publishStartDate')(value);
                        if (value && publishEndDate && publishEndDate < value) {
                            onChange('publishEndDate')(value);
                        }
                    }} />
                </div>
                <div className="mx-1">
                    <label htmlFor="itemTitle" className="form-label">End:</label>
                    <DateField max={threeYears} min={publishStartDate || today} value={publishEndDate} onChange={onChange('publishEndDate')}
                        placeAbove placeLeft clearable />
                </div>
            </div>
        </div>
    );
}
