import { useEffect } from 'react';
import { logError } from '../services/log-error';

interface InlineScriptProps {
    type: string;
    code: string;
}

interface ExternalScriptProps {
    url: string;
    defer?: boolean;
    async?: boolean
}

export function InlineScript(props: InlineScriptProps) {
    useEffect(() =>
        addScript(script => {
            script.type = props.type;
            script.innerHTML = props.code;
        }),
        [props.type, props.code]);

    return null;
}

export function ExternalScript(props: ExternalScriptProps) {
    useEffect(() =>
        addScript(script => {
            script.src = props.url;
            script.defer = typeof props.defer === 'undefined' ? false : props.defer;
            script.async = typeof props.async === 'undefined' ? true : props.async;
        }),
        [props.url, props.defer, props.async]);

    return null;
}

function addScript(callback: (script: HTMLScriptElement) => void) {
    const script = document.createElement('script');
    callback(script);
    document.body.appendChild(script);

    return () => {
        try {
            document.body.removeChild(script);
        } catch (error: any) {
            logError('Error cleaning up script:', error);
        }
    }
}
