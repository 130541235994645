import { ApiCall } from '@lcms/api-definition';
import { DetailedFolderInfo } from '../../../types/detailed-folder-info';
import { scheduledForPublish } from '../../api-endpoint-definition.json';

export interface ScheduledForPublishResponse {
    pending: DetailedFolderInfo
}

export const scheduledPublish = new ApiCall<ScheduledForPublishResponse>().get('scheduledForPublish', {
    route: scheduledForPublish
});