import { ApiCall, ParameterTypes, PostPayload } from '@lcms/api-definition';
import { restoreFile } from '../../api-endpoint-definition.json';

export type RestoreFilePayload = PostPayload<typeof restoreFileEndpoint>

export interface RestoreResponse {
    success: boolean;
}

export const restoreFileEndpoint = new ApiCall<RestoreResponse>().post('restoreFile', {
    route: restoreFile,
    params: {
        id: {
            type: ParameterTypes.String
        }
    }
});