import { ApiCall, ParameterTypes } from '@lcms/api-definition';
import { editLanguage } from '../../api-endpoint-definition.json';

export interface EditLanguageResponse {
    success: boolean;
}

export const editLanguageEndpoint = new ApiCall<EditLanguageResponse>().post('editLanguage', {
    route: editLanguage,
    params: {
        fileIds: {
            type: ParameterTypes.StringArray
        },
        languageId: {
            type: ParameterTypes.String
        }
    }
});