import { ApiCall } from '@lcms/api-definition';
import { DownloadStats } from '../../../types/download-stats';
import { folderStats } from '../../api-endpoint-definition.json';

export interface FolderStatResponse {
    stats: DownloadStats
}

export const folderStatsEndpoint = new ApiCall<FolderStatResponse>().get('folderStats', {
    route: folderStats
});