const getTextSize = ((text: string, font: string) => {
    const temp = getTextSize.canvas || (getTextSize.canvas = document.createElement("canvas"));
    const context = temp.getContext("2d");
    if (!context) return 0;
    context.font = font;
    return context.measureText(text).width;
}) as ((text: string, font: string) => number) & { canvas: HTMLCanvasElement }

export function setDragText(event: React.DragEvent<HTMLElement>, text: string) {
    const font = '15px Oswald';
    const canvas = document.createElement("canvas");
    canvas.id = 'temp-canvas'
    canvas.style.position = "absolute";
    canvas.style.left = '-100%';
    canvas.width = getTextSize(text, font) + 10;
    canvas.height = 25;
    document.body.append(canvas);

    const ctx = canvas.getContext("2d");
    if (!ctx) { return; }
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "#0d6efd";
    ctx.font = font
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle'
    ctx.fillText(text, canvas.width / 2, canvas.height / 2);

    event.dataTransfer.setDragImage(canvas, -10, -10);
}

export function clearDragText() {
    document.getElementById('temp-canvas')?.remove();
}