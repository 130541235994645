import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { Cookie } from '../services/authentication/cookies';
import { throwError } from './throw-error';

export type Layout = 'grid' | 'table';

export interface LayoutContextType {
    layout: Layout;
    cycle: () => void;
    set: (layout: Layout) => void;
}

function next(layout: Layout): Layout {
    switch (layout) {
        case 'grid':
            return 'table';
        case 'table':
        default:
            return 'grid';
    }
}

const cookie = new Cookie<Layout>('app-layout', 15, true);

export const LayoutContext = React.createContext<LayoutContextType>({
    layout: 'grid',
    set: throwError,
    cycle: throwError,
});

export function LayoutContextProvider(props: PropsWithChildren<{}>) {
    const [layout, setLayout] = useState<Layout>(cookie.get('grid'));
    const cycle = useCallback(() => {
        setLayout(next(layout));
    }, [layout]);

    useEffect(() => {
        cookie.set(layout)
    }, [layout])

    return <LayoutContext.Provider value={{
        layout,
        cycle,
        set: setLayout
    }}>
        {props.children}
    </LayoutContext.Provider>
};

