import { Dots } from "./spinner";

export function Loading() {
    return (
        <div style={{ position: 'absolute', width: '100%', top: 50, bottom: 0 }}>
            <div className="d-flex h-100 justify-content-center">
                <div className="align-self-center text-center">
                    <Dots />
                    <span className="text-muted">
                        Loading
                    </span>
                </div>
            </div>
        </div>
    );
}