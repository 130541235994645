import { ApiCall, ParameterTypes } from '@lcms/api-definition';
import { publish } from '../../api-endpoint-definition.json';

export interface PublishResponse {
    success: boolean;
}

export const publishEndpoint = new ApiCall<PublishResponse>().post('publish', {
    route: publish,
    params: {
        folderIds: {
            type: ParameterTypes.StringArray
        },
        fileIds: {
            type: ParameterTypes.StringArray
        },
        publishStartDate: {
            type: ParameterTypes.String,
            optional: true
        },
        publishEndDate: {
            type: ParameterTypes.String,
            optional: true
        }
    }
});