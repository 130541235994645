/* eslint-disable react/jsx-no-target-blank */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Tooltip } from "../../../tooltip/tooltip";
import { ValidatedInputProps } from "../input-props";
import { downloadFileRollback } from '../../../../api/api-endpoint-definition.json';

interface FileDropZoneProps extends ValidatedInputProps<'file', File | undefined> {
    thumbnail?: string;
    id?: string;
    canRollback: boolean;
    rollback: boolean;
    onRollbackChange: (field: 'rollback') => (newValue: boolean) => void;
}

export function FileDropZone(props: FileDropZoneProps) {
    const { onChange: onFileChange, value: file, onValidate, id, onRollbackChange, rollback, canRollback } = props;
    const [isDragging, setIsDragging] = useState(false);

    const updateFile = useCallback((file?: File) => {
        onRollbackChange('rollback')(false);
        onFileChange('file')(file);
    }, [onFileChange, onRollbackChange]);

    const setRollback = useCallback(() => {
        onRollbackChange('rollback')(true);
        onFileChange('file')(undefined);
    }, [onFileChange, onRollbackChange])

    const clearQueue = useCallback(() => {
        updateFile(undefined)
    }, [updateFile])

    const onDragEnd = useCallback(() => setIsDragging(false), []);

    const onDragOver = useCallback((ev: React.DragEvent<HTMLDivElement>) => {
        if (!ev.dataTransfer.types.includes('Files') || ev.dataTransfer.items.length > 1 || ev.dataTransfer.files.length > 1) {
            return false;
        }

        ev.stopPropagation();
        ev.preventDefault();
        setIsDragging(true);
    }, [])

    const onDrop = useCallback((ev: React.DragEvent<HTMLDivElement>) => {
        setIsDragging(false);

        if (!ev.dataTransfer.types.includes('Files') || ev.dataTransfer.items.length > 1 || ev.dataTransfer.files.length > 1) {
            return false;
        }

        ev.stopPropagation();
        ev.preventDefault();

        const files: File[] = [];
        if (ev.dataTransfer.items) {
            for (let i = 0; i <= ev.dataTransfer.items.length; i++) {
                const item = ev.dataTransfer.items[i];
                try {
                    const entry = item.webkitGetAsEntry();
                    if (entry?.isDirectory) { continue; }
                } catch (er) { }

                const file = item?.kind === 'file' ? item.getAsFile() : null;
                if (file) {
                    files.push(file)
                }
            }
        } else {
            for (let i = 0; i <= ev.dataTransfer.files.length; i++) {
                const item = ev.dataTransfer.files[i];
                files.push(item)
            }
        }
        const file = files[0]
        if (file) {
            updateFile(file);
        }
    }, [updateFile])

    const invalid = useMemo(() => {
        return !id && !file;
    }, [file, id])

    useEffect(() => {
        onValidate('file')(!invalid)
    }, [invalid, onValidate])

    const inputRef = useRef<HTMLInputElement>(null)

    return (<>
        <div onDragOver={onDragOver} onDrop={onDrop} onDragLeave={onDragEnd}
        >
            <div className="d-flex justify-content-between align-items-center rounded-3 p-2" style={{
                border: !invalid ? '#0d6efd 1px dashed' : '#dc3545 1px solid', backgroundColor: '#E8F0FE',
                boxShadow: isDragging ? '0px 0px 10px 0px #0d6efd' : undefined
            }}>

                {rollback ? <div className="px-3 py-1 bg-light border border-primary rounded-2 me-4 h-100" style={{ fontSize: '14px', width: '150px', height: '300px' }}>
                    <Tooltip content="File will be uploaded on save" placement="right">
                        <div className="mb-1 d-flex justify-content-between align-items-center">
                            <b><small>Rollback:</small></b>
                        </div>
                    </Tooltip>
                    <div style={{ overflow: 'hidden' }}>
                        <a href={`${downloadFileRollback}/${id}`} target="_blank">View Rollback File</a>
                    </div>
                </div> :
                    !file ?
                        <div className="d-flex align-items-center justify-content-center bg-white" style={{
                            opacity: 0.75, borderRadius: '10px', overflow: "hidden", position: 'relative', top: '0', left: '-10px',
                            backgroundImage: `url("${props.thumbnail || ''}")`,
                            height: '125px', backgroundSize: 'cover', width: '150px', backgroundRepeat: 'no-repeat'
                        }}></div>
                        : <div className="px-3 py-1 bg-light border border-primary rounded-2 me-4 h-100" style={{ fontSize: '14px', width: '150px', height: '300px' }}>
                            <Tooltip content="File will be uploaded on save" placement="right">
                                <div className="mb-1 d-flex justify-content-between align-items-center">
                                    <b><small>Queued:</small></b>
                                </div>
                            </Tooltip>
                            <div style={{ overflow: 'hidden' }}>{file.name.length > 50 ? file.name.substring(0, 50) + "..." : file.name}</div>
                        </div>
                }
                <div className="flex-grow-1 pe-4"><b>Drop replacement file here, or
                    <button type="button" className="ps-1 btn btn-link" onClick={() => inputRef?.current?.click()}>browse</button>
                </b>
                    <div className="w-100 p-2 bg-light" style={{ fontSize: '.875em' }}>
                        {rollback ?
                            <button type="button" className="p-0 btn btn-sm btn-link" onClick={clearQueue}><FontAwesomeIcon icon={['fas', 'times']} className="me-2" />Cancel Rollback</button>
                            : file ? <button type="button" className="p-0 btn btn-sm btn-link" onClick={clearQueue}><FontAwesomeIcon icon={['fas', 'times']} className="me-2" />Cancel Upload</button>
                                : canRollback ? <button type="button" className="p-0 btn btn-sm btn-link" onClick={setRollback}><FontAwesomeIcon icon={['fas', 'undo']} className="me-2" />Rollback to last uploaded version</button>
                                    : <span>This file has no previous versions to rollback to</span>
                        }
                    </div>
                </div>

                <input ref={inputRef} className="d-none" type="file" onChange={e => updateFile(e.target.files?.item(0) || undefined)} />
            </div>

        </div>
        {invalid && <small className="form-error text-danger">You must upload a file</small>}
    </>
    );
}