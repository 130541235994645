import { useCallback, useMemo, useState } from 'react';
import { api } from '../../api/api';
import { BreadCrumb } from '../../types/bread-crumb';
import { MultiSelectItem } from '../selected-file';

export type ToMoveType = { selectedType: 'Folder' | 'File'; id: string; };

export interface MoverCopier {
    show: (items: MultiSelectItem[], destination: { id?: string; breadCrumbs: BreadCrumb[] }, sourceFolder: { id?: string; breadCrumbs: BreadCrumb[] }) => void;
    visible: boolean;
    hide: () => void;
    invalidFolders?: { id: string; name: string }[];
    destination?: { id?: string; breadCrumbs: BreadCrumb[] },
    source?: { id?: string; breadCrumbs: BreadCrumb[] },
    toMove?: ToMoveType[];
    validating: boolean;
}

export function useMoverCopier(isAdmin: boolean) {
    const [moveCopyData, setMoveCopyData] = useState<{
        sources: ToMoveType[];
        destination: { id?: string; breadCrumbs: BreadCrumb[]; };
        sourceFolder: { id?: string; breadCrumbs: BreadCrumb[]; };
        invalidFolders?: { id: string; name: string; }[];
    }>();

    const [validatingMove, setIsValdidatingMove] = useState(false);

    const showMoverCopier = useCallback((sources: MultiSelectItem[], destination: { id?: string; breadCrumbs: BreadCrumb[]; }, sourceFolder: { id?: string; breadCrumbs: BreadCrumb[]; }) => {
        setIsValdidatingMove(true);
        setMoveCopyData({
            destination: destination,
            sourceFolder: sourceFolder,
            sources: sources.filter(s => s.id) as ToMoveType[],
        });
        api.post.checkMove({
            destinationId: destination.id,
            sourceIds: sources.filter(s => s.selectedType === 'Folder' && s.id).map(s => s.id) as string[],
        }).then(result => {
            setIsValdidatingMove(false);
            if (result) {
                setMoveCopyData({
                    destination: destination,
                    sourceFolder: sourceFolder,
                    sources: sources.filter(s => s.id) as ToMoveType[],
                    invalidFolders: result.invalidFolders
                });
            } else {
                setMoveCopyData(undefined);
            }
        });
    }, []);

    const hideMoverCopier = useCallback(() => {
        setMoveCopyData(undefined);
    }, []);

    const moverCopier: MoverCopier = useMemo(() => {
        return {
            visible: !!moveCopyData && isAdmin,
            hide: hideMoverCopier,
            show: showMoverCopier,
            invalidFolders: moveCopyData?.invalidFolders,
            validating: validatingMove,
            destination: moveCopyData?.destination,
            toMove: moveCopyData?.sources,
            source: moveCopyData?.sourceFolder
        };
    }, [hideMoverCopier, isAdmin, moveCopyData, showMoverCopier, validatingMove]);

    return moverCopier;
}
