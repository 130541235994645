import { useCallback, useMemo, useState } from 'react';
import { DetailedFileInfo } from '../../types/detailed-file-info';
import { DetailedFolderInfo } from '../../types/detailed-folder-info';

export type FileEditModel = { type: 'File', model: DetailedFileInfo, file?: File };
export type FolderEditModel = { type: 'Folder', model: DetailedFolderInfo & { path: string } };
export type EditModel = FileEditModel | FolderEditModel;
export interface AddEditForm {
    visible: boolean;
    hide: () => void;
    show: (model: EditModel) => void;
    editing?: EditModel;
}

export function useAddEditForm(isAdmin: boolean) {
    const [model, setModel] = useState<EditModel>();

    const hide = useCallback(() => {
        setModel(undefined);
    }, []);

    const show = useCallback((toEdit: EditModel) => {
        setModel(toEdit);
    }, []);

    const form: AddEditForm = useMemo(() => {
        return {
            visible: !!model && isAdmin,
            hide: hide,
            show: show,
            editing: model,
        };
    }, [model, isAdmin, hide, show]);

    return form;
}
