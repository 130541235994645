import { useCallback, useMemo, useState } from 'react';
import { post } from "../../api/http/post";
import { saveFile } from '../../api/api-endpoint-definition.json';
import { SaveFilePayload, SaveResponse } from '../../api/endpoints/files/save-file';
import { FolderParent } from '../../types/folder-parent';

export type FileUploadState = { status: 'Pending', complete: false } | { status: 'Uploading', complete: false } | { status: 'Complete', complete: true } | { status: 'Error', complete: false } | { status: 'Skipped', complete: true }

export type FileUpload = { file: File, state: FileUploadState };

export type BulkUploadFolder = FolderParent;

export interface BulkUploader {
    show: (files: File[], parent: BulkUploadFolder) => void;
    visible: boolean;
    hide: () => void;
    uploadFile: (file: File, metaData: SaveFilePayload) => void;
    skipFile: (file: File, undo?: boolean) => void;
    batch?: { files: FileUpload[], parent: BulkUploadFolder };
}

export const pendingStatus: FileUploadState = { status: 'Pending', complete: false };
export const uploadingStatus: FileUploadState = { status: 'Uploading', complete: false };
export const completeStatus: FileUploadState = { status: 'Complete', complete: true };
export const errorStatus: FileUploadState = { status: 'Error', complete: false };
export const skippedStatus: FileUploadState = { status: 'Skipped', complete: true };

export function useBulkUpload(isAdmin: boolean) {

    const [filesToUpload, setFilesToUpload] = useState<FileUpload[]>();
    const [bulkUploadParent, setBulkUploadParent] = useState<BulkUploadFolder>();
    const uploadFiles = useCallback((files: File[], parent: BulkUploadFolder) => {
        setBulkUploadParent(parent);
        setFilesToUpload(files.map(file => {
            return {
                file: file,
                state: pendingStatus
            };
        }));
    }, []);
    const completeAllFiles = useCallback(() => {
        setBulkUploadParent(undefined);
        setFilesToUpload(undefined);
    }, []);

    const updateFileState = useCallback((file: File, state: FileUploadState) => {
        setFilesToUpload(prev => prev?.map(pending => {
            if (pending.file !== file) {
                return pending;
            } else {
                return {
                    file: pending.file,
                    state: state
                };
            }
        }));
    }, []);

    const skipFile = useCallback((file: File, undo = false) => {
        updateFileState(file, undo ? pendingStatus : skippedStatus);
    }, [updateFileState]);

    const uploadFile = useCallback((file: File, metaData: SaveFilePayload) => {
        updateFileState(file, uploadingStatus);

        const form = new FormData();
        form.append('file', file);
        form.append('metaData', JSON.stringify(metaData));
        post()(saveFile, form, false, false)
            .then((result: SaveResponse) => {
                if (result?.id) {
                    updateFileState(file, completeStatus);
                } else {
                    updateFileState(file, errorStatus);
                }
            });

    }, [updateFileState]);

    const bulkUpload = useMemo(() => {
        return {
            visible: !!filesToUpload && isAdmin,
            show: uploadFiles,
            hide: completeAllFiles,
            batch: filesToUpload && bulkUploadParent ? {
                files: filesToUpload,
                parent: bulkUploadParent
            } : undefined,
            uploadFile: uploadFile,
            skipFile: skipFile
        };
    }, [bulkUploadParent, completeAllFiles, filesToUpload, isAdmin, skipFile, uploadFile, uploadFiles]);

    return bulkUpload;
}
