import { PropsWithChildren } from 'react';

export interface ModalProps {
    header?: string;
    showHeaderClose?: boolean;
    onHide: () => void;
    visible: boolean;
    lg?: boolean;
    Footer?: React.ReactNode;
    fixed?: boolean;
}

export function InlineModal(props: PropsWithChildren<ModalProps>) {

    return (
        <div>
            <div className={`inline-modal${props.visible ? ' show' : ' hide'}${props.fixed ? ' position-fixed' : ''}`}>
                <div className={`inline-modal-dialogue${props.lg ? ' inline-modal-dialogue-lg' : ''}`}>
                    {(props.header || props.showHeaderClose) && <div className="modal-header">
                        {props.header && <h5 className="modal-title">{props.header}</h5>}
                        {props.showHeaderClose === true || typeof (props.showHeaderClose) === 'undefined' ?
                            <button type="button" className="btn-close" aria-label="Close" onClick={props.onHide}></button>
                            : null}
                    </div>}
                    {props.children && <div className="inline-modal-body">
                        {props.children}
                    </div>}
                    {props.Footer && <div className="inline-modal-footer">
                        {props.Footer}
                    </div>}

                </div>
            </div>
        </div >

    );
}