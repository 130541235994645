import { ApiCall } from '@lcms/api-definition';
import { FileInfo } from '../../../types/file-info';
import { getDashboardTrending } from '../../api-endpoint-definition.json';

export interface TrendingResponse {
    files: FileInfo[]
}

export const dashboardTrending = new ApiCall<TrendingResponse>().get('dashboardTrending', {
    route: getDashboardTrending
});