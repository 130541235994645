const Spinner = (props: { delay?: string }) => {
    return (
        <div className="spinner-grow mx-2" style={{ width: '3rem', height: '3rem', animationDelay: props.delay }} role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    );
}

export function LoadingPage() {
    return (
        <div style={{ position: 'absolute', width: '100%', top: 50, bottom: 0 }}>
            <div className="d-flex h-100 justify-content-center">
                <div className="align-self-center text-center">
                    <div>
                        <Spinner delay="-.2s" />
                        <Spinner delay="-.1s"/>
                        <Spinner />
                    </div>
                    <span className="display-5 text-muted">
                        Loading
                    </span>
                </div>
            </div>
        </div>
    );
}