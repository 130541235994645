import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { StaticContext } from 'react-router';
import AuthenticatedRoute from '../components/routes/authenticated-route';
import Error404 from '../pages/error-404';
import Dashboard from './dashboard/dashboard.lazy';
import DashboardMobile from './dashboard/dashboard.mobile.lazy';
import Folder from './folder/folder.lazy';
import FolderMobile from './folder/folder.mobile.lazy';
import Login from '../pages/login/login';
import Preview from './preview.lazy';
import Recent from './recent/recent.lazy';
import RecentMobile from './recent/recent.mobile.lazy';
import SearchResults from './search-results/search-results.lazy';
import SearchResultsMobile from './search-results/search-results.mobile.lazy';
import Starred from './starred/starred.lazy';
import StarredMobile from './starred/starred.mobile.lazy';
import Trending from './trending/trending.lazy';
import TrendingMobile from './trending/trending.mobile.lazy';
import Trash from './trash.lazy';
import Untracked from './untracked.lazy';
import Unpublished from './unpublished.lazy';
import PendingPublish from './pending-publish.lazy';
import PendingUnpublish from './pending-unpublish.lazy';
import { MobileTogglesProvider } from '../contexts/mobile-toggles';
import { useIsMobile } from '../hooks/ui/use-is-mobile';
import { routes } from './routes';


export type PageProps<TParams = { [index: string]: string | undefined; }, TLocation = unknown> = RouteComponentProps<TParams, StaticContext, TLocation>;

export default function Routing() {

    const isMobile = useIsMobile()

    if (isMobile) {
        return (
            <MobileTogglesProvider>
                <Switch>
                    <Route path={routes.index} exact render={() =>
                        <Redirect to={routes.dashboard} />
                    } />
                    <Route path={routes.dashboard} exact render={props =>
                        <DashboardMobile {...props} />
                    } />
                    <Route path={routes.recent} exact render={props =>
                        <RecentMobile {...props} />
                    } />
                    <Route path={routes.starred} exact render={props =>
                        <StarredMobile {...props} />
                    } />
                    <Route path={routes.trending} exact render={props =>
                        <TrendingMobile {...props} />
                    } />
                    <Route path={routes.searchResults} exact render={props =>
                        <SearchResultsMobile {...props} />
                    } />
                    <AuthenticatedRoute path={routes.admin.untracked} exact render={props =>
                        <Redirect to={routes.dashboard} />
                    } />
                    <AuthenticatedRoute path={routes.admin.trash} exact render={props =>
                        <Redirect to={routes.dashboard} />
                    } />
                    <AuthenticatedRoute path={routes.admin.labels} exact render={props =>
                        <Redirect to={routes.dashboard} />
                    } />
                    <Route path={routes.login} exact render={props =>
                        <Redirect to={routes.dashboard} />
                    } />
                    <Route path={'/file/preview/:fileId(.*)'} render={props =>
                        <Preview {...props} />
                    } />
                    <Route path={'/folder/:folderId(.*)?'} render={props =>
                        <FolderMobile {...props} />
                    } />
                    <Route>
                        <Error404 />
                    </Route>
                </Switch>
            </MobileTogglesProvider>
        );
    }

    return (
        <Switch>
            <Route path={routes.index} exact render={() =>
                <Redirect to={routes.dashboard} />
            } />

            <Route path={routes.dashboard} exact render={props =>
                <Dashboard {...props} />
            } />
            <Route path={routes.recent} exact render={props =>
                <Recent {...props} />
            } />
            <Route path={routes.starred} exact render={props =>
                <Starred {...props} />
            } />
            <Route path={routes.trending} exact render={props =>
                <Trending {...props} />
            } />
            <Route path={routes.searchResults} exact render={props =>
                <SearchResults {...props} />
            } />
            <AuthenticatedRoute path={routes.admin.untracked} exact render={props =>
                <Untracked {...props} />
            } />
            <AuthenticatedRoute path={routes.admin.trash} exact render={props =>
                <Trash {...props} />
            } />
            <AuthenticatedRoute path={routes.admin.unpublished} exact render={props =>
                <Unpublished {...props} />
            } />
            <AuthenticatedRoute path={routes.admin.pendingPublish} exact render={props =>
                <PendingPublish {...props} />
            } />
            <AuthenticatedRoute path={routes.admin.pendingUnpublish} exact render={props =>
                <PendingUnpublish {...props} />
            } />
            <Route path={routes.login} exact render={props =>
                <Login {...props} />
            } />
            <Route path={'/file/preview/:fileId(.*)'} render={props =>
                <Preview {...props} />
            } />
            <Route path={'/folder/:folderId(.*)?'} render={props =>
                <Folder {...props} />
            } />
            <Route>
                <Error404 />
            </Route>
        </Switch>
    )
}