import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "../../modal/modal";

export interface LocateModalProps {
    visible: boolean;
    onHide: () => void;
    locations: {
        text: string;
        url: string;
    }[];
}

export function LocateModal(props: LocateModalProps) {
    return (
        <Modal
            header={`Parent Folder${props.locations.length > 1 ? 's' : ''}`}
            showHeaderClose={true}
            visible={props.visible}
            onHide={props.onHide}
        >
            {props.locations.map(l =>
                <div key={l.url} >
                    <Link to={l.url} onClick={props.onHide}>{l.text}</Link>
                </div>)}
        </Modal>
    );
}

export type ShowLocateModalProps = Omit<Omit<LocateModalProps, 'onHide'>, 'visible'>;
export function useLocateModal() {
    const [options, setOptions] = useState<ShowLocateModalProps>()
    const show = useCallback((p: ShowLocateModalProps) => { setOptions(p) }, []);
    const hide = useCallback(() => { setOptions(undefined) }, []);

    return {
        options,
        show, hide,
    }
}
