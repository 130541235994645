import { ApiCall, ParameterTypes } from '@lcms/api-definition';
import { Label } from '../../types/label';
import { getLabels, saveLabel as saveLabelRoute, removeLabel as removeLabelRoute } from '../api-endpoint-definition.json';
import { Success } from './success.model';

export interface LabelsResponse {
    labels: Label[]
}

export const labels = new ApiCall<LabelsResponse>().get('labels', {
    route: getLabels
});

export type SaveLabelResponse = { success: false } | { success: true, id: string }
export const saveLabel = new ApiCall<SaveLabelResponse>().post('saveLabel', {
    route: saveLabelRoute,
    params: {
        id: {
            type: ParameterTypes.String,
            optional: true
        },
        title: {
            type: ParameterTypes.String,
        },
        color: {
            type: ParameterTypes.String,
            optional: true
        }
    }
});

export type RemoveLabelResponse = Success
export const removeLabel = new ApiCall<RemoveLabelResponse>().post('removeLabel', {
    route: removeLabelRoute,
    params: {
        id: {
            type: ParameterTypes.String
        }
    }
});