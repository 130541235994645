import { ApiCall } from '@lcms/api-definition';
import { DetailedFolderInfo } from '../../../types/detailed-folder-info';
import { FileInfo } from '../../../types/file-info';
import { recentFiles } from '../../api-endpoint-definition.json';

export interface RecentFileResponse {
    recent: DetailedFolderInfo<never, FileInfo>
}

export const recentEndpoint = new ApiCall<RecentFileResponse>().get('recent', {
    route: recentFiles
});