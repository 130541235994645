import { ApiCall, ParameterTypes, PostPayload } from '@lcms/api-definition';
import { trashFolder } from '../../api-endpoint-definition.json';

export type TrashFolderPayload = PostPayload<typeof trashFolderEndpoint>

export interface TrashResponse {
    success: boolean;
}

export const trashFolderEndpoint = new ApiCall<TrashResponse>().post('trashFolder', {
    route: trashFolder,
    params: {
        id: {
            type: ParameterTypes.String
        }
    }
});