import { SaveFilePayload } from "../../../api/endpoints/files/save-file";
import { FileUpload } from "../../../contexts/admin-panel/use-bulk-upload";
import { BulkUploadStatus } from "./bulk-upload-status";
import { UploadFile } from "./upload-file";


export function BulkUploadContent(props: { files: FileUpload[], skip: (file: File) => void; upload: (file: File, meta: SaveFilePayload) => void; parentId?: string; }) {
    const { files } = props;

    return (
        <>
            <BulkUploadStatus files={files} />
            <table className="table">
                <thead>
                    <tr>
                        <th style={{ width: '25%' }} scope="col">Title</th>
                        <th style={{ width: '35%' }} scope="col">Description</th>
                        <th style={{ width: '25%' }} scope="col">Filename</th>
                        <th style={{ width: 1 }} scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {files.map(file => <UploadFile key={file.file.name} model={file} skip={props.skip} upload={props.upload} parentId={props.parentId} />)}
                </tbody>
            </table>

        </>
    );
}