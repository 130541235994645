import { useCallback } from "react";
import { useToken } from "./use-token";

export function useAuthenticatedApiCall<T>(func: () => Promise<T> | T) {
    const getToken = useToken();

    const execute = useCallback(async () => {
        if (!getToken) return null;

        const hasValidToken = await getToken();
        if (hasValidToken) {
            return await func();
        }
        return null;

    }, [func, getToken])

    return getToken ? execute : null;
}
