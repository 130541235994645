import React from "react";
import { Modal } from "../../modal/modal";

export interface ConfirmationModalProps {
    visible: boolean;
    header?: string;
    prompt?: React.ReactNode;
    lg?: boolean;
    acceptText?: string;
    rejectText?: string;
    onAccept: () => void;
    onReject: () => void;
    acceptIsDangerous?: boolean;
}

export function ConfirmationModal(props: ConfirmationModalProps) {
    return (
        <Modal
            visible={props.visible}
            header={props.header || 'Are you sure?'}
            onHide={props.onReject}
            Footer={<div className="d-flex justify-content-between w-100">
                <button type="button" className={`btn btn-sm btn-outline-dark border-0 me-3`} aria-label="Cancel" onClick={props.onReject}>{props.rejectText || 'No'}</button>
                <button type="button" className={`btn me-2 ${props.acceptIsDangerous ? 'btn-danger' : 'btn-primary'}`} aria-label="Submit" onClick={props.onAccept}>{props.acceptText || 'Yes'}</button>

            </div>}
        >
            {props.prompt}
        </Modal>
    );
}

