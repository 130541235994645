import { useCallback, useEffect, useMemo, useState } from "react";
import { BreadCrumbs } from "../../../services/bread-crumbs";
import { Dots } from "../../loading/spinner";
import { Error } from "../../error/error";
import { Modal } from "../../modal/modal";
import { useMoverCopier } from "./use-mover-copier";
import { HelpScout } from "../../../services/help-scout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { api } from "../../../api/api";
import { useDataSync } from "../../../hooks/use-data-sync";
import { DirtyRecord } from "../../../contexts/data-sync/data-sync";
import { ConfirmationModalProps } from "../confirmation-modal/confirmation-modal";
import { InlineConfirmationModal } from "../confirmation-modal/inline-confirmation-modal";

export function MoveCopyDialogue() {
    const { visible, invalidFolders, toMove, destination, validating, hide, source } = useMoverCopier();
    const destinationPath = useMemo(() => new BreadCrumbs(destination?.breadCrumbs).toPath(), [destination?.breadCrumbs])
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [working, setWorking] = useState(false);
    const [confirmProps, setConfirmProps] = useState<ConfirmationModalProps>();

    const setDirty = useDataSync();



    const copy = useCallback(() => {
        if (!destination || !source || !toMove) return;

        api.post.copy({
            destinationId: destination.id,
            sourceId: source.id,
            fileIds: toMove.filter(i => i.selectedType === 'File').map(i => i.id),
            folderIds: toMove.filter(i => i.selectedType === 'Folder').map(i => i.id)
        }).then((result) => {
            if (result?.success) {
                setSuccess(true);
                setDirty([{
                    type: 'Folder',
                    id: destination.id
                }, {
                    type: 'Folder',
                    id: source.id
                }, ...toMove.map(item => (item.selectedType === 'Folder' ? { type: 'Folder', id: item.id } : { type: 'File', id: item.id }) as DirtyRecord)])
            }
        })
    }, [destination, setDirty, source, toMove])

    const move = useCallback(() => {
        if (!destination || !source || !toMove || invalidFolders?.length) return;

        api.post.move({
            destinationId: destination.id,
            sourceId: source.id,
            fileIds: toMove.filter(i => i.selectedType === 'File').map(i => i.id),
            folderIds: toMove.filter(i => i.selectedType === 'Folder').map(i => i.id)
        }).then((result) => {
            if (result?.success) {
                setSuccess(true);
                setDirty([{
                    type: 'Folder',
                    id: destination.id
                }, {
                    type: 'Folder',
                    id: source.id
                }, ...toMove.map(item => (item.selectedType === 'Folder' ? { type: 'Folder', id: item.id } : { type: 'File', id: item.id }) as DirtyRecord)])

            }
        })
    }, [destination, invalidFolders?.length, setDirty, source, toMove])

    const promptCopy = useCallback(() => {
        setConfirmProps({
            lg: true,
            header: 'Are you sure?',
            prompt: 'Do you want to copy these items?',
            acceptText: 'Yes, copy them',
            rejectText: 'Cancel',
            onAccept: () => { copy(); setConfirmProps(undefined) },
            onReject: () => {
                setConfirmProps(undefined)
            },
            visible: true
        })
    }, [copy])

    const promptMove = useCallback(() => {
        setConfirmProps({
            lg: true,
            header: 'Are you sure?',
            prompt: 'Do you want to move these items?',
            acceptText: 'Yes, move them',
            rejectText: 'Cancel',
            onAccept: () => { move(); setConfirmProps(undefined) },
            onReject: () => {
                setConfirmProps(undefined)
            },
            visible: true
        })
    }, [move])

    useEffect(() => {
        if (visible) {
            setWorking(false);
            setSuccess(false);
            setError(false);
            HelpScout.hide('move-copy-modal');
        } else {
            HelpScout.show('move-copy-modal');
        }
    }, [visible])

    return (
        <Modal
            onHide={hide}
            visible={visible}
            hideContent={success || error}
            showHeaderClose={!success || !error}
            Footer={success || error ?
                <div className={"w-100 d-flex align-items-center " + (success ? 'justify-content-between' : 'justify-content-end')}  >
                    {success ? <>
                        <div className="d-flex justify-content-between align-items-center me-5">
                            <span className="display-6 me-3">
                                <FontAwesomeIcon className="text-success" icon={['fad', 'check']} />
                            </span>
                            <small className="text-success text-uppercase">
                                <b>Action Completed Successfully</b>
                            </small>
                        </div>
                        <button type="button" className="btn btn-success pulse-success" onClick={hide}>Done</button>
                    </> :
                        <>
                            {error && <>
                                <small className="text-danger"><Error /></small>
                                <button className="ms-4 btn btn-dark border-0" onClick={hide}>Close</button>
                            </>}
                        </>}
                </div> :
                <div className="d-flex justify-content-between w-100">
                    <div>
                        <button type="button" className="btn btn-outline-dark border-0" disabled={working} onClick={hide}>Cancel</button>
                    </div>
                    <div>
                        <button type="button" className="btn btn-primary me-2" onClick={promptMove} disabled={working || validating || !source || !destination || (!!invalidFolders?.length) || (source.id === destination.id)} > <FontAwesomeIcon icon={['fad', 'folder-tree']} className="me-2" />Move</button>
                        <button type="button" className="btn btn-primary" onClick={promptCopy} disabled={working || !source || !destination || (source.id === destination.id)}><FontAwesomeIcon icon={['fad', 'clone']} className="me-2" />Copy</button>
                    </div>
                </div>}
            header="Move or Copy"
        >
            {working ?
                <div>
                    <Dots />
                </div>
                : validating ? <>Validing move <Dots /></> :
                    !destination || !toMove ? null :
                        <div>
                            {!source ? <>
                                The folder you are in does not allow for moving or copying files.
                            </> : source?.id === destination.id ?
                                <div className="border-start border-danger border-2 ps-3"><b>You can not move or copy files to the same folder they are already in</b></div>
                                : invalidFolders?.length ? <>
                                    <div className="border-start border-danger border-2 ps-3"><b>The following folders can not be moved into:</b>
                                        <br />
                                        <dl className="ip-content">
                                            <dt>{destinationPath}</dt>
                                            {invalidFolders.map(folder => <dd key={folder.id}>{folder.name}</dd>)}
                                        </dl>
                                    </div>
                                </> :
                                    <div>
                                        <b>Would you like to move or copy?</b>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="badge bg-white p-2 rounded-2 text-primary w-25">{toMove?.length} {toMove?.length > 1 ? 'Items' : 'Item'}</div><div className="badge bg-secondary p-2 rounded-2 text-white"><FontAwesomeIcon icon={['far', 'long-arrow-alt-right']} /></div>  <div className="d-badge bg-light p-2 rounded-2 text-dark flex-grow-1">{destinationPath}</div>
                                        </div>
                                    </div>
                            }
                        </div>

            }
            {confirmProps && <InlineConfirmationModal {...confirmProps} />}
        </Modal>
    );
}