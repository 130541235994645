import { PublishState } from "../types/publish-state";

export function getPublishState(start?: Date, end?: Date): PublishState {
    const newDate = new Date();
    const now = Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 0, 0, 0, 0);
    if (!start) {
        return 'Unpublished';
    }
    if (start.valueOf() > now) {
        return 'PendingPublish';
    }

    if (!end) {
        return 'Published';
    }

    if (end.valueOf() < now) {
        return 'Unpublished';
    }

    return 'PendingUnpublish';
}