import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faBooks,
	faCalendar,
	faFileWord,
	faFileVideo,
	faFilePowerpoint,
	faFilePdf,
	faFileImage,
	faFileExcel,
	faWaveform,
	faFileArchive,
	faFileAlt,
	faCaretRight,
	faCaretDown,
	faTh,
	faPencil,
	faTrash,
	faFolder,
	faCheck,
	faTimes,
	faChartLine,
	faAlignJustify,
	faExclamationTriangle,
	faStars,
	faClock,
	faUser,
	faDownload,
	faEye,
	faFileDownload,
	faFolderDownload,
	faThList,
	faCircle,
	faStar,
	faAsterisk,
	faFileUpload,
	faFolderUpload,
	faFolderTimes,
	faFile,
	faFilmAlt,
	faTrashRestore,
	faUndo,
	faFileAudio,
	faLongArrowLeft,
	faArrowToLeft,
} from "@fortawesome/pro-solid-svg-icons";

library.add(
	faArrowToLeft,
	faAsterisk,
	faStar,
	faBooks,
	faCalendar,
	faFileWord,
	faFileVideo,
	faFilePowerpoint,
	faFilePdf,
	faFileImage,
	faFileExcel,
	faWaveform,
	faFileArchive,
	faFileAlt,
	faChartLine,
	faCaretRight,
	faCaretDown,
	faTh,
	faPencil,
	faTrash,
	faFolder,
	faCheck,
	faTimes,
	faAlignJustify,
	faExclamationTriangle,
	faStars,
	faClock,
	faUser,
	faDownload,
	faEye,
	faFileDownload,
	faFolderDownload,
	faTh,
	faThList,
	faCircle,
	faFileUpload,
	faFolderUpload,
	faFolderTimes,
	faFile,
	faFilmAlt,
	faTrashRestore,
	faUndo,
	faFileAudio,
	faLongArrowLeft
);
