import { memo } from "react";

export interface TooltipMessageConfiguration {
    placement?: 'top' | 'bottom' | 'left' | 'right';
    content: React.ReactNode;
    center?: boolean;
}
export interface TooltipMessageProps extends TooltipMessageConfiguration {
    visible: boolean;
}

export const TooltipMessage = memo((props: TooltipMessageProps) => {
    return <>
        {props.visible && (
            <div className={`tooltip-tip ${props.placement || "top"} show ${props.center ? 'center' : ''}`}>
                <div className="tooltip-inside">{props.content}</div>
            </div>
        )}
    </>;
});
