import { useMemo, useState } from "react";


export function useTooltip() {
    const [active, setActive] = useState(false);

    const { hideTip, showTip } = useMemo(() => {

        const showTip = () => {
            setActive(true);
        };

        const hideTip = () => {
            setActive(false);
        };

        return {
            showTip,
            hideTip
        };
    }, []);

    return {
        isVisible: active,
        hide: hideTip,
        show: showTip
    };
}
