import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSwitch } from '../../../../../hooks/use-switch';
import { dateToString } from '../../../../../services/date-conversion';
import { today } from '../../../../../types/dates';
import { DatePicker } from '../../../../form/date-picker/date-picker';

interface DateFieldProps {
    value?: Date;
    min: Date;
    max: Date;
    onChange: (date?: Date) => void;
    clearable?: boolean;
    placeAbove?: boolean;
    placeLeft?: boolean;
}

export function DateField(props: DateFieldProps) {
    const [isEditting, startEditting, stopEditting] = useSwitch(false);

    return (<div className="position-relative">
        <button className="btn btn-outline-primary" onClick={() => { if (!props.value) { props.onChange(today) } startEditting() }}>
            <span className="me-3"><FontAwesomeIcon icon={['fas', 'calendar']} /></span>{dateToString(props.value) || 'No Date'}
        </button>
        {isEditting &&
            <DatePicker currentDate={props.value || today} minDate={props.min} maxDate={props.max} onChange={props.onChange} onDone={stopEditting}
                onClear={props.clearable ? () => { props.onChange(undefined); stopEditting(); } : undefined}
                alignVertical={props.placeAbove ? 'above' : 'below'}
                alignHorizontal={props.placeLeft ? 'left' : 'right'}
                noDone
            />}
    </div>);
}
