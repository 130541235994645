import { ValidatedInputProps } from "../input-props";
import { useValidatedFormValue } from "../../../../hooks/forms/use-validated-form-value";

function validate(value: string) {
    const errors: string[] = [];
    if (value.length > 225) {
        errors.push('Note cannot exceed 225 characters in length')
    }

    return errors;
}

export function NoteInput(props: ValidatedInputProps<'note'>) {

    const {
        errors, invalid, onInputChange, value
    } = useValidatedFormValue('note', props, validate);

    return (
        <div className="mb-3">
            <label htmlFor="itemNote" className="form-label">Notes:</label>
            <textarea className={`form-control${invalid ? ' is-invalid' : ''}`} id="itemNote" cols={50} rows={2}
                placeholder="Administrative notes..."
                value={value} onChange={onInputChange}
            />
            {errors.map((message, i) => <small key={i} className="form-error text-danger">{message}</small>)}
        </div>
    )
}