import { lazy, Suspense } from 'react';
import { LoadingPage } from '../../components/loading/loading-page';
import { RecentProps } from '../../pages/recent/recent';

const Page = lazy(() => import('../../pages/recent/recent.mobile'))

export default function Recent(props: RecentProps) {
    return (
        <Suspense fallback={<LoadingPage />}>
            <Page {...props} />
        </Suspense>
    )
}
