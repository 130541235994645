import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo, useState } from 'react';
import { useLabels } from '../../../hooks/api/use-labels';
import { Tooltip } from '../../tooltip/tooltip';

export interface LabelPickerProps {
    openSearchTools?: () => void;
    toggle: (label: string) => void;
    selected: string[];
}

export function LabelList(props: LabelPickerProps) {
    const [filter, setFilter] = useState('');
    const [list, setList] = useState<{ id: string; name: string }[]>([]);
    const labels = useLabels();


    useEffect(() => {
        if (!labels.loading && !labels.hadError) {
            setList(labels.resource?.labels?.map(t => {
                return {
                    id: t.id,
                    name: t.title
                }
            }) || [])
        }

    }, [labels])

    const filteredList = useMemo(() => {
        if (!filter) {
            return list;
        }

        const term = filter?.toLowerCase();
        return list.filter(x => x.name?.toLowerCase().indexOf(term) >= 0)
    }, [filter, list])

    return (
        <>
            <div className="list-group">
                {list.length > 5 && <div className="p-2 rounded-2 bg-light sticky-top">
                    <div className="input-group">
                        <label htmlFor="filter" className="form-label w-100">Type to filter the label list:</label>
                        <input className="form-control" id="filter"
                            name="filter"
                            type="text"
                            value={filter}
                            onChange={e => setFilter(e.target.value)}
                        />

                        {filter && <div className="input-group-append" onClick={e => setFilter('')}>
                            <Tooltip content="Clear filter" placement="left"><span className="input-group-text btn btn-sm btn-primary py-2 px-3">
                                <FontAwesomeIcon icon={['fal', 'times']} />
                            </span></Tooltip>
                        </div>}

                    </div>
                </div>}
                {filteredList.map(l => (
                    <button key={l.id} onClick={() => props.toggle(l.name)} className={'list-group-item d-flex justify-content-between align-items-center border-0 px-3 py-1 ' + (props.selected.includes(l.name) ? 'active' : '')}>
                        <div className="d-flex align-items-center text-start py-2">
                            {l.name}
                        </div>
                        <div>
                            {props.selected.includes(l.name) && <FontAwesomeIcon icon={['fas', 'check']} />}
                        </div>
                    </button>
                ))}
                {!filteredList.length && <div className="p-3"><small><b>No labels match</b></small></div>}
            </div>
        </>

    );
}
