import { compare } from "@lcms/helpers";
import { BreadCrumb } from "../types/bread-crumb";
import { rootBreadCrumb } from "../types/root-directory";

export class BreadCrumbs {
    private breadcrumbs: BreadCrumb[]

    constructor(breadcrumbs?: BreadCrumb[], noSort?: boolean) {
        const allBreadcrumbs = breadcrumbs?.find(b => !b.id) ? [...breadcrumbs] : [rootBreadCrumb].concat([...(breadcrumbs || [])]);
        this.breadcrumbs = noSort ? allBreadcrumbs : allBreadcrumbs.sort(compare(x => x.order));
    }

    add(crumb: Omit<BreadCrumb, 'order'>) {
        return new BreadCrumbs(this.breadcrumbs.concat({
            name: crumb.name,
            id: crumb.id,
            order: this.breadcrumbs.length,
            isPublished: crumb.isPublished,
            isTrashed: crumb.isTrashed
        }), true);
    }

    toList() {
        return this.breadcrumbs;
    }

    toPath() {
        return this.breadcrumbs.map(c => c.name).join('/');
    }
}