
export const routes = {
    index: '/',
    dashboard: '/dashboard',
    recent: '/recent',
    starred: '/starred',
    trending: '/trending',
    searchResults: '/search',
    admin: {
        labels: '/admin/labels',
        untracked: '/admin/untracked',
        trash: '/admin/trash',
        unpublished: '/admin/unpublished',
        pendingPublish: '/admin/pending-publish',
        pendingUnpublish: '/admin/pending-unpublish',
    },
    login: '/login',
    file: {
        preview: '/file/preview'
    },
    folder: '/folder'
}