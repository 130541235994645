import { ApiCall, ParameterTypes, PostPayload } from '@lcms/api-definition';
import { saveFolder } from '../../api-endpoint-definition.json';

export type SaveFolderPayload = PostPayload<typeof saveFolderEndpoint>

export interface SaveResponse {
    id?: string;
}

export const saveFolderEndpoint = new ApiCall<SaveResponse>().post('saveFolder', {
    route: saveFolder,
    params: {
        id: {
            type: ParameterTypes.String,
            optional: true
        },
        parentId: {
            type: ParameterTypes.String,
            optional: true
        },
        title: {
            type: ParameterTypes.String
        },
        description: {
            type: ParameterTypes.String,
            optional: true
        },
        notes: {
            type: ParameterTypes.String,
            optional: true
        },
        isStarred: {
            type: ParameterTypes.Boolean
        },
        links: {
            type: [{
                slug: ParameterTypes.String,
                isPrimary: ParameterTypes.Boolean
            }]
        },
        labels: {
            type: ParameterTypes.StringArray
        },
        publishStartDate: {
            type: ParameterTypes.String,
            optional: true
        },
        publishEndDate: {
            type: ParameterTypes.String,
            optional: true
        }
    }
});