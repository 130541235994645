import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { compare } from "@lcms/helpers";
import React, { useCallback, useMemo, useState } from "react";
import { useLabels } from "../../../../hooks/api/use-labels";
import { useOnClickOutside } from "../../../../hooks/use-on-click-outside";

interface LabelInputProps {
    labels: string[];
    onAdd: (value: string) => void;
    onRemove: (value: string) => void;
}

export function LabelInput(props: LabelInputProps) {
    const { onAdd, onRemove } = props;
    const [text, setText] = useState('');

    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setText(e.target.value.substr(0, 100));
    }, [])

    const onBlur = useCallback(() => {
        setText('');
    }, [])

    const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' || e.key === ',') {
            setText(t => {
                onAdd(t);
                return ''
            })
            e.preventDefault();
        }
        if (e.key === 'Tab') {
            onBlur();
        }
    }, [onAdd, onBlur])



    const clickOutsideRef = useOnClickOutside<HTMLDivElement>(onBlur);


    return (<>
        <div ref={clickOutsideRef} className="w-100 border rounded-2 px-2 pb-2">
            {props.labels.map(l => <LabelRecord key={l} label={l} onRemove={onRemove} />)}
            <div className="position-relative">
                <input className="form-control blended-input cursor-pointer" type='text' id="label-input"
                    value={text} onChange={onChange} onKeyDown={onKeyDown}
                />
                <LabelAutoComplete current={props.labels} value={text} onSelect={(x) => { onAdd(x); onBlur(); }} />
            </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
            <small>Separate with commas or the 'Enter' key.</small>
            <small className="fw-bold" style={{ fontSize: '12px' }}>100 characters or less</small>
        </div>

    </>);
}

export function LabelRecord(props: { label: string, onRemove: (value: string) => void; }) {
    const { label, onRemove } = props;

    const onClick = useCallback(() => {
        onRemove(label)
    }, [onRemove, label])

    return (
        <div className="badge bg-light border text-dark py-1 px-2 m-2 text-wrap">
            <div className="d-flex align-items-center">
                <span className="fw-normal">{props.label}</span> <button className="ms-2 btn btn-link p-0" onClick={onClick}><FontAwesomeIcon icon={['fal', 'times']} color="#000" /></button>
            </div>

        </div>

    );
}

function LabelAutoComplete(props: { current: string[], value: string; onSelect: (value: string) => void }) {
    const { current: additionalLabels, onSelect, value } = props;

    const labels = useLabels();
    const matching = useMemo(() => {
        const existing = !labels.loading && !labels.hadError && labels.resource ? labels.resource.labels : [];
        return existing.map(e => e.title).filter(x => !additionalLabels.includes(x)).filter(x => !value || x.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) >= 0)
            .sort(compare(x => x)).slice(0, 5)
    }, [labels, additionalLabels, value])

    return (
        <div className={value ? '' : 'd-none'}>
            <div className="position-absolute border border-primary shadow bg-white" style={{ zIndex: 3, left: 0, right: 0 }}>
                {matching.map(x =>
                    <div key={x} className="auto-complete-item px-2 p-1 cursor-pointer" onClick={() => onSelect(x)}>
                        {x}
                    </div>
                )}
            </div>

        </div>
    )
}