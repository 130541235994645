export const Spinner = (props: { delay?: string; size?: string; margin?: string; }) => {
    return (
        <div className="spinner-grow" role="status" style={{
            marginRight: props.margin || '0.5rem',
            marginLeft: props.margin || '0.5rem',
            width: props.size || '1rem',
            height: props.size || '1rem',
            animationDelay: props.delay
        }} ></div>
    );
}

export function Dots(props: { size?: string, spacing?: string; }) {
    return <div>
        <Spinner delay="-.2s" size={props.size} margin={props.spacing} />
        <Spinner delay="-.1s" size={props.size} margin={props.spacing} />
        <Spinner size={props.size} margin={props.spacing} />
    </div>
}