import { ApiCall, ParameterTypes } from '@lcms/api-definition';
import { promote } from '../../api-endpoint-definition.json';

export interface PromoteResponse {
    success: boolean;
}

export const promoteEndpoint = new ApiCall<PromoteResponse>().post('promote', {
    route: promote,
    params: {
        folderIds: {
            type: ParameterTypes.StringArray
        },
        fileIds: {
            type: ParameterTypes.StringArray
        },
        isStarred: {
            type: ParameterTypes.Boolean
        }
    }
});