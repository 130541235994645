import { Logo } from '../logo/logo';

export function Header() {

    return (
        <div className={"d-flex justify-content-between align-items-center flex-wrap p-1 border-bottom shadow-sm mb-4 bg-white top-row "}>
            <div className="d-flex justify-content-between align-items-center top-left flex-wrap">
                <div className="logo">
                    <Logo />
                </div>
            </div>

            <div className="h-ctas d-flex justify-content-end flex-grow-1 align-items-center">
                <div>
                </div>
            </div>
        </div>
    );
}