import { ApiCall } from '@lcms/api-definition';
import { DetailedFolderInfo } from '../../../types/detailed-folder-info';
import { TrendingFile } from '../../../types/trending-file';
import { TrendingFolder } from '../../../types/trending-folder';
import { trendingFiles } from '../../api-endpoint-definition.json';

export interface TrendingFileResponse {
    trending: DetailedFolderInfo<TrendingFolder, TrendingFile>
}

export const trendingEndpoint = new ApiCall<TrendingFileResponse>().get('trending', {
    route: trendingFiles
});