import { ApiCall, ParameterTypes, PostPayload } from '@lcms/api-definition';
import { downloadItems } from '../api-endpoint-definition.json';

export type DownloadPayload = PostPayload<typeof downloadItemsEndpoint>;

export const downloadItemsEndpoint = new ApiCall<Response>().post('downloadItems', {
    route: downloadItems,
    nativeProcessing: true,
    params: {
        folder: {
            type: {
                id: ParameterTypes.String as string | undefined,
                folderIds: ParameterTypes.StringArray
            },
            optional: true
        },
        fileIds: {
            type: ParameterTypes.StringArray
        }
    },
    process: (response) => {
        response.blob().then(blob => {
            const filename = response.headers.get('content-disposition')?.split(';').map(s => s.trim().split('=')).find(t => t[0] === 'filename')?.[1]?.replace(/"/ig, '') || 'download.csv';
            const file = new File([blob], filename, {
                type: response.headers.get('content-type') || undefined
            })
            const url = window.URL.createObjectURL(file);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url)
            }, 0)
        });
        return response;
    }
});