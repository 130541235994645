import { useState, useEffect, useCallback } from 'react';
import { logError } from '../../services/log-error';

interface LoadingResource {
    loading: true;
}

interface Error {
    loading: false;
    hadError: true;
}

interface Complete<T> {
    loading: false;
    hadError: false;
    resource: T;
}

interface Reloadable {
    reload: () => void;
}

const loadingState: LoadingResource = {
    loading: true
}

const errorState: Error = {
    loading: false,
    hadError: true
}

export type ApiResource<T> = (LoadingResource | Error | Complete<T>) & Reloadable;

export function useApiResource<T>(apiCall: (() => Promise<T>) | undefined, onReload?: () => void) {
    const [resource, setResource] = useState<LoadingResource | Error | Complete<T>>(loadingState);

    const reload = useCallback(() => {
        if (!apiCall) return;

        setResource(loadingState);
        onReload?.();
        apiCall()
            .then(result => {
                setResource({
                    loading: false,
                    hadError: false,
                    resource: result
                });
            }, (error) => {
                logError(error)
                setResource(errorState);
            })

    }, [apiCall, onReload]);

    const [result, setResult] = useState<ApiResource<T>>({
        ...resource,
        reload: reload
    });


    useEffect(() => {
        reload()
    }, [reload]);

    useEffect(() => {
        setResult({
            ...resource,
            reload: reload
        })
    }, [resource, reload]);


    return result
}
