import { ApiCall } from '@lcms/api-definition';
import { DetailedFolderInfo } from '../../../types/detailed-folder-info';
import { notPublished } from '../../api-endpoint-definition.json';

export interface UnpublishedFilesResponse {
    unpublished: DetailedFolderInfo
}

export const unpublishedFiles = new ApiCall<UnpublishedFilesResponse>().get('unpublishedFiles', {
    route: notPublished
});