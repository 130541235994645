import React, { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { throwError } from "../throw-error";

type FolderId = { type: 'Folder', id: string | undefined };
type FileId = { type: 'File', id: string };
export type DirtyRecord = FolderId | FileId;
export type CleanRecord = DirtyRecord & { refreshed: string[] }

interface DataSyncContextType {
    markDirty: (params: DirtyRecord[]) => void;
    markClean: (params: DirtyRecord[], element: string) => void;
    dirtyRecords: CleanRecord[];
}

export const DataSyncContext = React.createContext<DataSyncContextType>({
    dirtyRecords: [],
    markClean: throwError,
    markDirty: throwError
})

export function DataSyncProvider(props: PropsWithChildren<{}>) {

    const [dirtyRecords, setDirtyRecords] = useState<CleanRecord[]>([]);
    const markDirty = useCallback((records: DirtyRecord[]) => {
        setDirtyRecords(prev =>
            prev.filter(p => records.find(n => n.type === p.type && n.id === p.id))
                .concat(records.map(r => {
                    return {
                        ...r,
                        refreshed: []
                    }
                }))
        )
    }, [])

    const markClean = useCallback((records: DirtyRecord[], element: string) => {
        setDirtyRecords(prev =>
            prev.map(p => {
                const isCleaned = records.find(r => r.type === p.type && r.id === p.id)
                if (isCleaned) {
                    return {
                        ...p,
                        refreshed: p.refreshed.filter(r => r !== element).concat(element)
                    }
                }
                return p
            })
        )
    }, [])

    const context: DataSyncContextType = useMemo(() => {
        return {
            dirtyRecords,
            markClean,
            markDirty
        }
    }, [dirtyRecords, markClean, markDirty])


    return (
        <DataSyncContext.Provider value={context}>
            {props.children}
        </DataSyncContext.Provider>
    );
}