import { ApiCall, ParameterTypes } from '@lcms/api-definition';
import { move } from '../../api-endpoint-definition.json';

export interface MoveResponse {
    success: boolean;
}

export const moveEndpoint = new ApiCall<MoveResponse>().post('move', {
    route: move,
    params: {
        destinationId: {
            type: ParameterTypes.String as string | undefined
        },
        sourceId: {
            type: ParameterTypes.String as string | undefined
        },
        folderIds: {
            type: ParameterTypes.StringArray
        },
        fileIds: {
            type: ParameterTypes.StringArray
        }
    }
});