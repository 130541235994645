import { useEffect, useState } from "react";

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

const breakpoints = [576, 768, 992, 1200, 1400];

function breakpoint(width: number): Breakpoint {
    if (width < breakpoints[0]) {
        return 'xs';
    }
    if (width < breakpoints[1]) {
        return 'sm';
    }
    if (width < breakpoints[2]) {
        return 'md';
    }
    if (width < breakpoints[3]) {
        return 'lg';
    }
    if (width < breakpoints[4]) {
        return 'xl';
    }
    return 'xxl'
}

export function usePageBreakpoints() {
    const [tier, setTier] = useState(breakpoint(window.innerWidth));

    useEffect(() => {
        const callback = () => {
            setTier(breakpoint(window.innerWidth))
        }
        window.addEventListener('resize', callback);
        return () => {
            window.removeEventListener('resize', callback);
        }
    }, [])

    return tier;
}