import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useValidatedFormValue } from "../../../../hooks/forms/use-validated-form-value";
import { ValidatedInputProps } from "../input-props";

function validate(title: string) {
    const errors: string[] = [];
    if (!title) {
        errors.push('Title is required')
    }
    if (title.length > 255) {
        errors.push('Title cannot exceed 255 characters in length')
    }

    return errors;
}

export function TitleInput(props: ValidatedInputProps<'title'>) {
    const {
        errors, invalid, onInputChange, value
    } = useValidatedFormValue('title', props, validate);

    return (
        <div className="mb-3">
            <label htmlFor="itemTitle" className={`form-label${invalid ? ' required' : ''}`}>Title{invalid && <FontAwesomeIcon icon={['fas', 'asterisk']} />}:</label>
            <input type="text" className={`form-control ${invalid ? 'is-invalid' : ''} ${value.length > 75 ? 'length-warning' : ''}`} id="itemTitle" placeholder="Please enter a title..."
                value={value} onChange={onInputChange}
            />
            {!errors.length && value.length > 75 && <div className="text-dark bg-light w-100 p-2 border-start border-2 border-dark shadow-sm"><small className="text-muted">Please keep titles short. Use the description field for more context.<br /></small></div>}
            {errors.map((message, i) => <small key={i} className="form-error text-danger">{message}<br /></small>)}
        </div>
    )
}