import { ApiCall, ParameterTypes, PostPayload } from '@lcms/api-definition';
import { trashFile } from '../../api-endpoint-definition.json';

export type TrashFilePayload = PostPayload<typeof trashFileEndpoint>

export interface TrashResponse {
    success: boolean;
}

export const trashFileEndpoint = new ApiCall<TrashResponse>().post('trashFile', {
    route: trashFile,
    params: {
        id: {
            type: ParameterTypes.String
        }
    }
});