import { ApiCall } from '@lcms/api-definition';
import { DetailedFolderInfo } from '../../../types/detailed-folder-info';
import { untracked } from '../../api-endpoint-definition.json';

export interface UntrackedFileResponse {
    untracked: DetailedFolderInfo
}

export const untrackedEndpoint = new ApiCall<UntrackedFileResponse>().get('untracked', {
    route: untracked
});