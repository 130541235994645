import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useState } from "react";
import { useSwitch } from "../../../../../hooks/use-switch";
import { FormLink } from "../../../../../types/link";
import { InlineConfirmationModal } from "../../../confirmation-modal/inline-confirmation-modal";
import { Tooltip } from "../../../../tooltip/tooltip";
import { FooterControl } from "../../admin-dialogue";
import { InputProps } from "../../input-props";
import { LinkEditForm } from "../link-edit-form";
import { LinkRecord } from "../link-record";

interface LinksAccordionProps extends InputProps<'links', FormLink[]> {
    controlFooter: (control?: FooterControl) => void;
    type: 'file' | 'folder'
}

export function LinksAccordian(props: LinksAccordionProps) {
    const { type, onChange, value: links, controlFooter } = props;

    const [linkFormIsVisible, showLinkForm, hideLinkForm] = useSwitch(false);
    const [link, setLink] = useState<FormLink>();

    const onLinkFormClose = useCallback(() => {
        hideLinkForm();
        controlFooter(undefined)
    }, [hideLinkForm, controlFooter])

    const onEditLink = useCallback((l: FormLink) => {
        setLink(l);
        showLinkForm();
        controlFooter({
            hideFooter: true
        })
    }, [showLinkForm, controlFooter])

    const updateLinks = useCallback((newLinks: FormLink[]) => {
        if (!newLinks.find(l => l.isPrimary)) {
            const systemLink = newLinks.find(l => l.isSystem);
            if (systemLink) {
                systemLink.isPrimary = true;
            }
        }
        onChange('links')(newLinks)
    }, [onChange])

    const saveLink = useCallback((l: FormLink) => {
        const newLinks = links.find(x => x.formKey === l.formKey) ?
            links.map(x => x === link ? l : (l.isPrimary ? { ...x, isPrimary: false } : x)) :
            links.map(x => l.isPrimary ? { ...x, isPrimary: false } : x).concat(l);
        updateLinks(newLinks);
    }, [links, updateLinks, link])

    const onAddLink = useCallback(() => {
        onEditLink({
            id: '',
            isPrimary: true,
            isSystem: false,
            slug: '',
            formKey: new Date().toUTCString()
        })
    }, [onEditLink])

    const [deleteLink, setDeleteLink] = useState<FormLink>()
    const removeLink = useCallback((l: FormLink) => {
        setDeleteLink(l);
    }, [])


    const onRemoveLinkAccept = useCallback(() => {
        const newLinks = links.filter(x => x !== deleteLink);
        updateLinks(newLinks);
        setDeleteLink(undefined)
    }, [links, updateLinks, deleteLink])

    const onRemoveLinkReject = useCallback(() => {
        setDeleteLink(undefined)
    }, [])

    return (<>
        <div className="accordion-item">
            <h2 className="accordion-header" id="linkSection">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#linkCollapse" aria-expanded="false" aria-controls="linkCollapse">
                    <div className="w-100 d-flex justify-content-between align-items-center pe-4">
                        <div>Links</div>
                        {links.filter(s => !s.isSystem).length >= 1 ? <small className="p-2 bg-white rounded text-dark">{links.filter(s => !s.isSystem).length} Custom</small> :
                            <small className="p-2 bg-white rounded text-muted">No Custom Links</small>}
                    </div>
                </button>
            </h2>
            <div id="linkCollapse" className="accordion-collapse collapse" aria-labelledby="linkSection" data-bs-parent="#addEditFormAccordion">
                <div className="accordion-body">
                    <div className="w-100 pt-2 ps-2">
                        <div className="d-flex justify-content-between align-items-center">
                            <label className="form-label p-0 m-0">Links</label>
                            <Tooltip content="Add link" placement="left">
                                <button className="btn btn-sm btn-primary"
                                    onClick={onAddLink}
                                >
                                    <span className="me-2"><FontAwesomeIcon icon={['fal', 'plus']} /></span>Add Link
                                </button>
                            </Tooltip>

                        </div>
                        <div className="flex-grow-1 mt-1">
                            <dl>
                                {links.map(s => <LinkRecord key={s.id} link={s} onDelete={removeLink} onEdit={onEditLink} />)}
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <InlineConfirmationModal
            onAccept={onRemoveLinkAccept}
            onReject={onRemoveLinkReject}
            visible={!!deleteLink}
            acceptIsDangerous
            acceptText="Delete"
            rejectText="Cancel"
            header="Are you sure you want to remove this link?"
            prompt="Removing a link will lead to 404 errors if anyone tries to use the link after it is deleted."
        />
        <LinkEditForm
            visible={linkFormIsVisible} onHide={onLinkFormClose}
            link={link} onSave={saveLink}
            type={type}
            existingSlugs={links.filter(l => l !== link).map(l => l.slug)}
        />
    </>);
}