const bytesPerSize = 1024;
const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

function getSize(bytes: number) {
    return Math.floor(Math.log(bytes) / Math.log(bytesPerSize));
}

function getFactor(size: number) {
    return Math.pow(bytesPerSize, size);
}

export function formatFileSize(bytes: number, decimals = 2): string {
    const fixedLength = decimals < 0 ? 0 : decimals;

    if (bytes === 0) return `${bytes.toFixed(fixedLength)} ${sizes[0]}`;

    const size = getSize(bytes);
    const factor = getFactor(size);

    return (bytes / factor).toFixed(fixedLength) + ' ' + sizes[size];
}

export function fileSizeToBytes(formattedSize: string) {
    const parts = formattedSize.split(' ');
    if (parts.length !== 2) return;

    const value = parseFloat(parts[0]);
    if (isNaN(value)) return;

    const label = parts[1];

    const size = sizes.map(x => x.toLowerCase()).indexOf(label.toLowerCase());
    if (size < 0) return;

    const factor = getFactor(size);
    return value * factor;
}
