import { notNull } from '@lcms/helpers';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Modal as BootstrapModal } from 'bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from "@fortawesome/fontawesome-svg-core";
export interface ModalProps {
    header?: string;
    headerIcon?: IconName;
    showHeaderClose?: boolean;
    onHide: () => void;
    visible: boolean;
    scrollable?: boolean;
    centerVertically?: boolean;
    noPadding?: boolean;
    sizeXL?: boolean;
    sizeLG?: boolean;
    sizeM?: boolean;
    sizeSM?: boolean;
    full?: boolean;
    fullSm?: boolean;
    fullMd?: boolean;
    fullLg?: boolean;
    fullXl?: boolean;
    hideContent?: boolean;
    Footer?: React.ReactNode;
    zIndex?: number;
}

export function Modal(props: PropsWithChildren<ModalProps>) {

    const [modal, setModal] = useState<BootstrapModal | null>(null);
    const modalRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (modalRef.current) {
            const modal = new BootstrapModal(modalRef.current);
            setModal(modal)
            return () => { modal.dispose(); }
        }
    }, [modalRef])

    useEffect(() => {
        if (props.visible) {
            modal?.show();
        } else {
            modal?.hide();
        }
    }, [props.visible, modal])


    const modalClasses = ['modal-dialog',
        props.scrollable ? 'modal-dialog-scrollable' : null,
        props.centerVertically ? 'modal-dialog-centered' : null,
        props.noPadding ? 'modal-no-padding' : null,
        props.sizeXL ? 'modal-xl' : null,
        props.sizeM ? 'modal-m' : null,
        props.sizeLG ? 'modal-lg' : null,
        props.sizeSM ? 'modal-sm' : null,
        props.fullSm ? 'modal-fullscreen-sm-down' : null,
        props.fullMd ? 'modal-fullscreen-md-down' : null,
        props.fullLg ? 'modal-fullscreen-lg-down' : null,
        props.fullXl ? 'modal-fullscreen-xl-down' : null,
        props.hideContent ? 'modal-hide-content' : null
    ].filter(notNull).join(' ')


    return (
        <div ref={modalRef} className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" style={{ zIndex: props.zIndex }}>
            <div className={modalClasses}>
                <div className="modal-content fixed-parent">
                    {(props.header || props.showHeaderClose || props.headerIcon) && <div className="modal-header">
                        {props.headerIcon && props.header ? <div className="d-flex justify-content-start align-items-center">
                            {props.headerIcon && <FontAwesomeIcon icon={['fad', props.headerIcon]} className="me-2 fa-2x" color="#B3B3B3" />}
                            {props.header && <h5 className="modal-title">{props.header}</h5>}
                        </div> : <>
                            {props.headerIcon && <FontAwesomeIcon icon={['fad', props.headerIcon]} className="me-2 fa-2x" color="#B3B3B3" />}
                            {props.header && <h5 className="modal-title">{props.header}</h5>}</>}

                        {props.showHeaderClose === true || typeof (props.showHeaderClose) === 'undefined' ?
                            <button type="button" className="btn-close" aria-label="Close" onClick={props.onHide}></button>
                            : null}
                    </div>}
                    {props.children &&
                        <div className="modal-body">
                            {props.children}
                        </div>
                    }
                    {props.Footer && <div className="modal-footer">
                        {props.Footer}
                    </div>}
                </div>
            </div>
        </div>
    );
}