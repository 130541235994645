import { ApiCall, ParameterTypes } from '@lcms/api-definition';
import { Label } from '../../../types/label';
import { labelsForItems } from '../../api-endpoint-definition.json';

export interface LabelsResponse {
    labels: Label[]
}


export const itemsLabelsEndpoint = new ApiCall<LabelsResponse>().post('itemLabels', {
    route: labelsForItems,
    params: {
        folderIds: {
            type: ParameterTypes.StringArray
        },
        fileIds: {
            type: ParameterTypes.StringArray
        }
    }
});