import { useCallback, useEffect, useMemo, useState } from "react";
import { api } from "../../../api/api";
import { post } from "../../../api/http/post";
import { useAdminForm } from "../../../hooks/ui/use-admin-form";
import { useAuthenticatedApiCall } from "../../../hooks/auth/use-authenticated-api-call";
import { useSwitch } from "../../../hooks/use-switch";
import { dateToString } from "../../../services/date-conversion";
import { useForm } from "../../form/use-form";
import { Modal } from "../../modal/modal";
import { FolderForm } from "./folder-form/folder-form";
import { Error } from '../../error/error';
import { ErrorText } from "../../error-text/error-text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileForm } from "./file-form/file-form";
import { saveFile } from '../../../api/api-endpoint-definition.json'
import { SaveFilePayload, SaveResponse } from "../../../api/endpoints/files/save-file";
import { HelpScout } from "../../../services/help-scout";
import { ConfirmationModalProps } from "../confirmation-modal/confirmation-modal";
import { InlineConfirmationModal } from "../confirmation-modal/inline-confirmation-modal";
import { mapFileToForm } from "./map-file-to-form";
import { mapFolderToForm } from "./map-folder-to-form";
import { useDataSync } from "../../../hooks/use-data-sync";
import { DirtyRecord } from "../../../contexts/data-sync/data-sync";

export interface FooterControl {
    hideFooter?: boolean;
}

export function AdminDialogue() {
    const { hide, visible, editing } = useAdminForm();
    const [footerControl, setFooterControl] = useState<FooterControl>();

    useLogFileAction(editing?.type === 'File' ? editing.model.id : undefined, visible);

    const [isDirty, markDirty, markPristine] = useSwitch(false);
    const [folderIsValid, setFolderValidity] = useState(false);
    const [fileIsValid, setFileValidity] = useState(false);
    const [saved, setSaved] = useState(false);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(false);

    const [confirmProps, setConfirmProps] = useState<ConfirmationModalProps>();

    const onHide = useCallback(() => {
        if (isDirty) {
            setFooterControl({
                hideFooter: true
            })
            setConfirmProps({
                onAccept: () => {
                    hide();
                    setConfirmProps(undefined);
                    setFooterControl(undefined)
                },
                onReject: () => {
                    setConfirmProps(undefined);
                    setFooterControl(undefined)
                },
                visible: true,
                acceptText: 'Lose Changes',
                rejectText: 'Return to form',
                header: 'You are about to lose changes',
                prompt: 'If you cancel now, your changes will not be saved.  Are you sure you want to do this?',
                acceptIsDangerous: true
            })
        } else {
            hide()
        }
    }, [hide, isDirty])

    useEffect(() => {
        markPristine();
        setSaved(false);
        setSaving(false);
        setError(false);
        setConfirmProps(undefined);
        setFooterControl(undefined)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editing])

    const isNew = useMemo(() => {
        return !editing?.model.id
    }, [editing])

    const folderForm = useForm(editing?.type === 'Folder' ? editing : undefined, mapFolderToForm, markDirty, setFolderValidity);
    const folderValues = folderForm.formValues;

    const fileForm = useForm(editing?.type === 'File' ? editing : undefined, mapFileToForm, markDirty, setFileValidity);
    const fileValues = fileForm.formValues;

    const valid = useMemo(() => !editing ? false : editing.type === 'File' ? fileIsValid : folderIsValid, [editing, folderIsValid, fileIsValid])

    const syncDirty = useDataSync()

    const save = useCallback(() => {
        setSaving(true);
        if (editing?.type === 'Folder') {
            const folder = editing.model;
            api.post.saveFolder({
                id: folder.id,
                isStarred: folderValues.isStarred,
                labels: folderValues.labels,
                links: folderValues.links.map(l => { return { isPrimary: l.isPrimary, slug: l.slug } }),
                title: folderValues.title,
                description: folderValues.description || undefined,
                notes: folderValues.note || undefined,
                parentId: folder.parentId,
                publishEndDate: dateToString(folderValues.publishEndDate),
                publishStartDate: dateToString(folderValues.publishStartDate)
            }).then(result => {
                if (result?.id) {
                    setSaved(true);
                    syncDirty([{
                        type: 'Folder',
                        id: result.id
                    }, {
                        type: 'Folder',
                        id: folder.parentId
                    }])
                } else {
                    setError(true);
                }
                setSaving(false);
            })
        } else if (editing?.type === 'File') {
            const file = editing.model;
            const payload: SaveFilePayload = {
                id: file.id,
                isStarred: fileValues.isStarred,
                labels: fileValues.labels,
                links: fileValues.links.map(l => { return { isPrimary: l.isPrimary, slug: l.slug } }),
                title: fileValues.title,
                description: fileValues.description,
                notes: fileValues.note || undefined,
                publishEndDate: dateToString(fileValues.publishEndDate),
                publishStartDate: dateToString(fileValues.publishStartDate),
                language: fileValues.language,
                parents: fileValues.location.map(l => l.id),
                rollback: fileValues.rollback
            }

            const form = new FormData();
            if (fileValues.file) {
                form.append('file', fileValues.file)
            }
            form.append('metaData', JSON.stringify(payload))
            post()(saveFile, form, false, false)
                .then((result: SaveResponse) => {
                    if (result?.id) {
                        setSaved(true);
                        syncDirty([{
                            type: 'File',
                            id: result.id
                        }, ...(fileValues.location.map(l => {
                            return {
                                type: 'Folder',
                                id: l.id
                            } as DirtyRecord
                        }))])
                    } else {
                        setError(true);
                    }
                    setSaving(false);
                })
        }
    }, [editing?.type, editing?.model, folderValues.isStarred, folderValues.labels, folderValues.links, folderValues.title, folderValues.description, folderValues.note, folderValues.publishEndDate, folderValues.publishStartDate, syncDirty, fileValues.isStarred, fileValues.labels, fileValues.links, fileValues.title, fileValues.description, fileValues.note, fileValues.publishEndDate, fileValues.publishStartDate, fileValues.language, fileValues.location, fileValues.rollback, fileValues.file]);
    const iconType = editing?.type === 'File' ? 'file' : 'folder';

    useEffect(() => {
        if (visible) {
            HelpScout.hide('admin-panel');
        } else {
            HelpScout.show('admin-panel');
        }
    }, [visible])

    const cancel = useCallback(() => {
        hide();
        setConfirmProps(undefined);
        setFooterControl(undefined)
    }, [hide])

    return (<>

        <Modal
            sizeXL={!saved}
            scrollable
            onHide={onHide}
            hideContent={saved}
            visible={visible}
            header={editing?.model.title || (editing?.type === 'File' ? `Add new file` : `Add new folder to ${editing?.model.path}`)}
            headerIcon={iconType}
            showHeaderClose={!saved}
            Footer={
                footerControl?.hideFooter ? null :
                    <div className={"w-100 d-flex align-items-center " + (saved ? 'justify-content-between' : 'justify-content-end')}  >
                        {saved ? <>
                            <div className="d-flex justify-content-between align-items-center me-5">
                                <span className="display-6 me-3">
                                    <FontAwesomeIcon className="text-success" icon={['fad', 'check']} />
                                </span>
                                <small className="text-success text-uppercase">
                                    <b>{editing?.type} Saved Successfully</b>
                                </small>
                            </div>
                            <button className="btn btn-success pulse-success" onClick={hide}>Done</button>
                        </> : <>
                            <div>
                                {error && <>
                                    <ErrorText visible>An error occured during the save.</ErrorText>
                                    <button className="ms-4 btn btn-dark border-0" onClick={onHide}>Close</button>
                                </>}
                                {!error && <><button className="me-4 btn btn-outline-dark border-0" onClick={onHide} disabled={saving}>Cancel</button>
                                    <button className={"btn btn-primary " + (!(!isDirty || !valid || saving) ? 'pulse' : '')} onClick={save} disabled={!isDirty || !valid || saving}>{isNew ? 'Add' : 'Update'}</button></>}
                            </div>
                        </>}

                    </div>
            }
        >
            {visible ?
                saved ?
                    null
                    : !editing ? <Error /> :
                        editing.type === 'Folder' ?
                            <FolderForm folder={editing.model} controlFooter={setFooterControl} form={folderValues}
                                setField={folderForm.setField} setFieldValidity={folderForm.setFieldValidity}
                            />
                            : <FileForm file={editing.model} controlFooter={setFooterControl} form={fileValues}
                                setField={fileForm.setField} setFieldValidity={fileForm.setFieldValidity} cancel={cancel} />
                : null}
            {confirmProps && <InlineConfirmationModal {...confirmProps} />}
        </Modal>
    </>);
}

function useLogFileAction(id: string | undefined, log: boolean) {
    const [edgeDetection, setEdgeDetection] = useState(log);

    const logAction = useCallback(async () => {
        if (id) {
            api.post.logAction({
                fileId: id
            })
        }
    }, [id]);

    const makeCall = useAuthenticatedApiCall(logAction);

    useEffect(() => {
        setEdgeDetection(false)
    }, [id])

    useEffect(() => {
        if (!edgeDetection && log && makeCall) {
            makeCall();
            setEdgeDetection(true);
        }
    }, [edgeDetection, log, makeCall])
}
