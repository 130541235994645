import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { ContextMenu, ContextMenuItem, ContextMenuProps } from "../components/context-menu/context-menu"
import { throwError } from "./throw-error"

type ContextMenuOptions<TId extends string> = Omit<Required<ContextMenuProps<TId>>, 'enabled'>

interface ContextMenuContextType {
    setMenu: (<TId extends string>(options: ContextMenuOptions<TId>) => void);
    enabled: boolean;
}

export const ContextMenuContext = React.createContext<ContextMenuContextType>({
    setMenu: throwError,
    enabled: false
})

export function ContextMenuProvider(props: PropsWithChildren<{}>) {
    const [options, setOptions] = useState<ContextMenuOptions<any>>()

    const enabled = !!(typeof (navigator) !== 'undefined' && navigator.clipboard?.writeText);

    const setMenu: (<TId extends string>(options: ContextMenuOptions<TId>) => void) = useCallback((options) => {
        setOptions(prev => {
            if (prev) return prev;
            return options;
        })
    }, [])

    const context: ContextMenuContextType = useMemo(() => {
        return {
            setMenu,
            enabled
        }
    }, [enabled, setMenu])
    const onHide = useCallback(() => { setOptions(undefined) }, []);

    return (
        <ContextMenuContext.Provider value={context}>
            {props.children}
            <ContextMenu items={options?.items} onMenuItemClicked={options?.onMenuItemClicked} onHide={onHide} enabled={enabled} />
        </ContextMenuContext.Provider>
    );
}

export function useContextMenu<T extends HTMLElement, TId extends string>(menuItems: ContextMenuItem<TId>[], onClick: (id: TId) => void) {
    const ref = useRef<T>(null);
    const { setMenu, enabled } = useContext(ContextMenuContext);

    useEffect(() => {
        const handleMenuOpen = (e: MouseEvent) => {
            if (enabled) {
                e.preventDefault();
                setMenu({
                    items: menuItems,
                    onMenuItemClicked: onClick,
                });
            }
        }
        const element = ref.current;
        element?.addEventListener('contextmenu', handleMenuOpen);

        return () => {
            element?.removeEventListener('contextmenu', handleMenuOpen);
        }

    }, [enabled, menuItems, onClick, ref, setMenu])

    return ref;
}
