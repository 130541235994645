import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { api } from '../api/api';
import { SearchParams } from '../api/endpoints/search';
import { useSelected } from '../hooks/use-selected';
import { useSwitch } from '../hooks/use-switch';
import { useToken } from '../hooks/auth/use-token';
import { DetailedFolderInfo } from '../types/detailed-folder-info';
import { throwError } from './throw-error';

export type SearchParameters = SearchParams & { locationPath?: string; language?: string; }

interface SearchNotStarted {
    waitingForInput: true;
    parameters?: SearchParameters;
}

interface SearchStarted {
    waitingForInput: false;
    parameters: SearchParameters;
}

interface Searching extends SearchStarted {
    searching: true;
}

interface Error extends SearchStarted {
    searching: false;
    error: true;
}

interface SearchComplete extends SearchStarted {
    searching: false;
    error: false;
}

interface NoResults extends SearchComplete {
    results: null;
}

interface ResultsFound extends SearchComplete {
    results: DetailedFolderInfo;
}

const searchNotStarted: SearchNotStarted = {
    waitingForInput: true
}

const searching = (parameters: SearchParameters): Searching => {
    return {
        waitingForInput: false,
        searching: true,
        parameters: parameters
    }
}

const searchComplete = (results: DetailedFolderInfo, parameters: SearchParameters): NoResults | ResultsFound => {
    return {
        waitingForInput: false,
        searching: false,
        error: false,
        parameters: parameters,
        results: results.files.length || results.folders.length ? results : null
    }
}

const error = (parameters: SearchParameters): Error => {
    return {
        waitingForInput: false,
        searching: false,
        error: true,
        parameters: parameters
    }
}

type SearchStatus = SearchNotStarted | Searching | NoResults | ResultsFound | Error;

export interface SearchContextType {
    status: SearchStatus;
    search: (parameters: SearchParameters) => void | Promise<void>;
    clear: () => void;
    searchTools: {
        visible: boolean;
        show: (parameters?: SearchParameters) => void;
        parameters: (parameters?: SearchParameters) => void;
        hide: () => void;
    }
}

export const SearchContext = React.createContext<SearchContextType>({
    status: searchNotStarted,
    search: throwError,
    clear: throwError,
    searchTools: {
        hide: throwError,
        show: throwError,
        parameters: throwError,
        visible: false
    }
});

export function SearchContextProvider(props: PropsWithChildren<{}>) {
    const { openLoadedFolder } = useSelected()
    const [searchToolsVisible, showSearchTools, hideSearchTools] = useSwitch(false);
    const [status, setStatus] = useState<SearchStatus>(searchNotStarted);

    const showSearchToolPanel = useCallback((parameters?: SearchParameters) => {
        showSearchTools();
        if (parameters) {
            setStatus({
                waitingForInput: true,
                parameters: parameters,
            })
        }
    }, [showSearchTools])

    const parameters = useCallback((parameters?: SearchParameters) => {
        setStatus(prev => {
            return {
                ...prev,
                parameters: parameters,
            } as SearchStatus
        })
    }, [])

    const searchTools = useMemo(() => {
        return {
            visible: searchToolsVisible,
            hide: hideSearchTools,
            show: showSearchToolPanel,
            parameters: parameters,
        }
    }, [searchToolsVisible, hideSearchTools, showSearchToolPanel, parameters]);

    const getToken = useToken();

    const search = useCallback(async (parameters: SearchParameters) => {
        setStatus(searching(parameters));
        await getToken?.()
        api.post.search(parameters)
            .then(results => {
                if (results) {
                    setStatus(searchComplete(results.result, parameters));
                    openLoadedFolder(results.result)
                } else {
                    setStatus(error(parameters));
                }
            })
    }, [getToken, openLoadedFolder]);

    const clear = useCallback(() => {
        setStatus(searchNotStarted);
    }, [])

    const context: SearchContextType = useMemo(() => {
        return {
            status: status,
            search: search,
            clear: clear,
            searchTools,
        }
    }, [status, search, clear, searchTools])

    return <SearchContext.Provider value={context}>
        {props.children}
    </SearchContext.Provider>
};

