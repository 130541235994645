import { Node } from '../tree/tree';
import { DocumentTreeSelectedType } from './document-tree';

export function mapToNode(title: string, info: DocumentTreeSelectedType): Node<DocumentTreeSelectedType> {
    return {
        allowDrop: false,
        allowDrag: false,
        title: title,
        id: info.id as string,
        data: info,
        children: []
    };
}
