import { ApiCall, ParameterTypes } from '@lcms/api-definition';
import { trash } from '../../api-endpoint-definition.json';

export interface TrashResponse {
    success: boolean;
}

export const trashEndpoint = new ApiCall<TrashResponse>().post('trash', {
    route: trash,
    params: {
        folderIds: {
            type: ParameterTypes.StringArray
        },
        fileIds: {
            type: ParameterTypes.StringArray
        }
    }
});