import { AccessToken } from '../access-token';
import { ApiKey } from './api-key';
import { unauthorized } from "./unauthorized";


export function post(cors?: boolean) {
    return async (url: string, data: any, nativeProcessing?: boolean, stringify = true) => {
        const response = await fetch(url, {
            method: 'POST',
            ...(cors ? { mode: 'cors' } : {}),
            headers: stringify ? {
                'X-api-key': ApiKey,
                'Content-Type': 'application/json',
                'Token': AccessToken.value || ''
            } : {
                'X-api-key': ApiKey,
                'Token': AccessToken.value || ''
            },
            body: stringify ? JSON.stringify(data) : data
        }).then(unauthorized);
        return nativeProcessing ? response : await response.json();
    }
}
