import { api } from '../../api/api';
import { useApiResource } from './use-api-resource';
import { createCachedApiResource } from '../../services/cached-api-resource';

const load = () => api.get.fileTypes();
const resource = createCachedApiResource(load);

export function useFileTypes() {
    return useApiResource(resource.get);
}

export default resource;