import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faInfoCircle,
	faSearch,
	faCalendar,
	faTimes,
	faBooks,
	faFolder,
	faChartLine,
	faClock,
	faStars,
	faClipboardList,
	faCloudDownload,
	faSortAlt,
	faSortAmountDown,
	faSortAmountUpAlt,
	faSlidersH,
	faPlus,
	faTrashAlt,
	faPen,
	faChartBar,
	faLink,
	faTags,
	faInfoSquare,
	faArrowFromLeft,
	faSpinnerThird,
	faUser,
	faArrowRight,
	faCircle,
	faStar,
	faTrash,
	faFolderTree,
	faAsterisk,
	faFileUpload,
	faFolderUpload,
	faFolderTimes,
	faLongArrowLeft,
	faEllipsisV,
	faFileWord,
	faFileVideo,
	faFilePowerpoint,
	faFilePdf,
	faFileImage,
	faFileExcel,
	faFileAudio,
	faFileArchive,
	faFileAlt,
	faFilmAlt,
	faSlash,
	faTrashRestore,
	faUndo,
	faLongArrowAltRight,
	faBars,
	faChartNetwork,
	faLocation,
	faFileDownload,
	faEye,
	faFolderDownload,
	faFilePlus,
	faFolderPlus,
	faToggleOn,
	faCommentsAlt,
	faCheck,
} from "@fortawesome/pro-light-svg-icons";

library.add(
	faInfoCircle,
	faSearch,
	faCalendar,
	faTimes,
	faBooks,
	faFolder,
	faChartLine,
	faClock,
	faStars,
	faClipboardList,
	faCloudDownload,
	faSortAlt,
	faSortAmountDown,
	faSortAmountUpAlt,
	faSlidersH,
	faPlus,
	faPen,
	faTrashAlt,
	faChartBar,
	faLink,
	faTags,
	faInfoSquare,
	faArrowFromLeft,
	faSpinnerThird,
	faUser,
	faArrowRight,
	faCircle,
	faStar,
	faTrash,
	faFolderTree,
	faAsterisk,
	faFileUpload,
	faFolderUpload,
	faFolderTimes,
	faLongArrowLeft,
	faEllipsisV,
	faFileWord,
	faFilePowerpoint,
	faFilePdf,
	faFileImage,
	faFileExcel,
	faFileAudio,
	faFileArchive,
	faFileAlt,
	faFileVideo,
	faFilmAlt,
	faSlash,
	faTrashRestore,
	faUndo,
	faLongArrowAltRight,
	faBars,
	faChartNetwork,
	faLocation,
	faFileDownload,
	faEye,
	faFolderDownload,
	faFilePlus,
	faFolderPlus,
	faToggleOn,
	faCommentsAlt,
	faCheck
);
