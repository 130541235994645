import { ApiCall } from '@lcms/api-definition';
import { DownloadStats } from '../../../types/download-stats';
import { fileStats } from '../../api-endpoint-definition.json';

export interface FileStatResponse {
    stats: DownloadStats
}

export const fileStatsEndpoint = new ApiCall<FileStatResponse>().get('fileStats', {
    route: fileStats
});