import { PropsWithChildren } from "react";

export interface ErrorTextProps {
    visible: boolean;
}

export function ErrorText(props: PropsWithChildren<ErrorTextProps>) {
    return (
        <small className={'text-danger fw-bold fst-italic ' + (props.visible ? 'visible' : 'invisible')}>{props.children}</small>
    );
}