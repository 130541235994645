import React, { useCallback } from 'react';
import { clearDragText, setDragText } from '../../services/drag-and-drop/set-drag-image';

export interface Draggable<T extends HTMLElement = HTMLElement> {
    onDragStart: (ev: React.DragEvent<T>) => void,
    onDragEnd: (ev: React.DragEvent<T>) => void
}

interface DragData {
    type: string;
    value: string;
}

interface DragCallbacks {
    onDragStart?: (value: string) => void;
    onDragEnd?: () => void;
}


export function useDraggable<T extends HTMLElement>(data: DragData, events?: DragCallbacks, text?: string): Draggable<T> {
    const { type, value } = data;
    const { onDragEnd, onDragStart } = events || {};
    const onDragStartEvent = useCallback((ev: React.DragEvent<T>) => {
        ev.dataTransfer.setData(type, value);
        if (text) {
            setDragText(ev, text);
        }
        onDragStart?.(value);
    }, [onDragStart, text, type, value]);

    const onDragEndEvent = useCallback((ev: React.DragEvent<T>) => {
        //ev.dataTransfer.clearData(type);
        clearDragText();
        onDragEnd?.();
    }, [onDragEnd]);

    return {
        onDragStart: onDragStartEvent,
        onDragEnd: onDragEndEvent
    };
}
