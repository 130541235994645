import { Node } from './tree';

export function changeTree<T>(node: Node<T>, changes: Node<T>): Node<T> {
    if (node.id === changes.id) {
        return changes;
    }
    const newNode = {
        allowDrop: node.allowDrop,
        allowDrag: node.allowDrag,
        id: node.id,
        title: node.title,
        data: {
            ...node.data,
        },
        children: node.children
    };
    newNode.children = newNode.children.map((x) => changeTree(x, changes))
    return newNode;
}