

export default function NotSupported() {
    return (
        <div className="container shadow rounded p-5 my-3">
            <div className="row">
                <div className="col-12 text-center text-white mb-5">
                <h3>Unsupported Browser</h3>
                    <p>Your current browser <b><u>is not</u></b> compatible with the LCMS Document Library.</p>
                    <p><b>For the best experience we recommend using one of the following browsers:</b></p>
                </div>
                <div className="col-sm text-center">
                    <a href="https://www.google.com/chrome" rel="noreferrer" target="_blank">
                        <i className="fab fa-chrome fa-3x"></i><br />
                        <h5>Chrome</h5>
                        <p className="text-xs">Install for free</p>
                    </a>
                </div>
                <div className="col-sm text-center">
                    <a href="https://www.mozilla.org/en-US/firefox/new/" rel="noreferrer" target="_blank">
                        <i className="fab fa-firefox fa-3x"></i><br />
                        <h5>Firefox</h5>
                        <p className="text-xs">Install for free</p>
                    </a>
                </div>
                <div className="col-sm text-center">
                    <a href="https://www.microsoft.com/en-us/windows/microsoft-edge" rel="noreferrer" target="_blank">
                        <i className="fab fa-edge fa-3x"></i><br />
                        <h5>Microsoft Edge</h5>
                        <p className="text-xs">Install for free</p>
                    </a>
                </div>
            </div>
        </div>
    )
}