import { useCallback, useState } from "react";

export function useSwitch(defaultValue: boolean): [boolean, () => true, () => false, () => void] {
    const [value, setValue] = useState(defaultValue);

    const on: () => true = useCallback(() => {
        setValue(true)
        return true;
    }, []);

    const off: () => false = useCallback(() => {
        setValue(false)
        return false;
    }, []);

    const toggle = useCallback(() => {
        setValue(prev => !prev)
    }, []);

    return [value, on, off, toggle]
}