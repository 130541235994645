import { lazy, Suspense } from 'react';
import { LoadingPage } from '../components/loading/loading-page';
import { TrashProps } from '../pages/trash/trash';

const Page = lazy(() => import('../pages/trash/trash'))

export default function Trash(props: TrashProps) {
    return (
        <Suspense fallback={<LoadingPage />}>
            <Page {...props} />
        </Suspense>
    )
}
