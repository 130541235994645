import { ApiCall, ParameterTypes, PostPayload } from '@lcms/api-definition';
import { DetailedFolderInfo } from '../../types/detailed-folder-info';
import { search as searchRoute } from '../api-endpoint-definition.json';

export type SearchParams = PostPayload<typeof search>;

export interface SearchResponse {
    result: DetailedFolderInfo
}

export const search = new ApiCall<SearchResponse>().post('search', {
    route: searchRoute,
    params: {
        term: {
            type: ParameterTypes.String,
            optional: true
        },
        fileTypes: {
            type: ParameterTypes.StringArray,
            optional: true
        },
        labels: {
            type: ParameterTypes.StringArray,
            optional: true,
        },
        folderId: {
            type: ParameterTypes.String,
            optional: true
        },
        isTrashed: {
            type: ParameterTypes.Boolean,
            optional: true
        },
        isStarred: {
            type: ParameterTypes.Boolean,
            optional: true
        },
        modifiedAfter: {
            type: ParameterTypes.String,
            optional: true
        },
        languageId: {
            type: ParameterTypes.String,
            optional: true
        },
    }
});