import { ApiCache } from './api-cache';

export function createCachedApiResource<T>(getResource: (params: string) => Promise<T | null>) {
    const cache = new ApiCache<T>(getResource);

    function clear() {
        return cache.clearCache();
    }

    async function get(id?: string) {
        return await cache.get(id);
    }

    return {
        clear, get
    }
}