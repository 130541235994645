import { BreadCrumb } from "./bread-crumb";
import { FilePath } from "./file-path";
import { FolderInfo } from "./folder-info";
import { FolderLocation } from "./folder-location";

export const rootTitle = 'All Documents';

export const rootFolderLocation: FolderLocation = {
    type: 'Folder',
    parents: [],
    path: rootTitle,
    isPublished: true,
    isTrashed: false
}

export const rootBreadCrumb: BreadCrumb = {
    name: rootTitle,
    order: 0,
    isPublished: true,
    isTrashed: false
}

export const rootFilePath: FilePath = {
    breadCrumbs: [rootBreadCrumb],
    path: rootBreadCrumb.name,
    isPublished: true,
    isTrashed: false
}

export const rootFolderInfo: FolderInfo = {
    title: rootTitle,
    type: 'Folder',
    created: '',
    createdValue: new Date(),
    size: '',
    isStarred: false,
    isTrashed: false,
    canRestore: false,
    publishState: 'Published',
    breadCrumbs: rootFilePath.breadCrumbs,
    fullPath: rootFilePath.path,
    isPublished: true,
    permissions: {
        allowAdd: true,
        allowTrash: false,
        allowDrag: false,
        allowDrop: true,
        allowEdit: false,
        allowDelete: false,
    },
    links: {
        download: '',
        preview: ''
    }
}