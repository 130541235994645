import { useMemo } from 'react';
import { usePageBreakpoints } from './use-page-breakpoints';


export function useIsMobile() {
    const breakpoint = usePageBreakpoints();
    const isMobile = useMemo(() => {
        return breakpoint === 'xs' || breakpoint === 'sm';
    }, [breakpoint]);

    return isMobile;
}
