import { ApiCall, ParameterTypes, PostPayload } from '@lcms/api-definition';
import { deleteFolder } from '../../api-endpoint-definition.json';

export type DeleteFolderPayload = PostPayload<typeof deleteFolderEndpoint>

export interface DeleteResponse {
    success: boolean;
}

export const deleteFolderEndpoint = new ApiCall<DeleteResponse>().post('deleteFolder', {
    route: deleteFolder,
    params: {
        id: {
            type: ParameterTypes.String
        }
    }
});