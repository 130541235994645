interface PrefillOptions {
    name?: string;
    email?: string;
    subject?: string;
    text?: string;
    fields?: { id: number, value: string | number }[]
}
declare function Beacon(call: 'open' | 'close'): void;
declare function Beacon(call: 'config', options: { display: { position: 'left' | 'right' } }): void;
declare function Beacon(call: 'prefill', options: PrefillOptions): void;


export class HelpScout {

    private static activeHides: string[] = [];

    static async prefill(options: PrefillOptions) {
        await this.isReady();
        Beacon('prefill', options);
        Beacon('open');
    }

    static async close() {
        await this.isReady();
        Beacon('close');
    }

    static async hide(id: string) {
        await this.isReady();
        const index = this.activeHides.indexOf(id);
        if (index < 0) {
            this.activeHides.push(id);
        }
        this.refreshVisibility();
    }

    static async show(id: string) {
        await this.isReady();
        const index = this.activeHides.indexOf(id);
        if (index >= 0) {
            this.activeHides.splice(index, 1);
        }
        this.refreshVisibility();
    }

    private static refreshVisibility() {
        if (this.activeHides.length > 0) {
            document.getElementById('beacon-container')?.classList.add('d-none');
        } else {
            document.getElementById('beacon-container')?.classList.remove('d-none');
        }
    }

    static async isReady() {
        const readyCheck = (callback: () => void) => {
            if (typeof Beacon === 'undefined') {
                setTimeout(() => readyCheck(callback), 100)
                return;
            };
            callback();
        }

        return new Promise<void>(resolve => {
            readyCheck(resolve)
        })
    }
}