import { useMemo } from "react";
import { FileUpload } from "../../../contexts/admin-panel/use-bulk-upload";

export function BulkUploadStatus(props: { files: FileUpload[]; complete?: boolean }) {
    const { files, complete } = props;

    const percentDone = useMemo(() => {
        return Math.floor((files.length === 0 ? 1 : files.filter(f => f.state.complete).length / files.length) * 100);
    }, [files]);

    return (
        <div className="rounded-2 bg-light p-4 shadow-sm">
            <h6 className={'m-0 ' + (complete ? 'text-success' : 'text-dark')}>{complete ? 'All Done' : 'Bulk File Upload'}</h6>
            <div className="d-flex justify-content-end">
                {complete && <div className="border-start border-success border-2 shadow-sm bg-white p-3 h-100 w-50"><small>All actions have been completed, you may close this modal.</small></div>}
                <div className="w-50 ps-5">
                    <table className="table table-sm">
                        <thead>
                            <tr style={{ fontSize: '13px', textTransform: 'uppercase' }}>
                                <th className="text-center">Uploaded</th>
                                <th className="text-center">Skipped</th>
                                <th className="text-center">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                <td className="text-center">{files.filter(f => f.state.status === 'Complete').length}</td>
                                <td className="text-center">{files.filter(f => f.state.status === 'Skipped').length}</td>
                                <td className="text-center">{files.length}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {!complete && <div style={{ minWidth: '100%' }}>
                <div className="progress" style={{ height: '20px' }}>
                    <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                        aria-valuenow={percentDone} aria-valuemin={0} aria-valuemax={100} style={{ width: `${percentDone.toFixed(0)}%` }}>
                        {percentDone.toFixed(0)}%
                    </div>
                </div>
            </div>}

        </div>
    );
}
