import { ApiCall } from '@lcms/api-definition';
import { FileInfo } from '../../../types/file-info';
import { getDashboardRecent } from '../../api-endpoint-definition.json';

export interface RecentResponse {
    files: FileInfo[]
}

export const dashboardRecent = new ApiCall<RecentResponse>().get('dashboardRecent', {
    route: getDashboardRecent
});