import React from "react";

function isPermitted<T>(ev: React.DragEvent<T>, multiple: boolean) {
    const hasMultiple = ev.dataTransfer.items.length > 1 || ev.dataTransfer.files.length > 1;
    const isFile = ev.dataTransfer.types.includes('Files');

    return (isFile && (multiple || !hasMultiple))
}

function handleEvent(ev: React.DragEvent<HTMLDivElement>) {
    ev.stopPropagation();
    ev.preventDefault();
}

export const onDropZoneDragOver = (ev: React.DragEvent<HTMLDivElement>, onPermitCallback?: () => void, multiple = false) => {
    if (!isPermitted(ev, multiple)) {
        return false;
    }
    handleEvent(ev);
    onPermitCallback?.();
    return true;
}

export const onDropZoneDragEnd = (ev: React.DragEvent<HTMLDivElement>, onPermitCallback?: (files: File[]) => void, multiple = false) => {
    if (!isPermitted(ev, multiple)) {
        return false;
    }

    handleEvent(ev);
    const files = getFilesFromDropEvent(ev);
    onPermitCallback?.(files);
    return true;
}

export const getFilesFromDropEvent = (ev: React.DragEvent<HTMLDivElement>) => {
    const files: File[] = [];
    if (ev.dataTransfer.items) {
        for (let i = 0; i <= ev.dataTransfer.items.length; i++) {
            const item = ev.dataTransfer.items[i];
            try {
                const entry = item.webkitGetAsEntry();
                if (entry?.isDirectory) { continue; }
            } catch (er) { }

            const file = item?.kind === 'file' ? item.getAsFile() : null;
            if (file) {
                files.push(file)
            }
        }
    } else {
        for (let i = 0; i <= ev.dataTransfer.files.length; i++) {
            const item = ev.dataTransfer.files[i];
            files.push(item)
        }
    }

    return files;
}