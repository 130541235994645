import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback } from "react";
import { FormLink } from "../../../../types/link";
import { Tooltip } from '../../../tooltip/tooltip';

interface LinkRecordProps {
    onEdit: (link: FormLink) => void;
    onDelete: (link: FormLink) => void;
    link: FormLink;
}

export function LinkRecord(props: LinkRecordProps) {
    const { link, onEdit, onDelete } = props;

    const remove = useCallback(() => {
        onDelete(link);
    }, [onDelete, link])

    const edit = useCallback(() => {
        onEdit(link);
    }, [onEdit, link])

    if (link.isSystem) {
        return (
            <dd className={"d-flex justify-content-between align-items-center unable" + (link.isPrimary ? ' primary-link' : ' py-3')}>
                <div>{link.slug}
                    {link.isPrimary && <PrimaryLinkBadge />}
                </div>
                <div className="text-muted text-end">
                    <small>System</small>
                </div>
            </dd>
        )
    }
    return (
        <dd className={"d-flex justify-content-between align-items-center" + (link.isPrimary ? ' primary-link' : '')}>
            <div>
                {link.slug}
                {link.isPrimary && <PrimaryLinkBadge />}
            </div>
            <div className="btn btn-group btn-group-sm">
                <EditLinkButton edit={edit} />
                <DeleteLinkButton delete={remove} />
            </div>
        </dd>
    );
}

function PrimaryLinkBadge() {
    return (
        <small className="d-inline-flex w-100 text-muted mt-0 p-0">
            <span className="me-1"><FontAwesomeIcon icon={['fad', 'link']} color='#0d6efd' /></span> Primary
        </small>
    );
}


function EditLinkButton(props: { edit: () => void }) {
    return (
        <Tooltip content="Edit link" placement="left">
            <button type="button" className="btn btn-link" onClick={props.edit}><FontAwesomeIcon icon={['fad', 'pencil']} /></button>
        </Tooltip>
    );
}

function DeleteLinkButton(props: { delete: () => void }) {
    return (
        <Tooltip content="Remove link" placement="left">
            <button type="button" className="btn btn-link" onClick={props.delete}><FontAwesomeIcon icon={['fad', 'trash']} color="#dc3545" /></button>
        </Tooltip>
    );
}
