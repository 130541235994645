import { FormLink } from "../../../../types/link";
import { FooterControl } from "../admin-dialogue";
import { DescriptionInput } from "../form-components/description-input";
import { NoteInput } from "../form-components/notes-input";
import { TitleInput } from "../form-components/title-input";
import { PublishAccordion } from "../form-components/published-accordian/publish-accordion";
import { StarredAccordion } from "../form-components/starred-accordian/starred-accordian";
import { LabelsAccordion } from "../form-components/labels-accordian/labels-accordian";
import { Stats } from "../form-components/stats/stats";
import { LinksAccordian } from "../form-components/links-accordian/links-accordian";
import { FormField } from "../../../form/types/form-field";
import { DetailedFileInfo } from "../../../../types/detailed-file-info";
import { LocationInput } from "../form-components/location-input";
import { FolderLocation } from "../../../../types/folder-location";
import { FileDropZone } from "../form-components/file-drop-zone";
import { LanguageInput } from "../form-components/language-input";
import { useCallback, useEffect, useState } from "react";
import { api } from "../../../../api/api";
import { formatFileSize } from "../../../../services/format-file-size";
import { FileInfo } from "../../../../types/file-info";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSwitch } from "../../../../hooks/use-switch";
import { Dots } from "../../../loading/spinner";

export interface FormValues {
    title: string;
    description: string;
    note: string;
    labels: string[];
    links: FormLink[];
    isStarred: boolean;
    publishStartDate?: Date;
    publishEndDate?: Date;
    location: FolderLocation[];
    file?: File;
    language?: {
        id: string;
        value: string;
    };
    rollback: boolean;
}

interface FileFormProps {
    file: DetailedFileInfo;
    controlFooter: (control?: FooterControl) => void;
    form: FormValues;
    setField: (field: FormField<FormValues>) => (value: any) => void;
    setFieldValidity: (field: FormField<FormValues>) => (isValid: boolean) => void;
    cancel: () => void;
}

export function FileForm(props: FileFormProps) {
    const { file, form, controlFooter, setField, setFieldValidity, cancel } = props;
    const [userVerified, verifiyYes, clearVerification] = useSwitch(false);
    const [potentialMatches, setPotentialMatches] = useState<FileInfo[]>();
    const [checkingFile, setCheckingFile] = useState(false);

    useEffect(() => {
        if (form.file && !file.id) {
            setCheckingFile(true);
            controlFooter({
                hideFooter: true
            })
            api.post.fileExists({
                fileSize: formatFileSize(form.file.size),
                filename: form.file.name
            }).then(response => response?.result?.files)
                .then(files => {
                    setCheckingFile(false);
                    setPotentialMatches(files)
                    clearVerification();
                    if (!files?.length) {
                        controlFooter(undefined)
                    }
                })
        }
    }, [clearVerification, controlFooter, form.file, file.id])

    const verifyDuplicate = useCallback(() => {
        verifiyYes()
        controlFooter({
            hideFooter: false
        })
    }, [controlFooter, verifiyYes])

    if (checkingFile) {
        return <div style={{ minHeight: '200px' }} className="d-flex justify-content-center pt-5">
            <div className="text-center">
                <Dots />
                <div>
                    Checking if file exists
                </div>
            </div>
        </div>
    }

    return (
        <div style={{ minHeight: '200px' }}>
            {potentialMatches?.length && !userVerified ? <div className="border border-warning bg-light rounded p-4 mb-2">
                <b>This file may already be in the system:</b>
                <div className="d-flex justify-content-between align-items-center">
                    {potentialMatches.map(match => <div key={match.id}><a href={match.links.preview} target="_blank" rel="noreferrer">{match.title}</a> <FontAwesomeIcon icon={['fad', 'window-restore']} color="var(--bs-primary)" className="ms-2" /></div>)}
                    <div className="d-flex justify-content-between align-items-center">
                        <button type="button" onClick={verifyDuplicate} className="btn btn-success btn-sm mx-2">I verified and this is a new file</button>
                        <button type="button" onClick={cancel} className="btn btn-outline-dark  border-0 btn-sm mx-2">Cancel</button>
                    </div>
                </div>
            </div> : null}
            <div className={"row " + (potentialMatches?.length && !userVerified ? 'd-none' : '')}>
                <div className="col-md-6">
                    <TitleInput value={form.title} onChange={setField} onValidate={setFieldValidity} />
                    <DescriptionInput value={form.description} onChange={setField} onValidate={setFieldValidity} required />
                    <LocationInput value={form.location} onChange={setField} type='File' controlFooter={controlFooter} multiple />
                    <LanguageInput value={form.language} onChange={setField} onValidate={setFieldValidity} />
                    <NoteInput value={form.note} onChange={setField} onValidate={setFieldValidity} />
                </div>
                <div className="col-md-6">
                    <FileDropZone onChange={setField} value={form.file} thumbnail={file.links.thumbnail} onValidate={setFieldValidity} id={file.id}
                        canRollback={file.canRollback} rollback={form.rollback} onRollbackChange={setField} />
                    <Stats size={file.fileSize || '0 Bytes'}
                        type='File' created={file.uploaded} modified={file.modified}
                    />
                    <div className="accordion accordion-flush mt-3" id="addEditFormAccordion">
                        <LinksAccordian type='file' onChange={setField} controlFooter={controlFooter} value={form.links} />
                        <LabelsAccordion onChange={setField} value={form.labels} />
                        <StarredAccordion onChange={setField} value={form.isStarred} />
                        <PublishAccordion
                            currentStartDate={file.publishStartDate} currentEndDate={file.publishEndDate}
                            onChange={setField}
                            publishStartDate={form.publishStartDate} publishEndDate={form.publishEndDate}
                            type='File'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
