import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useOnClickOutside } from "../../hooks/use-on-click-outside";
import { useSwitch } from "../../hooks/use-switch";

export interface ContextMenuItem<TId extends string> {
    id: TId;
    icon?: IconName;
    title: string;
    startNewSection?: boolean;
}

export interface ContextMenuProps<TId extends string> {
    items?: ContextMenuItem<TId>[];
    onMenuItemClicked?: (id: TId) => void;
    enabled: boolean;
}

export function ContextMenu<TId extends string>(props: ContextMenuProps<TId> & { onHide: () => void }) {
    const { onMenuItemClicked, onHide: propsOnHide, items, enabled } = props;

    const [shouldShow, show, hide] = useSwitch(false);
    const [position, setPosition] = useState<[number, number]>([0, 0]);
    const onHide = useCallback(() => {
        propsOnHide();
        hide();
    }, [hide, propsOnHide])

    const menuRef = useOnClickOutside<HTMLDivElement>(onHide);



    useEffect(() => {
        const onContextMenu = (e: MouseEvent) => {
            if (enabled) {
                show();
                setPosition([e.pageX, e.pageY]);
            }
        }

        document.addEventListener("contextmenu", onContextMenu);
        document.addEventListener("wheel", onHide);
        return () => {
            document.removeEventListener("contextmenu", onContextMenu);
            document.removeEventListener("wheel", onHide);
        }
    }, [enabled, onHide, show]);

    const onClick = useCallback((id: TId) => {
        onMenuItemClicked?.(id);
        onHide();
    }, [onMenuItemClicked, onHide])

    return (<Menu
        items={items}
        position={position}
        reference={menuRef}
        visible={shouldShow}
        onClick={onClick}
    />);
}


interface MenuProps<T extends string> {
    visible: boolean;
    position: [number, number];
    items?: ContextMenuItem<T>[];
    reference: React.MutableRefObject<HTMLDivElement | null>;
    onClick: (id: T) => void;
}

function Menu<T extends string>(props: MenuProps<T>) {
    const { items, position, reference, visible, onClick } = props;

    const { boundingBox, safeArea } = (() => {
        if (!reference.current || !reference.current.parentElement) { return {}; }
        const margin = 32;
        const parentBoundingBox = reference.current.parentElement.getBoundingClientRect();
        const boundingBox = reference.current?.getBoundingClientRect();

        const safeArea = {
            xMin: parentBoundingBox.x + margin,
            yMin: parentBoundingBox.y + margin,
            xMax: parentBoundingBox.x + parentBoundingBox.width - margin,
            yMax: parentBoundingBox.y + (parentBoundingBox.height || window.innerHeight) - margin
        }

        return {
            boundingBox,
            safeArea
        }
    })();

    let posX = safeArea && boundingBox ? Math.min(safeArea.xMax - boundingBox.width, Math.max(safeArea.xMin, position[0])) : position[0];
    let posY = safeArea && boundingBox ? Math.min(safeArea.yMax - boundingBox.height, Math.max(safeArea.yMin, position[1])) : position[1];


    return (
        <div ref={reference} className={`context-menu bg-white shadow border rounded ${visible && items?.length ? '' : 'd-none'}`} style={{ left: posX, top: posY }}>
            <ul>
                {items?.map(item => <>
                    {item.startNewSection && <li className="context-menu-section-header border-bottom" key={item.id + '-section-header'}></li>}
                    <li key={item.id} className="d-flex context-menu-item" onClick={() => onClick(item.id)}>
                        <div className="context-menu-icon">{item.icon && <FontAwesomeIcon icon={['fal', item.icon]} />}</div>
                        <div className="context-menu-text">{item.title}</div>
                    </li>
                </>)}
            </ul>
        </div>
    );
}