import { useCallback, useEffect, useState } from "react";
import { api } from "../../../api/api";
import { useBulkEdit } from "../../../hooks/ui/use-bulk-edit";
import { useSwitch } from "../../../hooks/use-switch";
import { dateToString } from "../../../services/date-conversion";
import { HelpScout } from "../../../services/help-scout";
import { today } from "../../../types/dates";
import { useForm } from "../../form/use-form";
import { Dots } from "../../loading/spinner";
import { Error } from "../../error/error";
import { Modal } from "../../modal/modal";
import { LabelsAccordion } from "../admin-dialogue/form-components/labels-accordian/labels-accordian";
import { PublishAccordion } from "../admin-dialogue/form-components/published-accordian/publish-accordion";
import { StarredAccordion } from "../admin-dialogue/form-components/starred-accordian/starred-accordian";
import { ConfirmationModalProps } from "../confirmation-modal/confirmation-modal";
import { InlineConfirmationModal } from "../confirmation-modal/inline-confirmation-modal";
import { useDataSync } from "../../../hooks/use-data-sync";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Label } from "../../../types/label";
import { Loading } from "../../loading/loading";
import { InputProps } from "../admin-dialogue/input-props";
import { LanguageInput } from "../admin-dialogue/form-components/language-input";

interface Form {
    labels: string[];
    labelsToRemove: string[];
    isStarred: boolean;
    publishStartDate?: Date;
    publishEndDate?: Date;
    language?: {
        id: string;
        value: string;
    };
}

function map(): Form {
    return {
        isStarred: false,
        labels: [],
        labelsToRemove: [],
        publishStartDate: today
    }
}
const defaultValues: Form = {
    labels: [],
    labelsToRemove: [],
    isStarred: false,
    publishStartDate: today,
    publishEndDate: undefined
}

export function BulkEditDialogue() {
    const [values, setValues] = useState(defaultValues);
    const { hide, visible, mode, items } = useBulkEdit();
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [working, setWorking] = useState(false);
    const [isDirty, markDirty, markPristine] = useSwitch(false);
    const [isValid, setValidity] = useState(false);
    const form = useForm(values, map, markDirty, setValidity)
    const { formValues } = form;

    const [labels, setLabels] = useState<Label[]>()

    const [confirmProps, setConfirmProps] = useState<ConfirmationModalProps>();

    const syncDirty = useDataSync();

    const saveChanges = useCallback(() => {
        if (!items) return;

        const files = (items.filter(i => i.selectedType === 'File').map(i => i.id)) as string[];
        const folders = (items.filter(i => i.selectedType === 'Folder').map(i => i.id)) as string[];

        setWorking(true)
        const call = mode === 'label' ? api.post.label({
            fileIds: files,
            folderIds: folders,
            labels: formValues.labels,
            labelsToRemove: formValues.labelsToRemove
        }) : mode === 'promote' ? api.post.promote({
            fileIds: files,
            folderIds: folders,
            isStarred: formValues.isStarred
        }) : mode === 'language' && formValues.language ?
            api.post.editLanguage({
                fileIds: files,
                languageId: formValues.language?.id
            })
            : mode === 'publish' ? api.post.publish({
                fileIds: files,
                folderIds: folders,
                publishEndDate: dateToString(formValues.publishEndDate),
                publishStartDate: dateToString(formValues.publishStartDate)
            }) : Promise.resolve(null)

        call.then(result => {
            if (result?.success) {
                markPristine();
                syncDirty(items.map(item => item.selectedType === 'Folder' ? {
                    type: 'Folder',
                    id: item.id
                } : {
                    type: 'File',
                    id: item.id
                }))
                setSuccess(true);
            } else {
                setError(true);
            }

            setWorking(false)

        })
    }, [formValues.isStarred, formValues.labels, formValues.labelsToRemove, formValues.language, formValues.publishEndDate, formValues.publishStartDate, items, markPristine, mode, syncDirty])

    const onHide = useCallback(() => {
        if (isDirty) {
            setConfirmProps({
                onAccept: () => {
                    hide();
                    setConfirmProps(undefined);
                },
                onReject: () => {
                    setConfirmProps(undefined);
                },
                visible: true,
                lg: true,
                acceptText: 'Lose Changes',
                rejectText: 'Return to form',
                header: 'You are about to lose changes',
                prompt: 'If you cancel now, your changes will not be saved.  Are you sure you want to do this?',
                acceptIsDangerous: true
            })
        } else {
            hide()
        }
    }, [hide, isDirty])

    const onSave = useCallback(() => {
        setConfirmProps({
            onAccept: () => {
                saveChanges();
                setConfirmProps(undefined);
            },
            onReject: () => {
                setConfirmProps(undefined);
            },
            lg: true,
            visible: true,
            acceptText: 'Apply',
            rejectText: 'Cancel',
            header: 'Multiple Items Selected',
            prompt: (<>This action will apply across multiple items. <br />  Are you sure?</>),
            acceptIsDangerous: true
        })
    }, [saveChanges])

    useEffect(() => {
        if (visible) {
            setValues({
                ...defaultValues
            })
            setLabels(undefined);
            if (items && mode === 'label') {
                api.post.itemLabels({
                    fileIds: items.filter(item => item.selectedType === 'File' && item.id).map(item => item.id as string),
                    folderIds: items.filter(item => item.selectedType === 'Folder' && item.id).map(item => item.id as string),
                }).then(result => {
                    setLabels(result?.labels || []);
                })
            }
            markPristine();
            setWorking(false);
            setSuccess(false);
            setError(false);
            HelpScout.hide('bulk-edit-modal');
        } else {
            HelpScout.show('bulk-edit-modal');
        }
    }, [items, markPristine, mode, visible])

    return (
        <Modal
            visible={visible}
            onHide={onHide}
            Footer={success || error ?
                <div className="d-flex justify-content-between w-100">
                    <div>
                    </div>
                    <div>
                        <button type="button" className={"btn " + (success ? 'btn-success pulse-success' : 'btn-primary ')} onClick={hide}>{success ? 'Done' : 'Close'}</button>
                    </div>
                </div> : <div className="d-flex justify-content-between w-100">
                    <div>
                        <button type="button" className="btn btn-outline-dark border-0" disabled={working} onClick={onHide}>Cancel</button>
                    </div>
                    <div>
                        <button type="button" className="btn btn-primary" onClick={onSave} disabled={working || !isValid || !isDirty}>Apply</button>
                    </div>
                </div>}
            header={mode === 'label' ? 'Label Selection' : mode === 'promote' ? 'Promote Selection' : mode === 'language' ? 'Adjust Language' : mode === 'publish' ? 'Publish Selection' : ''}
        >{success ? <div>
            <span className="display-6 me-3">
                <FontAwesomeIcon className="text-success" icon={['fad', 'check']} />
            </span>
            <small className="text-success text-uppercase">
                <b>Action Completed Successfully</b>
            </small>
        </div> : error ? <div>
            <Error />
        </div> : working ?
            <div>
                <Dots />
            </div>
            : <div className="accordion accordion-flush single-form-accordion mt-3" id="addEditFormAccordion">
                {mode === 'label' ? <>
                    <LabelsAccordion show value={formValues.labels} onChange={form.setField} />
                    <LabelRemover itemCount={items?.length || 0} allLabels={labels} value={formValues.labelsToRemove} onChange={form.setField} />
                </> :
                    mode === 'language' ? <LanguageInput onValidate={form.setFieldValidity} value={form.formValues.language} onChange={form.setField} /> :
                        mode === 'promote' ? <StarredAccordion show value={formValues.isStarred} onChange={form.setField} /> :
                            mode === 'publish' ? <PublishAccordion show
                                currentStartDate={dateToString(today)} currentEndDate={undefined}
                                onChange={form.setField}
                                publishStartDate={formValues.publishStartDate} publishEndDate={formValues.publishEndDate}
                                type='Selection'
                            /> :
                                null
                }
            </div>}
            {confirmProps && <InlineConfirmationModal {...confirmProps} />}
        </Modal>
    );
}

function LabelRemover(props: InputProps<'labelsToRemove', string[]> & { allLabels?: Label[]; itemCount: number }) {
    const { onChange, value: labelsToRemove, allLabels } = props;

    const toggle = useCallback((label: string) => {
        const hasItem = labelsToRemove.includes(label);
        onChange('labelsToRemove')(hasItem ? labelsToRemove.filter(l => l !== label) : labelsToRemove.concat(label))
    }, [labelsToRemove, onChange])

    return (
        <div className="accordion-body bg-white">
            {allLabels?.length ? <div className="form-label">Select Labels to Remove</div> : <div className="form-label text-muted fw-normal">No Labels To Remove</div>}
            <div style={{ maxHeight: '30vh', overflowY: 'auto', overflowX: 'hidden' }}>
                {!allLabels ? <Loading /> :
                    allLabels.map(l =>
                        <button key={l.id} className={"btn btn-outline-secondary border-0 w-100 d-flex p-2 justify-content-between align-items-center"} onClick={() => toggle(l.title)}>
                            <small className="text-start d-flex justify-content-start w-75">
                                <div className={"badge p-2 me-3 d-flex align-self-center" + (labelsToRemove.includes(l.title) ? ' bg-dark' : ' bg-primary')}>{l.count === props.itemCount ? 'All' : l.count?.toFixed(0)} {l.count?.toFixed(0) === '1' ? 'Item' : 'Items'}</div>
                                <div>{l.title}</div>
                            </small>
                            <div>
                                {labelsToRemove.includes(l.title) && <FontAwesomeIcon icon={['fad', 'trash']} />}
                            </div>
                        </button>
                    )
                }
            </div>
        </div>
    );
}