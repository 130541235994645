import { lazy, Suspense } from 'react';
import { LoadingPage } from '../../components/loading/loading-page';
import { DashboardProps } from '../../pages/dashboard/dashboard';

const Page = lazy(() => import('../../pages/dashboard/dashboard'))

export default function Dashboard(props: DashboardProps) {
    return (
        <Suspense fallback={<LoadingPage />}>
            <Page {...props} />
        </Suspense>
    )
}
