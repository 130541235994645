import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PublishState } from "../../../../../types/publish-state";

export function Status(props: { state: PublishState; layout?: boolean; type?: string }) {
    const { state } = props;

    return (<>
        {!props.layout ? <>
            {state === 'Unpublished' ?
                <><span className="me-2"><UnpublishedIcon /></span> Unpublished </> :
                state === 'PendingPublish' ?
                    <><span className="me-2"><ScheduledPublishIcon /></span> Scheduled </> :
                    state === 'PendingUnpublish' ?
                        <><span className="me-2"><ScheduledUnpublishIcon /></span> Scheduled </> :
                        <><span className="me-2"><PublishedIcon /></span> Published </>}
        </> : <div className="border bg-light rounded-2 p-2 px-3 mt-3">


            <span className="me-1"><b>Status:</b> {props.type} is</span>
            {state === 'Unpublished' ?
                <span>unpublished </span> :
                state === 'PendingPublish' ?
                    <span>scheduled </span> :
                    state === 'PendingUnpublish' ?
                        <span>scheduled </span> : <span>published </span>}
            {/* FIXME
                        Conditionally need more detail for Scheduled Conditions
                        1) If start date empty && end date set
                            initially set the file to be published, and then unpublished on the date set
                            Status: Folder will be published until [End Date Value].
                            
                        2) If start date set && end date empty
                            initially set file to be unpublished, and publish on date set to remain published
                            Status: Folder will be published on [Start Date Value].
                           
                        3) If start date empty && end date empty
                            initially set file to be unpublished and default selection to ‘Unpublished’ on main form save
                            Status: Folder is unpublished as no start or end dates were set.

                        */}
        </div>}

    </>);
}

export function UnpublishedIcon() {
    return <FontAwesomeIcon icon={['fal', 'circle']} color='#dc3545' />
}
export function ScheduledPublishIcon() {
    return <FontAwesomeIcon icon={['far', 'clock']} color='#28a745' />
}
export function ScheduledUnpublishIcon() {
    return <FontAwesomeIcon icon={['far', 'clock']} color='#E89805' />
}
export function PublishedIcon() {
    return <FontAwesomeIcon icon={['fas', 'circle']} color='#28a745' />
}