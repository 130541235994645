import { Dictionary } from '@lcms/helpers';

interface Call<T> {
    result: T | null,
    promise?: Promise<T | null>
}

export class ApiCache<T> {
    constructor(private getResource: (params: string) => Promise<T | null>) { }

    private routes: Dictionary<Call<T>> = {}

    private getCache(param: string) {
        return this.routes[param] || {
            result: null
        }
    }

    private setCache(param: string, result: T | null) {
        this.routes[param] = {
            result: result
        }
    }

    public clearCache(): ApiCache<T> {
        this.routes = { };
        return this;
    }

    public async get(params: string = ''): Promise<T | null> {
        const cache = this.getCache(params);
        if (cache.result) { return cache.result; }

        if (cache.promise) {
            return cache.promise;
        }

        cache.promise = this.getResource(params);

        const result = await cache.promise;
        this.setCache(params, result);

        return result;
    }
}