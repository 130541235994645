export function dateToString(date?: Date): string {
    if (!date) return '';
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    return `${month}/${day}/${year}`;
}

export function stringToDate(date?: string): Date | undefined {
    if (!date) return undefined;
    const dateParts = date.split('/');
    const month = +dateParts[0] - 1;
    const day = +dateParts[1];
    const year = +dateParts[2];
    return new Date(Date.UTC(year, month, day, 0, 0, 0, 0));
}