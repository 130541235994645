import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { routes } from '../../routing/routes';
import { LoadingPage } from '../loading/loading-page';


export interface AuthenticatedRouteProps extends Omit<RouteProps, 'render'>, Required<Pick<RouteProps, 'render'>> {
}

export default function AuthenticatedRoute(props: AuthenticatedRouteProps) {

    const authenticated = useIsAuthenticated();

    return <Route {...props} render={(childProps) =>
        authenticated ?
            props.render(childProps)
            : <NotAuthenticated />
    } />
};

function NotAuthenticated() {
    const { inProgress } = useMsal();
    return inProgress !== InteractionStatus.None
        ? <LoadingPage />
        : <Redirect to={{ pathname: routes.dashboard }
        } />
}