import { lazy, Suspense } from 'react';
import { LoadingPage } from '../../components/loading/loading-page';
import { SearchResultsProps } from '../../pages/search-results/search-results';

const SearchResultsPage = lazy(() => import('../../pages/search-results/search-results.mobile'))

export default function SearchResults(props: SearchResultsProps) {
    return (
        <Suspense fallback={<LoadingPage />}>
            <SearchResultsPage {...props} />
        </Suspense>
    )
}
