import { lazy, Suspense } from 'react';
import { LoadingPage } from '../components/loading/loading-page';
import { UntrackedProps } from '../pages/untracked/untracked';

const Page = lazy(() => import('../pages/untracked/untracked'))

export default function Untracked(props: UntrackedProps) {
    return (
        <Suspense fallback={<LoadingPage />}>
            <Page {...props} />
        </Suspense>
    )
}
