import { stringToDate } from "../../../services/date-conversion";
import { FormValues as FolderFormValues } from "./folder-form/folder-form";
import { FolderEditModel } from "../../../contexts/admin-panel/use-add-edit-form";

export function mapFolderToForm(editModel?: FolderEditModel): FolderFormValues {
    const folder = editModel?.model;
    if (!folder) {
        return {
            description: '',
            isStarred: false,
            labels: [],
            links: [],
            note: '',
            title: ''
        };
    }
    return {
        title: folder.title || '',
        description: folder.description || '',
        note: folder.note || '',
        labels: folder.labels.map(l => l.title),
        links: folder.slugs.map(x => {
            return {
                ...x,
                formKey: x.id
            };
        }),
        isStarred: folder.isStarred,
        publishEndDate: stringToDate(folder.publishEndDate),
        publishStartDate: stringToDate(folder.publishStartDate),
    };
}
