import { stringToDate } from "../../../services/date-conversion";
import { FormValues as FileFormValues } from "./file-form/file-form";
import { FileEditModel } from "../../../contexts/admin-panel/use-add-edit-form";

export function mapFileToForm(editModel?: FileEditModel): FileFormValues {
    const file = editModel?.model;
    if (!file) {
        return {
            description: '',
            isStarred: false,
            labels: [],
            links: [],
            note: '',
            title: '',
            location: [],
            rollback: false,
            file: editModel?.file
        };
    }

    return {
        title: file.title || '',
        description: file.description || '',
        note: file.note || '',
        labels: file.labels.map(l => l.title),
        links: file.slugs.map(x => {
            return {
                ...x,
                formKey: x.id
            };
        }),
        isStarred: file.isStarred,
        publishEndDate: stringToDate(file.publishEndDate),
        publishStartDate: stringToDate(file.publishStartDate),
        location: file.paths.map(p => {
            return {
                type: 'Folder',
                id: p.folderId,
                parents: p.breadCrumbs,
                path: p.path,
                isPublished: p.isPublished,
                isTrashed: p.isTrashed
            };
        }),
        language: file.language,
        rollback: false,
        file: editModel?.file
    };
}
