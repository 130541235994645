import { ApiCall } from '@lcms/api-definition';
import { reports } from '../../api-endpoint-definition.json';

export interface DownloadsReport {
	type: 'Downloads';
	startDate: string;
	endDate: string;
	folder?: string;
}

export interface ReferrersReport {
	type: 'Referrers';
	startDate: string;
	endDate: string;
}

export interface StaleFilesReport {
	type: 'StaleFiles';
	endDate: string;
}

export interface FileReport {
	type: 'File';
	fileId: string;
}

export interface FolderReport {
	type: 'Folder';
	folderId: string | undefined;
}

export interface NotFoundReport {
	type: 'NotFound';
	filterType: 'File' | 'Folder';
}

export interface LabelsReport {
	type: 'Labels';
}

export const reportsEndpoint = new ApiCall<Response>().post('report', {
	route: reports,
	nativeProcessing: true,
	params: {
		report: {
			type: {} as DownloadsReport | ReferrersReport | StaleFilesReport | FileReport | FolderReport | NotFoundReport | LabelsReport,
		},
	},
	process: (response) => {
		response.blob().then((blob) => {
			const filename =
				response.headers
					.get('content-disposition')
					?.split(';')
					.map((s) => s.trim().split('='))
					.find((t) => t[0] === 'filename')?.[1]
					?.replace(/"/gi, '') || 'download.csv';
			const file = new File([blob], filename, {
				type: response.headers.get('content-type') || undefined,
			});
			const url = window.URL.createObjectURL(file);
			const a = document.createElement('a');
			a.href = url;
			a.download = filename;
			document.body.appendChild(a);
			a.click();
			setTimeout(() => {
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
			}, 0);
		});

		return response;
	},
});
