import { IconName } from '@fortawesome/fontawesome-svg-core';

export function mapFileTypeToIcon(type: string): IconName {
    switch (type) {

        case 'Audio':
            return 'file-audio';
        case 'Folder':
            return 'folder';
        case 'Image':
            return 'file-image';
        case 'Microsoft Excel':
            return 'file-excel';
        case 'Microsoft PowerPoint':
            return 'file-powerpoint';
        case 'Microsoft Word':
            return 'file-word';
        case 'PDF':
            return 'file-pdf';
        case 'Video':
            return 'file-video';
        case 'Zipped Folder':
            return 'file-archive';
        case 'Other':
        default:
            return 'file-alt';
    }
}