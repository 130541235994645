import { ApiCall, ParameterTypes } from '@lcms/api-definition';
import { label } from '../../api-endpoint-definition.json';

export interface LabelResponse {
    success: boolean;
}

export const labelEndpoint = new ApiCall<LabelResponse>().post('label', {
    route: label,
    params: {
        folderIds: {
            type: ParameterTypes.StringArray
        },
        fileIds: {
            type: ParameterTypes.StringArray
        },
        labels: {
            type: ParameterTypes.StringArray
        },
        labelsToRemove: {
            type: ParameterTypes.StringArray
        }
    }
});