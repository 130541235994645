import { ApiCall, ParameterTypes, PostPayload } from '@lcms/api-definition';
import { restoreFolder } from '../../api-endpoint-definition.json';

export type RestoreFolderPayload = PostPayload<typeof restoreFolderEndpoint>

export interface RestoreResponse {
    success: boolean;
}

export const restoreFolderEndpoint = new ApiCall<RestoreResponse>().post('restoreFolder', {
    route: restoreFolder,
    params: {
        id: {
            type: ParameterTypes.String
        }
    }
});