import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react"
import { Modal } from "../components/modal/modal";
import { DocumentTree, DocumentTreeProps, DocumentTreeSelectedType } from "../components/document-tree/document-tree";
import { useSwitch } from "../hooks/use-switch";
import { throwError } from "./throw-error";
import { ConfirmationModal, ConfirmationModalProps } from "../components/modals/confirmation-modal/confirmation-modal";
import { HelpScout } from "../services/help-scout";
import { FileTypeModal, ShowFileTypePickerProps, useFileTypeModal } from "../components/modals/file-type-modal/file-type-modal";
import { LabelModal, ShowLabelPickerProps, useLabelModal } from "../components/modals/labels-modal/label-modal";
import { LocateModal, ShowLocateModalProps, useLocateModal } from "../components/modals/locate-modal/locate-modal";
//import { BulkUploadContent } from "../components/bulk-upload/bulk-upload-content";

type ShowDocumentTreeProps = Omit<DocumentTreeProps, 'select'> & { multiple?: boolean; select: (items?: DocumentTreeSelectedType[]) => void; };
type ConfirmProps = Partial<Omit<Omit<ConfirmationModalProps, 'visible'>, 'onHide'>>;

interface ModalsServiceContextType {
    documentLocationTreePicker: {
        show: (props: ShowDocumentTreeProps) => void;
        hide: () => void;
    },
    fileTypePicker: {
        show: (props: ShowFileTypePickerProps) => void;
        hide: () => void;
    },
    labelPicker: {
        show: (props: ShowLabelPickerProps) => void;
        hide: () => void;
    },
    locateModal: {
        show: (props: ShowLocateModalProps) => void;
        hide: () => void;
    },
    confirm: (confirm: ConfirmProps) => void;
}

export const ModalServiceContext = React.createContext<ModalsServiceContextType>({
    documentLocationTreePicker: {
        show: throwError,
        hide: throwError,
    },
    fileTypePicker: {
        show: throwError,
        hide: throwError,
    },
    labelPicker: {
        show: throwError,
        hide: throwError,
    },
    locateModal: {
        show: throwError,
        hide: throwError,
    },
    confirm: throwError
})

export function ModalServiceProvider(props: PropsWithChildren<{}>) {
    const [documentTreeVisible, showDocumentTree, hideDocumentTree] = useSwitch(false)
    const [documentTreeMultiple, setDocumentTreeMultiple] = useState(false);
    const [documentTreeSelected, setDocumentTreeSelected] = useState<DocumentTreeSelectedType[]>();
    const [documentTreeIncludeFiles, setDocumentTreeIncludeFiles] = useState(false);
    const [onDocumentTreeApply, setOnDocumentTreeApply] = useState<{ onDone: (changes?: DocumentTreeSelectedType[]) => void }>({ onDone: hideDocumentTree })
    const onShowDocumentTree = useCallback((p: ShowDocumentTreeProps) => {
        setDocumentTreeMultiple(p.multiple || false);
        setDocumentTreeIncludeFiles(p.includeFiles || false);
        setDocumentTreeSelected(p.selected);
        setOnDocumentTreeApply({
            onDone: (changes) => {
                p.select(changes);
                hideDocumentTree();
            }
        })
        showDocumentTree();
    }, [setDocumentTreeSelected, showDocumentTree, hideDocumentTree])

    const { options: fileTypePicker, show: showFileTypePicker, hide: hideFileTypePicker, toggleItem: toggleSelectedFileType } = useFileTypeModal();
    const { options: labelPicker, show: showLabelPicker, hide: hideLabelPicker, toggleItem: toggleSelectedLabel } = useLabelModal();
    const { options: locateModal, show: showLocateModal, hide: hideLocateModal } = useLocateModal();

    const [confirm, setConfirm] = useState<ConfirmProps>();
    const onConfirmPrompt = useCallback((confirm: ConfirmProps) => {
        setConfirm({
            acceptText: confirm.acceptText,
            rejectText: confirm.rejectText,
            header: confirm.header,
            prompt: confirm.prompt,
            onAccept: () => {
                confirm.onAccept?.();
                setConfirm(undefined);
            },
            onReject: () => {
                confirm.onReject?.();
                setConfirm(undefined)
            }
        })
    }, [])

    const context = useMemo(() => {
        return {
            documentLocationTreePicker: {
                hide: hideDocumentTree,
                show: onShowDocumentTree
            },
            fileTypePicker: {
                hide: hideFileTypePicker,
                show: showFileTypePicker
            },
            labelPicker: {
                hide: hideLabelPicker,
                show: showLabelPicker
            },
            locateModal: {
                hide: hideLocateModal,
                show: showLocateModal
            },
            confirm: onConfirmPrompt
        }
    }, [hideDocumentTree, onShowDocumentTree, hideFileTypePicker, showFileTypePicker, hideLabelPicker, showLabelPicker, hideLocateModal, showLocateModal, onConfirmPrompt])

    const modalIsPopped = useMemo(() => { return documentTreeVisible || !!fileTypePicker || !!confirm || !!labelPicker || !!locateModal },
        [documentTreeVisible, fileTypePicker, confirm, labelPicker, locateModal]);

    useEffect(() => {
        if (modalIsPopped) {
            HelpScout.hide('modal-service');
        } else {
            HelpScout.show('modal-service');
        }
    }, [modalIsPopped])

    return (
        <ModalServiceContext.Provider value={context}>

            {props.children}
            <Modal visible={documentTreeVisible} onHide={hideDocumentTree} scrollable noPadding
                Footer={<div className="d-flex w-100 d-flex justify-content-between">
                    <button type="button" className="btn btn-outline-dark" onClick={hideDocumentTree}>Cancel</button>
                    <button type="button" className="btn btn-primary" onClick={() => onDocumentTreeApply.onDone(documentTreeSelected)}>Apply</button>
                </div>}
                header={'Select Location:'}
            >
                <div className="alert alert-secondary p-3 rounded-0  mb-0">
                    <small>Note: multiple folder selections are not allowed</small>
                </div>
                <DocumentTree
                    includeFiles={documentTreeIncludeFiles as any}
                    selected={documentTreeSelected}
                    select={(item: DocumentTreeSelectedType) => setDocumentTreeSelected(items => {
                        if (documentTreeMultiple) {
                            return items?.filter(i => i !== item).concat(item) || [item]
                        } else {
                            return [item];
                        }
                    })} />
            </Modal>
            <FileTypeModal
                visible={!!fileTypePicker}
                onDone={fileTypePicker?.onDone || emptyFunction}
                onHide={hideFileTypePicker}
                selected={fileTypePicker?.selected || []}
                toggle={toggleSelectedFileType}
            />
            <LabelModal
                visible={!!labelPicker}
                onDone={labelPicker?.onDone || emptyFunction}
                onHide={hideLabelPicker}
                selected={labelPicker?.selected || []}
                toggle={toggleSelectedLabel}
            />
            <LocateModal
                visible={!!locateModal}
                onHide={hideLocateModal}
                locations={locateModal?.locations || []}
            />
            <ConfirmationModal visible={!!confirm}
                acceptIsDangerous
                acceptText={confirm?.acceptText}
                rejectText={confirm?.rejectText}
                header={confirm?.header}
                prompt={confirm?.prompt}
                onAccept={confirm?.onAccept || emptyFunction}
                onReject={confirm?.onReject || emptyFunction}
            />
        </ModalServiceContext.Provider>
    );
}

const emptyFunction = () => { };
