import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { api } from '../api/api';
import { useToken } from '../hooks/auth/use-token';
import { DetailedFileInfo } from "../types/detailed-file-info";
import { DetailedFolderInfo } from '../types/detailed-folder-info';
import { throwError } from './throw-error';

export type FolderState = ({ loading: true; id: string | undefined; result: null; }) |
    ({ loading: false; error: true; id: string | undefined; result: null; }) |
    ({ loading: false; error: false; id: string | undefined; } & { result: DetailedFolderInfo | null });
export type FileState = ({ loading: true, id: string; result: null; }) | ({ loading: false; error: true; id: string; result: null; }) | ({ loading: false; error: false; } & { result: DetailedFileInfo | null });

export type SelectedItem = ({ selectedType: 'Folder'; id?: string; } & FolderState) | ({ selectedType: 'File'; id: string; } & FileState)
export type MultiSelectItem = { selectedType: 'Folder'; id?: string; } | { selectedType: 'File'; id: string; };

export interface SelectedFileContextType {
    openedFolder?: FolderState;
    selected?: SelectedItem;
    multiSelect: MultiSelectItem[];
    selectFile: (id: string, forceFetch?: boolean) => void;
    selectLoadedFile: (file: DetailedFileInfo) => void;
    selectFolder: (id?: string, forceFetch?: boolean) => void;
    selectLoadedFolder: (folder: DetailedFolderInfo) => void;
    multiSelectFile: (id: string) => void;
    multiSelectFolder: (id?: string) => void;
    openFolder: (id?: string) => void;
    reopenFolder: () => void;
    openLoadedFolder: (folder: DetailedFolderInfo) => void;
    deselect: () => void;
    isFileSelected: (id: string) => boolean;
    isFolderSelected: (id?: string) => boolean;
}

export const SelectedFileContext = React.createContext<SelectedFileContextType>({
    deselect: throwError,
    selectFile: throwError,
    selectLoadedFile: throwError,
    openFolder: throwError,
    openLoadedFolder: throwError,
    selectFolder: throwError,
    selectLoadedFolder: throwError,
    multiSelectFolder: throwError,
    multiSelectFile: throwError,
    isFileSelected: throwError,
    isFolderSelected: throwError,
    reopenFolder: throwError,
    multiSelect: []
});

export function SelectedFileContextProvider(props: PropsWithChildren<{}>) {

    const [selectedItems, setSelectedItems] = useState<MultiSelectItem[]>([])
    const [selected, setSelected] = useState<SelectedItem>()
    const [openedFolder, setOpenedFolder] = useState<FolderState>();

    const getToken = useToken();

    const deselect = useCallback(() => {
        setSelected(undefined);
        setSelectedItems([]);
    }, [])

    const selectLoadedFile = useCallback((file: DetailedFileInfo) => {
        setSelectedItems([{
            selectedType: 'File',
            id: file.id
        }]);
        setSelected({
            selectedType: 'File',
            id: file.id,
            loading: false,
            error: false,
            result: file
        });
    }, [])

    const selectFile = useCallback(async (id: string, forceFetch?: boolean) => {
        if (!forceFetch) {
            if (selected && selected.selectedType === 'File' && selected.id === id) {
                return;
            }
        }

        setSelectedItems([{
            selectedType: 'File',
            id: id
        }])

        setSelected({
            selectedType: 'File',
            loading: true,
            result: null,
            id: id
        });
        await getToken?.();
        api.get.file(`/${id}`)
            .then(response => {
                if (response) {
                    setSelected({
                        selectedType: 'File',
                        id: id,
                        loading: false,
                        error: false,
                        result: response.file
                    });
                } else {
                    setSelected({
                        selectedType: 'File',
                        id: id,
                        loading: false,
                        result: null,
                        error: true,
                    });
                }
            })
            .catch(() => setSelected({
                selectedType: 'File',
                loading: false,
                result: null,
                error: true,
                id: id
            }))
    }, [selected, getToken]);

    const selectLoadedFolder = useCallback((folder: DetailedFolderInfo | null) => {
        if (folder) {
            setSelectedItems([{
                selectedType: 'Folder',
                id: folder.id
            }])

            setSelected({
                selectedType: 'Folder',
                id: folder.id,
                error: false,
                loading: false,
                result: folder
            })
        } else {
            setSelectedItems([]);
            setSelected(undefined);
        }
    }, [])

    const openLoadedFolder = useCallback((folder: DetailedFolderInfo) => {
        setOpenedFolder({
            loading: false,
            error: false,
            result: folder,
            id: folder.id
        })
        setSelected({
            selectedType: 'Folder',
            error: false,
            loading: false,
            result: folder,
            id: folder.id
        })
    }, [])

    const selectFolder = useCallback(async (id?: string, forceFetch?: boolean) => {
        setSelectedItems([{
            selectedType: 'Folder',
            id: id
        }])

        if (!forceFetch) {
            if (selected && selected.selectedType === 'Folder' && selected.id === id) {
                return;
            }

            if (openedFolder && openedFolder.id === id) {
                setSelected({
                    selectedType: 'Folder',
                    ...openedFolder
                })
                return;
            }
        }

        setSelected({
            selectedType: 'Folder',
            result: null,
            loading: true,
            id: id
        });

        await getToken?.();
        api.get.folder(`/${id || ''}`)
            .then(response => {
                if (response) {
                    setSelected({
                        selectedType: 'Folder',
                        id: id,
                        loading: false,
                        error: false,
                        result: response.folder
                    });
                } else {
                    setSelected({
                        selectedType: 'Folder',
                        result: null,
                        loading: false,
                        error: true,
                        id: id
                    });
                }
            })
            .catch(() => setSelected({
                selectedType: 'Folder',
                result: null,
                loading: false,
                error: true,
                id: id
            }))
    }, [openedFolder, selected, getToken]);


    const openFolder = useCallback(async (id?: string, forceFetch?: boolean) => {
        if (!forceFetch) {
            if (selected && selected.selectedType === 'Folder' && selected.id === id) {
                setOpenedFolder({
                    ...selected
                })
                return;
            }
        }

        setOpenedFolder({
            result: null,
            loading: true,
            id: id
        });
        await getToken?.();
        api.get.folder(`/${id || ''}`)
            .then(response => {
                if (response) {
                    setOpenedFolder({
                        loading: false,
                        id: id,
                        error: false,
                        result: response.folder
                    });
                    selectLoadedFolder(response.folder);
                }
                else {
                    setOpenedFolder({
                        loading: false,
                        error: true,
                        result: null,
                        id: id
                    });
                }
            })
            .catch(() => setOpenedFolder({
                loading: false,
                result: null,
                error: true,
                id: id
            }))
    }, [selected, selectLoadedFolder, getToken]);

    const reopenFolder = useCallback(() => {
        openFolder(openedFolder?.id, true)
    }, [openFolder, openedFolder?.id])

    useEffect(() => {
        if (selected && openedFolder && selected.selectedType === 'Folder' && selected.id === openedFolder.id) {
            if ((selected.loading || selected.error) && !openedFolder.loading) {
                setSelected({
                    selectedType: 'Folder',
                    ...openedFolder
                })
            } else if (!selected.loading && (openedFolder.loading || openedFolder.error)) {
                const { selectedType, ...folder } = selected
                setOpenedFolder({
                    ...folder
                })
            }
        }
    }, [selected, openedFolder])


    const multiSelectFile = useCallback((id: string) => {
        setSelectedItems(prev => {
            const deselected = prev.filter(i => !(i.selectedType === 'File' && i.id === id));

            if (prev.length === 1 && openedFolder) {
                const selectedItem = prev[0];
                if (selectedItem.selectedType === 'Folder' && (selectedItem.id || '') === (openedFolder.id || '')) {
                    selectFile(id);
                    return [];
                }
            }

            if (deselected.length === prev.length) {
                return prev.concat({
                    selectedType: 'File',
                    id: id
                })
            }


            if (deselected.length === 1) {
                const lastItem = deselected[0]
                if (lastItem.selectedType === 'File' && id) {
                    selectFile(lastItem.id);
                } else {
                    selectFolder(lastItem.id);
                }
                return [];
            }

            return deselected;

        })
    }, [selectFile, selectFolder, openedFolder]);


    const multiSelectFolder = useCallback((id?: string) => {
        setSelectedItems((prev) => {
            const deselected = prev.filter(i => !(i.selectedType === 'Folder' && i.id === id));

            if (prev.length === 1 && openedFolder) {
                const selectedItem = prev[0];
                if (selectedItem.selectedType === 'Folder' && (selectedItem.id || '') === (openedFolder.id || '')) {
                    selectFolder(id);
                    return [];
                }
            }

            if (deselected.length === prev.length) {
                return prev.concat({
                    selectedType: 'Folder',
                    id: id
                })
            }

            if (deselected.length === 1) {
                const lastItem = deselected[0]
                if (lastItem.selectedType === 'File' && id) {
                    selectFile(lastItem.id);
                } else {
                    selectFolder(lastItem.id);
                }
                return [];
            }

            return deselected;
        })

    }, [selectFile, selectFolder, openedFolder]);

    const isFileSelected = useCallback((id: string) => {
        return !!(selectedItems?.find(item => item.selectedType === 'File' && item.id === id))
    }, [selectedItems]);

    const isFolderSelected = useCallback((id?: string) => {
        return !!(selectedItems?.find(item => item.selectedType === 'Folder' && item.id === id))
    }, [selectedItems]);

    const context: SelectedFileContextType = useMemo(() => {
        return {
            deselect: deselect,
            selectFile: selectFile,
            selectFolder: selectFolder,
            openFolder: openFolder,
            multiSelectFile: multiSelectFile,
            multiSelectFolder: multiSelectFolder,
            multiSelect: selectedItems,
            selected: selected,
            openedFolder: openedFolder,
            isFileSelected: isFileSelected,
            isFolderSelected: isFolderSelected,
            selectLoadedFolder: selectLoadedFolder,
            openLoadedFolder: openLoadedFolder,
            reopenFolder: reopenFolder,
            selectLoadedFile: selectLoadedFile
        }
    }, [deselect, selectFile, selectFolder, openFolder, multiSelectFile,
        multiSelectFolder, selectedItems, selected, openedFolder,
        isFileSelected, isFolderSelected, selectLoadedFolder, openLoadedFolder,
        reopenFolder, selectLoadedFile])


    if (!context) {
        return null;
    }

    return <SelectedFileContext.Provider value={context}>
        {props.children}
    </SelectedFileContext.Provider>
};

