import { useCallback, useEffect, useMemo, useState } from "react";
import { api } from "../../../../api/api";
import { FormLink } from "../../../../types/link";
import { Switch } from "../../../form/switch/switch";
import { Dots } from "../../../loading/spinner";
import { InlineModal } from "../../../modal/modal-inline";

interface LinkEditFormProps {
    visible: boolean;
    onHide: () => void;
    link?: FormLink;
    onSave: (link: FormLink) => void;
    type: 'file' | 'folder';
    existingSlugs: string[];
}

export function LinkEditForm(props: LinkEditFormProps) {
    const { onHide, visible, link, onSave } = props;

    const [slug, setSlug] = useState(link?.slug || '');
    const [isPrimary, setIsPrimary] = useState(link?.isPrimary || false);

    const [urlCheck, setIsCheckingUrl] = useState<{ needsChecking: true } | { needsChecking: false, checking: true; } | { needsChecking: false; checking: false; valid: boolean; }>({ needsChecking: true });
    const invalid = useMemo(() => {
        return !slug || urlCheck.needsChecking || urlCheck.checking || !urlCheck.valid;
    }, [urlCheck, slug]);

    useEffect(() => {
        if (link?.slug) {
            setIsCheckingUrl({ needsChecking: false, checking: false, valid: true })
        } else {
            setIsCheckingUrl({ needsChecking: true })
        }
        setSlug(link?.slug || '');
        setIsPrimary(link?.isPrimary || false);
    }, [link]);

    const onChange = useCallback((event: { target: { value: string } }) => {
        setIsCheckingUrl({ needsChecking: true })
        setSlug(event.target.value.split(' ').join('-'))
    }, [])

    const onBlur = useCallback(() => {
        if (slug) {
            if (props.existingSlugs.includes(slug)) {
                setIsCheckingUrl({
                    needsChecking: false,
                    checking: false,
                    valid: false
                })
            } else {
                setIsCheckingUrl({
                    needsChecking: false,
                    checking: true
                })
                api.post.checkUrl({
                    id: link?.id || '',
                    slug: slug,
                    type: props.type
                }).then(result => {
                    setIsCheckingUrl({
                        needsChecking: false,
                        checking: false,
                        valid: result?.isFree || false
                    })
                })
            }
        }
    }, [props, link, slug])

    const save = useCallback(() => {
        if (link) {
            onSave({
                id: link.id,
                isPrimary: isPrimary,
                isSystem: link.isSystem,
                slug: slug,
                formKey: link.formKey
            });
            onHide();
        }
    }, [link, onSave, isPrimary, slug, onHide]);

    const downloadLinkBase = useMemo(() => `${window.location.protocol}//${window.location.host}/${props.type === 'file' ? 'f' : 'd'}/`, [props.type])

    return (
        <InlineModal
            fixed
            visible={visible}
            onHide={onHide}
            header={`${link?.slug ? 'Edit' : 'Add'} Link`}
            Footer={<div className="d-flex justify-content-end">
                <div>
                    <button className="btn btn-sm btn-outline-dark border-0 me-3" onClick={onHide}>Cancel</button>
                    <button className="btn btn-sm btn-primary" disabled={invalid} onClick={save}>Done</button>
                </div>
            </div>}
        >
            <div className="mb-3">
                <label htmlFor="" className="form-label">Link</label>
                <div className="input-group">
                    <div className="input-group-prepend" style={{ cursor: 'text' }}>
                        <div className="input-group-text btn btn-light border fs-6" style={{ cursor: 'text' }}>
                            {downloadLinkBase}
                        </div>
                    </div>
                    <input type="text" className={`form-control${invalid ? ' is-invalid' : ' is-valid'}`} value={slug} onChange={onChange} onBlur={onBlur} />
                    {!urlCheck.needsChecking && urlCheck.checking && <div className="input-group-append">
                        <span className="input-group-text">
                            <Dots size={'0.5rem'} spacing={'0.25rem'} />
                        </span>
                    </div>
                    }
                    <div className="invalid-feedback mx-1">
                        {!slug ? 'Enter a friendly url' : urlCheck.needsChecking ? 'Availability will be checked when you finish editing' : urlCheck.checking ? 'Checking availability' : 'This url is taken'}
                    </div>
                    <div className="valid-feedback mx-1">
                        This url is available
                    </div>
                </div>
            </div>
            <div className="mb-3">
                <label className="form-label" htmlFor="">Primary</label>
                <div className="d-flex justify-content-start align-items-center">
                    <Switch checked={isPrimary} name="Is Primary" onChanged={setIsPrimary} label={isPrimary ? 'This link will show as the primary url for guests' : 'This link will not show to guests'} />

                </div>
            </div>
        </InlineModal>
    );
}
