import { useCallback, useState } from 'react';
import { MultiSelectItem } from '../contexts/selected-file';
import { getFilesFromDropEvent } from '../services/file-drop-zone';
import { hasParent } from '../services/has-parent';
import { DropSource } from '../components/tree/droppable';
import { FolderInfo } from '../types/folder-info';

export function useFolderDropZone(
    destination: string | undefined,
    onDrop: ((source: DropSource, destination?: string) => void | Promise<void>) | undefined,
    dragging: string | undefined
) {
    const [dropping, setDropping] = useState(false);

    const onDropEvent = useCallback((ev: React.DragEvent<HTMLDivElement>) => {
        if (!onDrop) { return; }

        ev.stopPropagation();
        ev.preventDefault();
        setDropping(false);

        if (ev.dataTransfer.types.includes('application/tree-node')) {
            const source = ev.dataTransfer.getData('application/tree-node');
            if (!source) { return false; }

            const { id, parent } = JSON.parse(source) as { id: string; parent: FolderInfo };

            if (hasParent(ev.target as HTMLElement, el => el.id === source)) {
                return false;
            }
            onDrop({
                type: 'Folder',
                id: id,
                parent: parent
            }, destination);
        } else if (ev.dataTransfer.types.includes('application/selected-items')) {
            const source = ev.dataTransfer.getData('application/selected-items');
            if (!source) { return false; }

            const { items, parent } = JSON.parse(source) as { items: MultiSelectItem[]; parent: FolderInfo };

            onDrop({
                type: 'Multi-Select',
                items: items,
                parent: parent
            }, destination);
        } else if (ev.dataTransfer.types.includes('Files')) {
            const files = getFilesFromDropEvent(ev);
            onDrop({
                type: 'Upload',
                files: files
            });
        }
    }, [destination, onDrop]);

    const onDragOverEvent = useCallback((ev: React.DragEvent<HTMLDivElement>) => {
        if (!onDrop) { return; }

        if (ev.dataTransfer.types.includes('application/tree-node')) {
            if (!dragging) { return false; }
            if (hasParent(ev.target as HTMLElement, el => el.id === dragging)) {
                return false;
            }
        } else if (!ev.dataTransfer.types.includes('Files') && !ev.dataTransfer.types.includes('application/selected-items')) {
            return false;
        }

        ev.stopPropagation();
        ev.preventDefault();

        if (!dropping) {
            setDropping(true);
        }
    }, [onDrop, dropping, dragging]);

    const onDragLeave = useCallback(() => setDropping(false), []);

    return {
        isDropping: dropping,
        onDrop: onDropEvent,
        onDragOver: onDragOverEvent,
        onDragLeave: onDragLeave
    };
}
