export interface SwitchProps {
    name: string;
    checked: boolean;
    onChanged: (checked: boolean) => void;
    label?: string;
}

export function Switch(props: SwitchProps) {
    return (
        <div className="form-check form-switch">
            <div>
                <input className="form-check-input me-2" type="checkbox" id={props.name} name={props.name}
                    checked={props.checked} onChange={e => props.onChanged(e.target.checked)} />
                <label className={"form-check-label " + (props.checked ? 'fw-bold' : 'text-muted')} htmlFor={props.name}>
                    {props.label}
                </label>
            </div>
        </div>
    );
}