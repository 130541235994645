import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOnClickOutside } from '../../../hooks/use-on-click-outside';

function daysInMonth(month: number, year: number) {
	return new Date(year, month + 1, 0).getDate();
}

interface DatePickerProps {
	minDate: Date;
	maxDate: Date;
	currentDate: Date;
	onChange: (date: Date) => void;
	onDone: () => void;
	onClear?: () => void;
	alignVertical?: 'above' | 'below';
	alignHorizontal?: 'left' | 'right';
	noDone?: boolean;
}

export function DatePicker(props: DatePickerProps) {
	const currentYear = props.currentDate.getUTCFullYear();
	const currentMonth = props.currentDate.getUTCMonth();
	const currentDay = props.currentDate.getUTCDate();

	const ref = useOnClickOutside<HTMLDivElement>(props.onDone);

	let newYear = currentYear;
	let newMonth = currentMonth;
	let newDay = currentDay;

	let minMonth = 0;
	let maxMonth = 11;
	let minDay = 1;
	let maxDay = daysInMonth(newMonth, newYear);

	const minYear = props.minDate.getUTCFullYear();
	if (newYear < minYear) {
		newYear = minYear;
	}
	if (newYear === minYear) {
		minMonth = props.minDate.getUTCMonth();
		if (newMonth < minMonth) {
			newMonth = minMonth;
			maxDay = daysInMonth(newMonth, newYear);
			if (newDay > maxDay) {
				newDay = maxDay;
			}
		}
		if (newMonth === minMonth) {
			minDay = props.minDate.getUTCDate();
			if (newDay < minDay) {
				newDay = minDay;
			}
		}
	}

	const maxYear = props.maxDate.getUTCFullYear();
	if (newYear > maxYear) {
		newYear = maxYear;
	}
	if (newYear === maxYear) {
		maxMonth = props.maxDate.getUTCMonth();
		if (newMonth > maxMonth) {
			newMonth = maxMonth;
			maxDay = daysInMonth(newMonth, newYear);
			if (newDay > maxDay) {
				newDay = maxDay;
			}
		}
		if (newMonth === maxMonth) {
			maxDay = props.maxDate.getUTCDate();
			if (newDay > maxDay) {
				newDay = maxDay;
			}
		}
	}

	if (newDay !== currentDay || newMonth !== currentMonth || newYear !== currentYear) {
		props.onChange(new Date(Date.UTC(newYear, newMonth, newDay)));
	}

	return (
		<div
			className='position-absolute'
			style={{
				top: props.alignVertical === 'above' ? undefined : '100%',
				bottom: props.alignVertical === 'above' ? '100%' : undefined,
				right: props.alignHorizontal === 'left' ? 0 : undefined,
			}}
			ref={ref}
		>
			<div className='input-group flex-nowrap bg-white shadow rounded'>
				<div className='form-floating'>
					<select
						className='form-select'
						aria-label='Month'
						style={{ minWidth: '6rem' }}
						value={currentMonth}
						onChange={(e) =>
							props.onChange(
								new Date(
									Date.UTC(currentYear, +e.target.value, Math.min(daysInMonth(+e.target.value, currentYear), currentDay))
								)
							)
						}
					>
						{[...Array(maxMonth - minMonth + 1)]
							.map((_, i) => i + minMonth)
							.map((value) => (
								<option key={value} value={value}>
									{(value + 1).toFixed(0)}
								</option>
							))}
					</select>
					<label>Month</label>
				</div>
				<div className='form-floating'>
					<select
						className='form-select'
						aria-label='Day'
						style={{ minWidth: '6rem' }}
						value={currentDay}
						onChange={(e) => props.onChange(new Date(Date.UTC(currentYear, currentMonth, +e.target.value)))}
					>
						{[...Array(maxDay - minDay + 1)]
							.map((_, i) => i + minDay)
							.map((value) => (
								<option key={value} value={value}>
									{value.toFixed(0)}
								</option>
							))}
					</select>
					<label>Day</label>
				</div>
				<div className='form-floating'>
					<select
						className='form-select'
						aria-label='Year'
						style={{ minWidth: '6rem' }}
						value={currentYear}
						onChange={(e) =>
							props.onChange(
								new Date(+e.target.value, currentMonth, Math.min(daysInMonth(currentMonth, +e.target.value), currentDay))
							)
						}
					>
						{[...Array(maxYear - minYear + 1)]
							.map((_, i) => i + minYear)
							.map((value) => (
								<option key={value} value={value}>
									{value.toFixed(0)}
								</option>
							))}
					</select>
					<label>Year</label>
				</div>
				{props.onClear && (
					<button type='button' className='btn btn-outline-danger border' onClick={props.onClear}>
						<FontAwesomeIcon icon={['fal', 'times']} />
					</button>
				)}
				{!props.noDone && (
					<button type='button' className='btn btn-outline-success' onClick={props.onDone}>
						Done
					</button>
				)}
			</div>
		</div>
	);
}
