import { DetailedFolderInfo } from "../../../../types/detailed-folder-info";
import { FormLink } from "../../../../types/link";
import { FooterControl } from "../admin-dialogue";
import { DescriptionInput } from "../form-components/description-input";
import { NoteInput } from "../form-components/notes-input";
import { TitleInput } from "../form-components/title-input";
import { PublishAccordion } from "../form-components/published-accordian/publish-accordion";
import { StarredAccordion } from "../form-components/starred-accordian/starred-accordian";
import { LabelsAccordion } from "../form-components/labels-accordian/labels-accordian";
import { Stats } from "../form-components/stats/stats";
import { LinksAccordian } from "../form-components/links-accordian/links-accordian";
import { FormField } from "../../../form/types/form-field";

export interface FormValues {
    title: string;
    description: string;
    note: string;
    labels: string[];
    links: FormLink[];
    isStarred: boolean;
    publishStartDate?: Date;
    publishEndDate?: Date;
}


interface FolderFormProps {
    folder: DetailedFolderInfo;
    controlFooter: (control?: FooterControl) => void;
    form: FormValues;
    setField: (field: FormField<FormValues>) => (value: any) => void;
    setFieldValidity: (field: FormField<FormValues>) => (isValid: boolean) => void;
}

export function FolderForm(props: FolderFormProps) {
    const { folder, form, controlFooter, setField, setFieldValidity } = props;

    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <TitleInput value={form.title} onChange={setField} onValidate={setFieldValidity} />
                    <DescriptionInput value={form.description} onChange={setField} onValidate={setFieldValidity} />
                    <NoteInput value={form.note} onChange={setField} onValidate={setFieldValidity} />
                </div>
                <div className="col-md-6">
                    <Stats size={`${(folder.folders.length || 0)} Folders ${(folder.files.length || 0)} Files`}
                        type='Folder' created={folder.created} modified={folder.modified}
                    />
                    <div className="accordion accordion-flush mt-3" id="addEditFormAccordion">
                        <LinksAccordian type='folder' onChange={setField} controlFooter={controlFooter} value={form.links} />
                        <LabelsAccordion onChange={setField} value={form.labels} />
                        <StarredAccordion onChange={setField} value={form.isStarred} />
                        <PublishAccordion
                            currentStartDate={folder.publishStartDate} currentEndDate={folder.publishEndDate}
                            onChange={setField}
                            publishStartDate={form.publishStartDate} publishEndDate={form.publishEndDate}
                            type='Folder'
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
