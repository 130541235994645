import { useIsAuthenticated } from '@azure/msal-react';
import React, { PropsWithChildren, useMemo } from 'react';
import { throwError } from '../throw-error';
import { BulkUploader, useBulkUpload } from './use-bulk-upload';
import { MoverCopier, useMoverCopier } from './use-mover-copier';
import { AddEditForm, useAddEditForm } from './use-add-edit-form';
import { BulkEdit, useBulkEdit } from './use-bulk-edit';



export interface AdminPanelContextType {
    form: AddEditForm;
    bulkUpload: BulkUploader;
    bulkEdit: BulkEdit;
    moverCopier: MoverCopier;
}

export const AdminPanelContext = React.createContext<AdminPanelContextType>({
    form: {
        visible: false,
        hide: throwError,
        show: throwError,
    },
    bulkUpload: {
        visible: false,
        hide: throwError,
        show: throwError,
        uploadFile: throwError,
        skipFile: throwError,
    },
    moverCopier: {
        hide: throwError,
        show: throwError,
        visible: false,
        validating: false,
    },
    bulkEdit: {
        hide: throwError,
        show: throwError,
        mode: 'label',
        items: [],
        visible: false
    }
});

export function AdminPanelContextProvider(props: PropsWithChildren<{}>) {
    const isAdmin = useIsAuthenticated();


    const moverCopier = useMoverCopier(isAdmin);
    const bulkUpload = useBulkUpload(isAdmin);
    const form = useAddEditForm(isAdmin);
    const bulkEdit = useBulkEdit(isAdmin);

    const context: AdminPanelContextType = useMemo(() => {
        return {
            bulkEdit: bulkEdit,
            form: form,
            bulkUpload: bulkUpload,
            moverCopier: moverCopier
        }
    }, [bulkEdit, form, bulkUpload, moverCopier])

    return <AdminPanelContext.Provider value={context}>
        {props.children}
    </AdminPanelContext.Provider>
};


