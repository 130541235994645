import { lazy, Suspense } from 'react';
import { LoadingPage } from '../components/loading/loading-page';
import { PendingUnpublishProps } from '../pages/pending-unpublish/pending-unpublish';

const Page = lazy(() => import('../pages/pending-unpublish/pending-unpublish'))

export default function PendingUnpublish(props: PendingUnpublishProps) {
    return (
        <Suspense fallback={<LoadingPage />}>
            <Page {...props} />
        </Suspense>
    )
}
