import { Configuration, LogLevel, RedirectRequest } from "@azure/msal-browser";

const url = window.location.protocol + '//' + window.location.host + "/dashboard/";

 export const msalConfig: Configuration = {
    auth: {
        clientId: "972d8d81-70fc-4d09-9f50-0479bd22ae98",
        authority: "https://login.microsoftonline.com/44235acd-e77a-4dd3-a42f-625be236b4a0",
        redirectUri: url 
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {	
        loggerOptions: {	
            logLevel: LogLevel.Error,
            piiLoggingEnabled: false
        }
    }
};

export const loginRequest: RedirectRequest = {
    scopes: ["User.Read"]
};

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};