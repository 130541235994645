import { lazy, Suspense } from 'react';
import { LoadingPage } from '../components/loading/loading-page';
import { PendingPublishProps } from '../pages/pending-publish/pending-publish';

const Page = lazy(() => import('../pages/pending-publish/pending-publish'))

export default function PendingPublish(props: PendingPublishProps) {
    return (
        <Suspense fallback={<LoadingPage />}>
            <Page {...props} />
        </Suspense>
    )
}
