import { ApiCall } from '@lcms/api-definition';
import { DetailedFolderInfo } from '../../../types/detailed-folder-info';
import { FileInfo } from '../../../types/file-info';
import { userRecentActivity } from '../../api-endpoint-definition.json';

export interface RecentActionsResponse {
    recentActions: DetailedFolderInfo<never, FileInfo>
}

export const recentActivityEndpoint = new ApiCall<RecentActionsResponse>().get('recentActions', {
    route: userRecentActivity
});