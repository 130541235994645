import { useCallback, useMemo } from "react";
import { stringToDate } from "../../../../../services/date-conversion";
import { getPublishState } from "../../../../../services/get-publish-state";
import { today, tomorrow, yesterday } from "../../../../../types/dates";
import { PublishState } from "../../../../../types/publish-state";
import { PublishDateControls } from "./publish-date-controls";
import { Status } from "./status";

export interface PublishAccordionProps {
    currentStartDate?: string;
    currentEndDate?: string;
    publishStartDate?: Date;
    publishEndDate?: Date;
    type?: string;
    onChange: (field: 'publishStartDate' | 'publishEndDate') => (newValue: Date | undefined) => void;
    show?: boolean;
}

export function PublishAccordion(props: PublishAccordionProps) {

    const { publishStartDate, publishEndDate, onChange, currentStartDate } = props;

    const state: PublishState = useMemo(() => {
        return getPublishState(publishStartDate, publishEndDate)
    }, [publishEndDate, publishStartDate]);

    const publish = useCallback(() => {
        onChange('publishStartDate')(today);
        onChange('publishEndDate')(undefined);
    }, [onChange]);

    const unpublish = useCallback(() => {
        onChange('publishStartDate')(stringToDate(currentStartDate));
        onChange('publishEndDate')(yesterday);
    }, [onChange, currentStartDate]);

    const schedule = useCallback(() => {
        onChange('publishStartDate')(tomorrow);
        onChange('publishEndDate')(undefined);
    }, [onChange]);

    return (
        <div className="accordion-item">
            <h2 className="accordion-header" id="publishSection">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#publishCollapse" aria-expanded="false" aria-controls="publishCollapse">
                    <div className="w-100 d-flex justify-content-between align-items-center pe-4">
                        <div>Status</div>
                        <small className={"p-2 bg-white rounded " + (state === 'Unpublished' ? 'text-muted' : 'text-dark')}>
                            <Status state={state} />
                        </small>
                    </div>
                </button>
            </h2>
            <div id="publishCollapse" className={`accordion-collapse collapse ${props.show ? 'show' : ''}`} aria-labelledby="publishSection" data-bs-parent="#addEditFormAccordion">
                <div className="accordion-body">

                    <div className="btn-group w-100" role="group" aria-label="Basic radio toggle button group">
                        <input type="radio" className="btn-check" name="btnradio" id="publish-button" autoComplete="off" readOnly
                            checked={state === 'Published'} onClick={publish} />
                        <label className={(state === 'Published' ? 'btn btn-outline-success' : 'btn btn-outline-dark')} htmlFor="publish-button">Published</label>

                        <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" readOnly
                            checked={state === 'Unpublished'} onClick={unpublish} />
                        <label className={(state === 'Unpublished' ? 'btn btn-outline-danger' : 'btn btn-outline-dark')} htmlFor="btnradio2">Unpublished</label>

                        <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off" readOnly
                            checked={state === 'PendingPublish' || state === 'PendingUnpublish'}
                            onClick={schedule} />
                        <label className={(state === 'PendingPublish' ? 'btn btn-outline-warning' : 'btn btn-outline-dark')} htmlFor="btnradio3">Scheduled</label>
                    </div>

                    {(state === 'PendingPublish' || state === 'PendingUnpublish') &&
                        <PublishDateControls
                            onChange={onChange}
                            publishStartDate={publishStartDate}
                            publishEndDate={publishEndDate}
                        />}

                    <Status state={state} layout type={props.type} />


                </div>
            </div>
        </div>
    );
}

