import { Link } from 'react-router-dom';
import { Header } from '../components/header/header.bare';
import { routes } from '../routing/routes';
import emptySVG from '../components/backgrounds/404.svg';
import { useMount } from '../hooks/use-mount';

export default function Error404() {
	useMount(() => {
		const current = document.title;
		document.title = '404 - ' + window.location.pathname;
		return () => {
			document.title = current;
		};
	});

	return (
		<>
			<Header />
			<div className='content-pane' style={{ left: '0', right: '0', width: '100%', backgroundColor: '#BAC5F4' }}>
				<div
					className='container-fluid position-relative svgBG mt-5'
					style={{
						right: 'auto',
						left: 'auto',
						height: '90%',
						backgroundSize: 'auto',
						backgroundPosition: 'top center',
						backgroundImage: `url(${emptySVG})`,
						backgroundRepeat: 'no-repeat',
					}}
				>
					<div
						className='px-3 py-3 rounded-2 glass position-relative shadow-sm text-center'
						style={{
							right: 'auto',
							left: 'auto',
							top: '0',
							width: '50%',
							marginRight: 'auto',
							marginLeft: 'auto',
							backgroundColor: 'rgba(255, 255, 255, 0.35',
						}}
					>
						<h3>You seem to be lost</h3>
						<p>
							Click <Link to={routes.dashboard}>here</Link> to return to the Document Library
						</p>
					</div>
				</div>
			</div>
		</>
	);
}
