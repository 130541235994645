import { useMsal } from "@azure/msal-react";
import { useCallback } from "react";
import { getToken } from "../../services/authentication/get-token";

export function useToken() {
    const { instance, accounts } = useMsal();

    const callWithToken = useCallback(async () => {
        return await getToken(instance, accounts);
    }, [instance, accounts])

    return !accounts[0] ? null : callWithToken;
}

