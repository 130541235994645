import { lazy, Suspense } from 'react';
import { LoadingPage } from '../../components/loading/loading-page';
import { TrendingProps } from '../../pages/trending/trending';

const Page = lazy(() => import('../../pages/trending/trending.mobile'))

export default function Trending(props: TrendingProps) {
    return (
        <Suspense fallback={<LoadingPage />}>
            <Page {...props} />
        </Suspense>
    )
}
