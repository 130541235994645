import { ApiCall } from '@lcms/api-definition';
import { FileInfo } from '../../../types/file-info';
import { FolderInfo } from '../../../types/folder-info';
import { getDashboardStarred } from '../../api-endpoint-definition.json';

export interface StarredResponse {
    files: FileInfo[];
    folders: FolderInfo[];
}

export const dashboardStarred = new ApiCall<StarredResponse>().get('dashboardStarred', {
    route: getDashboardStarred
});