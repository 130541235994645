import { lazy, Suspense } from 'react';
import { LoadingPage } from '../../components/loading/loading-page';
import { FolderProps } from '../../pages/folder/folder';

const Page = lazy(() => import('../../pages/folder/folder.mobile'))

export default function Folder(props: FolderProps) {
    return (
        <Suspense fallback={<LoadingPage />}>
            <Page {...props} />
        </Suspense>
    )
}
