import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSwitch } from "../../../../hooks/use-switch";
import { FolderLocation } from "../../../../types/folder-location";
import { DocumentTree, DocumentTreeSelectedType } from "../../../document-tree/document-tree";
import { InlineModal } from "../../../modal/modal-inline";
import { Tooltip } from "../../../tooltip/tooltip";
import { FooterControl } from "../admin-dialogue";
import { InputProps } from "../input-props";

interface LocationInputProps extends InputProps<'location', FolderLocation[]> {
    multiple?: boolean;
    type?: string;
    controlFooter: (control?: FooterControl) => void;
}

export function LocationInput(props: LocationInputProps) {
    const { onChange, value: locations, multiple, controlFooter } = props;
    const [pickerIsVisible, showPicker, hidePicker] = useSwitch(false);
    const [selected, setSelected] = useState<FolderLocation[]>()

    const untracked = useMemo(() => { return !locations.filter(l => !l.isTrashed && l.isPublished).length }, [locations])

    useEffect(() => {
        setSelected(locations);
    }, [locations])

    const onRemove = useCallback((id?: string) => {
        onChange('location')(locations.filter(location => location.id !== id))
    }, [onChange, locations])

    const onHidePicker = useCallback(() => {
        hidePicker();
        controlFooter(undefined);
    }, [controlFooter, hidePicker])

    const onSelect = useCallback(() => {
        onChange('location')(selected || [])
        onHidePicker();
    }, [onChange, selected, onHidePicker])

    const onShowPicker = useCallback(() => {
        showPicker();
        controlFooter({ hideFooter: true })
    }, [controlFooter, showPicker])

    const onPickerSelect = useCallback((i: DocumentTreeSelectedType) => {
        if (i.type === 'Folder') {
            setSelected(prev => {
                if (!prev || !multiple) {
                    return [i]
                }
                else {
                    if (prev.find(p => p.id === i.id)) {
                        return prev.filter(p => p.id !== i.id)
                    }
                    return prev.concat(i)
                }
            })
        }
    }, [multiple])

    const buttonLabel = props.type === 'File' ? 'Done' : 'Move';
    return (<>
        <div className="mb-3">
            <label htmlFor="itemLocation" className="form-label">Location:</label>
            {untracked && <div className="d-flex justify-content-start align-items-center">
                <div className="file-type"><FontAwesomeIcon icon={['fal', 'chart-network']} color='var(--bs-danger)' /></div>
                <div className=""><b>This file is untracked.</b> <br /><small>Move to an active folder to restore.</small></div>
            </div>}
            <div className="alert bg-white border rounded-2 d-flex justify-content-between align-items-center">
                <div>
                    {locations.map(l => <LocationRecord key={l.id}
                        id={l.id} path={l.path} isTrashed={l.isTrashed} isPublished={l.isPublished}
                        onRemove={multiple && locations.length > 1 ? onRemove : undefined}
                    />)}
                    {!locations.length && <LocationRecord path="Untracked" />}
                </div>
                <button type="button" className="btn btn-primary btn-sm shadow-sm py-3"
                    onClick={onShowPicker}
                >
                    <span className="ms-2 p-4 me-3"><FontAwesomeIcon icon={['fad', 'folder-tree']} /></span>
                    {props.type === 'Folder' ? <>
                        <div>Move/Copy</div>
                        <div>Folder</div>
                    </> :
                        <>
                            <div>Add/Remove</div>
                            <div>File Location</div>
                        </>
                    }

                </button>

            </div>
        </div>
        <InlineModal
            fixed
            header='Select Location'
            visible={pickerIsVisible}
            onHide={onHidePicker}
            Footer={<div className="d-flex align-items-center justify-content-between">
                {props.type === 'File' ?
                    <small className="fw-bold">
                        <span className="bg-light p-2 rounded-2 text-success">{(selected?.length || 0).toFixed(0).padStart(2, '0')}</span> Locations Selected
                    </small> :
                    <button className="btn btn-sm btn-outline-dark border-0 me-3" onClick={onHidePicker}>Cancel</button>
                }

                <div>
                    {props.type === 'File' ? <button className="btn btn-sm btn-outline-dark border-0 me-3" onClick={onHidePicker}>Cancel</button> :
                        <button className="btn btn-sm btn-outline-primary me-3" onClick={onSelect}>Copy</button>}
                    <button className="btn btn-sm btn-primary" onClick={onSelect} disabled={!selected?.length}>{buttonLabel}</button>
                </div>

            </div>}
        >
            <div style={{ maxHeight: '20em', overflowY: 'scroll' }}>
                <DocumentTree select={onPickerSelect} selected={selected} />
            </div>
        </InlineModal>
    </>
    );
}

function LocationRecord(props: { id?: string; path: string; isTrashed?: boolean; isPublished?: boolean; onRemove?: (id?: string) => void }) {
    const { id, path, onRemove, isTrashed, isPublished } = props;

    const onClick = useCallback(() => {
        onRemove?.(id);
    }, [onRemove, id])

    return (
        <div className={"badge text-dark p-2 m-2 text-wrap border " + (!isPublished || isTrashed ? '' : 'border-primary')}>
            <div className="d-flex justify-content-between align-items-center">
                <div className={"text-start " + (!isPublished || isTrashed ? 'text-muted' : '')}>{!isPublished ? <FontAwesomeIcon className="text-danger" icon={['fal', 'circle']} /> : null} {isTrashed ? <FontAwesomeIcon icon={['fas', 'trash']} className="me-1" /> : null} {path}</div>
                {onRemove &&
                    <Tooltip content="Remove location" placement="left">
                        <button type="button" onClick={onClick} className="ms-3 btn btn-link p-0">
                            <FontAwesomeIcon icon={['fas', 'times']} />
                        </button>
                    </Tooltip>
                }
            </div>
        </div>
    );
}