import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { Draggable } from './draggable';
import { Droppable, DropSource } from './droppable';

export type OnDropEvent<T> = (source: DropSource, destination: { id?: string; node: T }) => void | Promise<void>

interface Events<T> {
    dragging?: string;
    onDrop?: OnDropEvent<T>;
    onDrag?: (source: string) => void | Promise<void>;
    onDragEnd?: () => void | Promise<void>;
}

export interface Node<T> {
    id: string;
    title: string;
    data: T;
    allowDrop: boolean;
    allowDrag: boolean;
    children: Node<T>[];
}

export interface TreeProps<TData, TSettings> {
    updateNode: (changes: Node<TData>) => void;
    data: Node<TData>;
    onDrop?: OnDropEvent<TData>;
    onClick?: (node: Node<TData>) => void | Promise<void>;
    Root?: React.FunctionComponent<TreeNodeComponentProps<TData, TSettings>>;
    Component?: React.FunctionComponent<TreeNodeComponentProps<TData, TSettings>>;
    isActive?: (node: Node<TData>) => boolean;
    isOpen?: (node: Node<TData>) => boolean;
    allowDragAndDrop: boolean;
    settings?: TSettings;
    getDragText?: (node: Node<TData>) => string;
}

export function Tree<TData, TSettings = {}>(props: TreeProps<TData, TSettings>) {
    const [dragging, setDragging] = useState<string>();
    const [events, setEvents] = useState<Events<TData> | null>(null)

    const { onDrop } = props;
    useEffect(() => {
        setEvents({
            onDrag: (id: string) => setDragging(id),
            onDragEnd: () => setDragging(undefined),
            onDrop: onDrop,
            dragging
        });
    }, [onDrop, dragging]);

    if (!events) { return null; }
    return (
        <TreeNode
            parent={undefined}
            index={0} depth={1.25}
            isActive={props.isActive} updateNode={props.updateNode}
            isOpen={props.isOpen}
            node={props.data} events={events}
            onClick={props.onClick}
            Component={props.Root || props.Component}
            ChildComponent={props.Component}
            allowDragAndDrop={props.allowDragAndDrop}
            settings={props.settings}
            getDragText={props.getDragText}
        />
    );
}

interface TreeNodeProps<TData, TSettings> {
    parent: Node<TData> | undefined;
    node: Node<TData>;
    updateNode: (changes: Node<TData>) => void;
    events: Events<TData>;
    onClick?: (node: Node<TData>) => void | Promise<void>;
    Component?: React.FunctionComponent<TreeNodeComponentProps<TData, TSettings>>;
    ChildComponent?: React.FunctionComponent<TreeNodeComponentProps<TData, TSettings>>;
    isActive?: (node: Node<TData>) => boolean
    isOpen?: (node: Node<TData>) => boolean;
    depth: number;
    index: number;
    allowDragAndDrop: boolean;
    settings?: TSettings;
    getDragText?: (node: Node<TData>) => string;
}

function TreeNode<TData, TSettings>(props: TreeNodeProps<TData, TSettings>) {
    const { node, onClick, events, Component, getDragText, parent } = props;
    const [collapsed, setCollapsed] = useState(true);
    const onClickEvent = useCallback(() => {
        if (onClick) {
            onClick(node)
        }
    }, [node, onClick])
    const indentText = !collapsed ? { textIndent: `${props.depth}em` } : {};

    const NodeComponent: React.FunctionComponent<TreeNodeComponentProps<TData, TSettings>> = Component || TreeNodeComponent;

    const onDrop = useCallback((source: DropSource, destination: string | undefined) => {
        events.onDrop?.(source, {
            node: node.data,
            id: destination
        })
    }, [node.data, events])

    return (
        <Droppable id={node.id} allow={props.allowDragAndDrop && node.allowDrop} onDrop={onDrop} dragging={events.dragging}>
            <Draggable id={node.id}
                parent={parent}
                dragText={getDragText ? getDragText(node) : undefined}
                allow={props.allowDragAndDrop && node.allowDrag}
                onDrag={events.onDrag}
                onDragEnd={events.onDragEnd}>
                <NodeComponent
                    index={props.index}
                    updateNode={props.updateNode}
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    select={onClickEvent}
                    node={node}
                    selectable={!!onClick}
                    isActive={props.isActive}
                    isOpen={props.isOpen}
                    settings={props.settings}
                />
            </Draggable>
            <div className={collapsed ? 'd-none' : 'tree-sub'} style={indentText}>
                {node.children.map(node =>
                    <div key={node.id} style={indentText}>
                        <TreeNode {...props} parent={node} index={props.index + 1} depth={props.depth + .75} node={node} Component={props.ChildComponent} />
                    </div>
                )}
            </div>
        </Droppable>);
}

export interface TreeNodeComponentProps<TData, TSettings = {}> {
    collapsed: boolean;
    setCollapsed: (collapsed: boolean) => void;
    select: () => void;
    node: Node<TData>;
    selectable: boolean;
    updateNode: (changes: Node<TData>) => void;
    index: number;
    isActive?: (node: Node<TData>) => boolean;
    isOpen?: (node: Node<TData>) => boolean;
    settings?: TSettings;
}
function TreeNodeComponent<TData, TSettings>(props: TreeNodeComponentProps<TData, TSettings>) {
    const { collapsed, node: data, select, setCollapsed, selectable } = props;

    return (
        <div className="text-nowrap">
            <button className={`btn btn-link text-dark ${!data.children.length ? 'invisible' : ''}`} onClick={() => setCollapsed(!collapsed)}>
                <FontAwesomeIcon icon={['fas', collapsed ? 'caret-right' : 'caret-down']} />
            </button>
            <div className={`p-2 d-inline-block ${selectable ? 'cursor-pointer' : ''}`} onClick={select}>
                {data.title}
            </div>
        </div>
    );
}